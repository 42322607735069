import { t } from 'i18next';

import ImpersonateIcon from '@mui/icons-material/TransferWithinAStation';
// import CloudinaryTemplateIcon from '@mui/icons-material/FilterDrama';
import BlueprintBuilderIcon from '@mui/icons-material/SettingsInputComponent';
import BoltIcon from '@mui/icons-material/Bolt';
import SkinSettingsIcon from '@mui/icons-material/Tune';
import FacebookIcon from '@mui/icons-material/Facebook';
import SearchIcon from '@mui/icons-material/Search';
import PlusIcon from '@mui/icons-material/Add';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import SellIcon from '@mui/icons-material/Sell';
import KeyIcon from '@mui/icons-material/VpnKey';
import WebhookIcon from '@mui/icons-material/SettingsInputAntenna';
import ContentIcon from '@mui/icons-material/ListAlt';
import ArchitectureIcon from '@mui/icons-material/AccountTree';
import CatalogContentIcon from '@mui/icons-material/LibraryBooks';
import PersonIcon from '@mui/icons-material/Person';
import MediaIcon from '@mui/icons-material/PermMedia';
import ResourceLibraryIcon from '@mui/icons-material/MenuBook';
import AttachMoney from '@mui/icons-material/AttachMoney';
import StorageIcon from '@mui/icons-material/Storage';
import CodeIcon from '@mui/icons-material/Code';
import AddUserIcon from '@mui/icons-material/PersonAdd';
import ScienceIcon from '@mui/icons-material/Science';
import AddLinkIcon from '@mui/icons-material/AddLink';
import CancelIcon from '@mui/icons-material/Cancel';
import EmailIcon from '@mui/icons-material/Email';
import DataObjectIcon from '@mui/icons-material/DataObject';

import { paths } from 'src/routes/paths';

import { isOrgAdmin, isDeveloper } from 'src/Auth/common';

const isDev = isDeveloper();
const isOrgAdminUser = isOrgAdmin();

const getAdminQuickStartsText = () => ({
  impersonate: {
    description: t(
      'adminDashboard:impersonate.description',
      'Impersonate any user in any organization.'
    ),
    name: t('adminDashboard:impersonate.name')
  },
  blueprintBuilder: {
    description: t(
      'adminDashboard:blueprintBuilder.description',
      'Make updates to Blueprints'
    ),
    name: t('adminDashboard:blueprintBuilder.name')
  }
});

// order here matters
export const adminQuickStarts = isEvocalizeOrg => {
  const text = getAdminQuickStartsText();
  const quickStarts = [
    {
      description: text.impersonate.description,
      disabled: false,
      icon: ImpersonateIcon,
      name: text.impersonate.name,
      path: paths.admin.impersonate,
      visible: true,
      hide: !isEvocalizeOrg
    },
    {
      description: text.blueprintBuilder.description,
      disabled: !isDev && !isOrgAdminUser,
      icon: BlueprintBuilderIcon,
      name: text.blueprintBuilder.name,
      path: paths.admin.blueprintBuilderOverview,
      visible: isOrgAdminUser
    }
  ];

  return quickStarts;
};

const getSettingsAdminQuickStartsText = () => ({
  createOrganization: {
    description: t(
      'adminDashboard:createOrganization.description',
      'Setup a new organization'
    ),
    name: t('adminDashboard:createOrganization.name')
  },
  skinSettings: {
    description: t(
      'adminDashboard:skinSettings.description',
      'Update and manage organization skin settings'
    ),
    name: t('adminDashboard:skinSettings.name')
  },
  facebookSettings: {
    description: t(
      'adminDashboard:facebookSettings.description',
      'Manage Facebook page settings'
    ),
    name: t('adminDashboard:facebookSettings.name')
  },
  authenticationSettings: {
    description: t(
      'adminDashboard:authenticationSettings.description',
      'Manage authentication settings'
    ),
    name: t(
      'adminDashboard:authenticationSettings.name',
      'Authentication Settings'
    )
  },
  webhookSettings: {
    description: t(
      'adminDashboard:webhookSettings.description',
      'Manage webhook settings'
    ),
    name: t('adminDashboard:webhookSettings.name')
  },
  architectureSettings: {
    description: t(
      'adminDashboard:architectureSettings.description',
      'Manage architecture settings'
    ),
    name: t('adminDashboard:architectureSettings.name')
  },
  gallerySettings: {
    description: t(
      'adminDashboard:gallerySettings.description',
      'Manage your galleries'
    ),
    name: t('adminDashboard:gallerySettings.name')
  },
  resourceLibrarySettings: {
    description: t(
      'adminDashboard:resourceLibrarySettings.description',
      'Manage items in the resource library'
    ),
    name: t('adminDashboard:resourceLibrarySettings.name')
  },
  googleSearchSettings: {
    description: t(
      'adminDashboard:googleSearchSettings.description',
      'Manage Google Search settings'
    ),
    name: t(
      'adminDashboard:googleSearchSettings.name',
      'Google Search Settings'
    )
  },
  experimentSettings: {
    description: t(
      'adminDashboard:experimentSettings.description',
      'Update and manage organization experiments'
    ),
    name: t('adminDashboard:experimentSettings.name')
  },
  manageQuickstarts: {
    description: t(
      'adminDashboard:manageQuickstarts.description',
      'Manage Quickstarts'
    ),
    name: t('adminDashboard:manageQuickstarts.name')
  }
});

// order here matters
export const settingsAdminQuickStarts = () => {
  const text = getSettingsAdminQuickStartsText();

  const quickStarts = [
    {
      description: text.createOrganization.description,
      disabled: !isDev,
      icon: PlusIcon,
      name: text.createOrganization.name,
      path: paths.admin.createOrganization,
      visible: !isOrgAdminUser
    },
    {
      description: text.skinSettings.description,
      disabled: false,
      icon: SkinSettingsIcon,
      name: text.skinSettings.name,
      path: paths?.admin?.settings?.skin,
      visible: isOrgAdminUser
    },
    {
      description: text.facebookSettings.description,
      disabled: !isDev,
      icon: FacebookIcon,
      name: text.facebookSettings.name,
      path: paths?.admin?.settings?.facebook,
      visible: isOrgAdminUser
    },
    {
      description: text.authenticationSettings.description,
      disabled: !isDev,
      icon: KeyIcon,
      name: text.authenticationSettings.name,
      path: paths?.admin?.settings?.authentication,
      visible: isOrgAdminUser
    },
    {
      description: text.webhookSettings.description,
      disabled: !isDev,
      icon: WebhookIcon,
      name: text.webhookSettings.name,
      path: paths?.admin?.settings?.webhook,
      visible: isOrgAdminUser
    },
    {
      description: text.architectureSettings.description,
      disabled: !isDev,
      icon: ArchitectureIcon,
      name: text.architectureSettings.name,
      path: paths?.admin?.settings?.architecture,
      visible: isOrgAdminUser
    },

    {
      description: text.gallerySettings.description,
      disabled: !isDev,
      icon: MediaIcon,
      name: text.gallerySettings.name,
      path: paths?.admin?.settings?.galleries,
      visible: isOrgAdminUser
    },

    {
      description: text.resourceLibrarySettings.description,
      disabled: !isDev,
      icon: ResourceLibraryIcon,
      name: text.resourceLibrarySettings.name,
      path: paths?.admin?.settings?.resourceLibrary,
      visible: isOrgAdminUser
    },
    {
      description: text.googleSearchSettings.description,
      disabled: !isDev,
      icon: SearchIcon,
      name: text.googleSearchSettings.name,
      path: paths?.admin?.settings?.googleSearch,
      visible: isOrgAdminUser
    },
    {
      description: text.experimentSettings.description,
      disabled: false,
      icon: ScienceIcon,
      name: text.experimentSettings.name,
      path: paths?.admin?.settings?.experiments,
      visible: isOrgAdminUser
    },
    {
      description: text.manageQuickstarts.description,
      icon: BoltIcon,
      name: text.manageQuickstarts.name,
      path: paths?.admin?.settings?.quickStarts,
      visible: isOrgAdminUser
    },
    {
      description: t('adminDashboard:notificationPreferences.description'),
      icon: EmailIcon,
      name: t('adminDashboard:notificationPreferences.name'),
      path: paths?.admin?.settings?.notificationPreferences,
      visible: isOrgAdminUser
    }
  ];

  return quickStarts;
};

const getMoneyAdminQuickstartsText = () => ({
  billingSettings: {
    description: t(
      'adminDashboard:billingSettings.description',
      'Manage billing settings'
    ),
    name: t('adminDashboard:billingSettings.name')
  },
  channelSpendAllocationSettings: {
    description: t(
      'adminDashboard:channelSpendAllocationSettings.description',
      'Manage channel spend allocation configurations'
    ),
    name: t(
      'adminDashboard:channelSpendAllocationSettings.name',
      'Channel Spend Allocation Settings'
    )
  },
  pricingPlanSettings: {
    description: t(
      'adminDashboard:pricingPlanSettings.description',
      'Manage pricing plan settings'
    ),
    name: t(
      'adminDashboard:pricingPlanSettings.name',
      'Pricing Plans & Tiers Settings'
    )
  },
  promotions: {
    description: t(
      'adminDashboard:promotions.description',
      'Manage your promotions'
    ),
    name: t('adminDashboard:promotions.name')
  }
});

export const moneyAdminQuickStarts = () => {
  const text = getMoneyAdminQuickstartsText();
  return [
    {
      description: text.billingSettings.description,
      disabled: !isDev,
      icon: CreditCardIcon,
      name: text.billingSettings.name,
      path: paths?.admin?.settings?.billing,
      visible: isOrgAdminUser
    },
    {
      description: text.channelSpendAllocationSettings.description,
      disabled: !isDev,
      icon: AttachMoney,
      name: text.channelSpendAllocationSettings.name,
      path: paths?.admin?.settings?.channelSpendAllocation,
      visible: isOrgAdminUser
    },
    {
      description: text.pricingPlanSettings.description,
      disabled: false,
      icon: SellIcon,
      name: text.pricingPlanSettings.name,
      path: paths?.admin?.settings?.pricing,
      visible: isOrgAdminUser
    },
    {
      description: text.promotions.description,
      disabled: !isDev && !isOrgAdminUser,
      icon: ContentIcon,
      name: text.promotions.name,
      path: paths?.admin?.settings?.promotions,
      visible: isOrgAdminUser
    }
  ];
};

const contentAdminQuickStartsText = () => ({
  contentTablesSettings: {
    description: t(
      'adminDashboard:contentTablesSettings.description',
      'Content Tables Settings'
    ),
    name: t(
      'adminDashboard:contentTablesSettings.name',
      'Content Tables Settings'
    )
  },
  catalogContentSettings: {
    description: t(
      'adminDashboard:catalogContentSettings.description',
      'Catalog & Cntent Settings'
    ),
    name: t(
      'adminDashboard:catalogContentSettings.name',
      'Catalog & Content Settings'
    )
  },
  contentDataManager: {
    description: t(
      'adminDashboard:contentDataManager.description',
      'Manage content data for your organization.'
    ),
    name: t('adminDashboard:contentDataManager.name')
  }
});

export const contentAdminQuickStarts = () => {
  const text = contentAdminQuickStartsText();

  return [
    {
      description: text.contentTablesSettings.description,
      disabled: !isDev,
      icon: ContentIcon,
      name: text.contentTablesSettings.name,
      path: paths?.admin?.settings?.contentTablesLanding,
      visible: isOrgAdminUser
    },
    {
      description: text.catalogContentSettings.description,
      disabled: !isDev,
      icon: CatalogContentIcon,
      name: text.catalogContentSettings.name,
      path: paths?.admin?.settings?.catalogContentLanding,
      visible: isOrgAdminUser
    },
    {
      description: text.contentDataManager.description,
      disabled: !isDev,
      icon: StorageIcon,
      name: text.contentDataManager.name,
      path: paths?.admin?.settings?.contentDataManager,
      visible: isOrgAdminUser
    }
  ];
};

const getToolsAdminQuickstartsText = () => ({
  facebookSupportTools: {
    description: t(
      'adminDashboard:facebookSupportTools.description',
      'Tools to help with common Facebook & Instagram issues'
    ),
    name: t(
      'adminDashboard:facebookSupportTools.name',
      'Facebook Support Tools'
    )
  },
  partnerPortalAccessTool: {
    description: t(
      'adminDashboard:partnerPortalAccessTool.description',
      'Tool for generating access to partner portal'
    ),
    name: t(
      'adminDashboard:partnerPortalAccessTool.name',
      'Partner Portal Access Tool'
    )
  },
  audienceTool: {
    description: t('adminDashboard:audienceTool.description'),
    name: t('adminDashboard:audienceTool.name')
  },
  manuallyLinkOrders: {
    description: t(
      'adminDashboard:manuallyLinkOrders.description',
      'ManuallyLinkOrders'
    ),
    name: t('adminDashboard:manuallyLinkOrders.name')
  },
  createUser: {
    description: {
      admin: t(
        'adminDashboard:createUser.description.admin',
        'Create non-admin and evocalize admin users'
      ),
      nonAdmin: t(
        'adminDashboard:createUser.description.nonAdmin',
        'This tool is currently only available for the primary Evocalize organization'
      )
    },
    name: t('adminDashboard:createUser.name')
  },
  cancelOrder: {
    description: t(
      'adminDashboard:cancelOrder.description',
      'Cancel An Order Via Order ID'
    ),
    name: t('adminDashboard:cancelOrder.name')
  }
});

// order here matters
export const toolsAdminQuickStarts = () => {
  const text = getToolsAdminQuickstartsText();

  const quickStarts = [
    {
      description: text.facebookSupportTools.description,
      disabled: false,
      icon: FacebookIcon,
      name: text.facebookSupportTools.name,
      path: paths?.admin?.tools?.facebook,
      visible: isOrgAdminUser
    },
    {
      description: text.partnerPortalAccessTool.description,
      disabled: false,
      icon: KeyIcon,
      name: text.partnerPortalAccessTool.name,
      path: paths?.admin?.tools?.partner,
      visible: isOrgAdminUser
    },
    {
      description: text.audienceTool.description,
      icon: PersonIcon,
      name: text.audienceTool.name,
      path: paths?.admin?.tools?.audience,
      visible: isOrgAdminUser
    },
    {
      description: text.manuallyLinkOrders.description,
      icon: AddLinkIcon,
      name: text.manuallyLinkOrders.name,
      path: paths?.admin?.tools?.manuallyLinkOrders,
      visible: isOrgAdminUser
    },
    {
      description: isOrgAdminUser
        ? text.createUser.description.admin
        : text.createUser.description.nonAdmin,
      disabled: false,
      icon: AddUserIcon,
      name: text.createUser.name,
      path: paths?.admin?.createUser,
      visible: isOrgAdminUser
    },
    {
      description: text.cancelOrder.description,
      disabled: false,
      icon: CancelIcon,
      name: text.cancelOrder.name,
      path: paths?.admin?.tools?.cancelOrder,
      visible: isOrgAdminUser
    }
  ];

  return quickStarts;
};

const getDeveloperToolsAdminQuickstartsText = () => ({
  clientKeys: {
    description: t(
      'adminDashboard:clientKeys.description',
      'API key information'
    ),
    name: t('adminDashboard:clientKeys.name')
  },
  hanldebarMapper: {
    description: t('adminDashboard:handlebarMapper.description'),
    name: t('adminDashboard:handlebarMapper.name')
  }
});

export const developerToolsAdminQuickStarts = () => {
  const text = getDeveloperToolsAdminQuickstartsText();

  const quickStarts = [
    {
      description: text.clientKeys.description,
      disabled: false,
      icon: CodeIcon,
      name: text.clientKeys.name,
      path: paths.admin.developer.clientKeys,
      visible: isOrgAdminUser
    },
    {
      description: text.hanldebarMapper.description,
      disabled: false,
      icon: DataObjectIcon,
      name: text.hanldebarMapper.name,
      path: paths.admin.developer.handlebarMapper,
      visible: isOrgAdminUser
    }
  ];

  return quickStarts;
};

// order here matters
export const utilitiesAdminQuickStarts = () => {
  const quickStarts = [
    // Tool is not that useful right now so removing from UI
    // {
    //     description: 'Tool to help create text & image overlays',
    //     disabled: false,
    //     icon: CloudinaryTemplateIcon,
    //     name: 'Cloudinary Template Builder',
    //     path: get(paths, 'admin.utilities.imageTemplateBuilder'),
    //     visible: isOrgAdminUser
    // }
  ];

  return quickStarts;
};
