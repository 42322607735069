import Message from 'src/components/Containers/Message';
import ClientHtml from 'src/components/ClientHtml';

interface CustomFormMessageProps {
  message: HTMLElement | string;
  type?: 'info' | 'error' | 'warn';
}

const CustomFormMessage = ({
  message,
  type = 'info'
}: CustomFormMessageProps) => {
  return (
    <Message
      sx={{
        margin: '8px auto',
        whiteSpace: 'normal !important',
        width: '100%',
        justifyContent: 'flex-start'
      }}
      iconSize="small"
      type={type}
    >
      <ClientHtml html={message} />
    </Message>
  );
};

export default CustomFormMessage;
