import { isString, forEach, reduce, find, isEmpty } from 'lodash';

import { Chip } from '@mui/material';

import { messages } from 'src/common/validations';
import {
  filterOutConditionalInputs,
  ConditionalInputsVisibility
} from 'src/common/conditionals';
import { isAdPreviewTemplate } from 'src/common/templateTranslator';
import {
  ProductInputField,
  ProductInputSection,
  ValidationError
} from 'src/generated/gql/graphql';
import { PROGRAM_FORM_SECTION_DYNAMIC_INPUTS_NAME } from 'src/pages/Program/Constants';
import { LOCATIONS_OVERRIDES_BY_ID_NAME } from 'src/pages/Program/ProgramSteps/MultiLocationPublish/utils';
import { PHONE_MOCK_MESSAGE_WIDTH, UNIFORM_PREVIEW_WIDTH } from './Constants';
import {
  GOOGLE_DISPLAY_IMAGE_HEIGHT,
  GOOGLE_PREVIEW_WIDTH
} from './GoogleAdPreview/GoogleAdPreviewConstants';

// create unique keys based on strings
// duplicate keys are incremented so not amazing but avoids crashes
const keyer = (() => {
  const keys: Record<string, number> = {};
  return (string: string) => {
    const key = string.replace(' ', '0').replace(/[^a-zA-Z0-9]/g, '');
    if (!keys[key]) {
      keys[key] = 0;
    }
    return `${key}-${keys[key]++}`;
  };
})();

export const addChips = (value: any) => {
  if (isString(value) && isAdPreviewTemplate(value)) {
    // preview data will come back like: "stuff [[ friendly name ]] more text"

    // split the string into chunks and template matches
    const matches = [...value.matchAll(/\[\[([^[\]]+)\]\]/g)];
    const stringBits = value.split(/\[\[[^[\]]+\]\]/g);

    // shuffle the string bits and template contents together:
    return (
      <>
        {stringBits.reduce((items, string, i) => {
          return [
            ...items,
            <span key={keyer(string)}>{string}</span>,
            ...(matches[i]
              ? [
                  <Chip
                    label={matches[i][1]}
                    key={keyer(matches[i][1])}
                    size="small"
                    variant="outlined"
                    style={{
                      fontWeight: 'normal',
                      fontSize: 'inherit',
                      textTransform: 'capitalize'
                    }}
                  />
                ]
              : [])
          ];
        }, [] as JSX.Element[])}
      </>
    );
  }

  return value;
};

// Fallback value for defaultWidth
const DEFAULT_WIDTH = 400;

export const getPreviewWidth = (
  hasUniformPreviewWidth: boolean,
  defaultWidth = DEFAULT_WIDTH,
  isResponsive: boolean
) => {
  if (isResponsive) return '100%';
  if (hasUniformPreviewWidth) return `${UNIFORM_PREVIEW_WIDTH}px`;
  return `${defaultWidth}px`;
};

export const getGoogleDisplayImageHeight = (
  hasUniformPreviewWidth: boolean,
  isRepsonsive: boolean
) => {
  if (hasUniformPreviewWidth)
    return `calc(${
      GOOGLE_DISPLAY_IMAGE_HEIGHT / GOOGLE_PREVIEW_WIDTH
    } * ${UNIFORM_PREVIEW_WIDTH})`;

  if (isRepsonsive) return '100%';

  return GOOGLE_DISPLAY_IMAGE_HEIGHT;
};

export const getMessageMaxWidth = (displayAsPhoneMockUp: boolean) => {
  if (displayAsPhoneMockUp) return PHONE_MOCK_MESSAGE_WIDTH;
  return '100%';
};

export const getMessageIconSize = (displayAsPhoneMockUp: boolean) => {
  if (displayAsPhoneMockUp) return 'small';
  return 'medium';
};

interface InputField extends ProductInputField {
  fieldName: string;
}
interface InputSection extends ProductInputSection {
  inputFields: InputField[];
}

interface Blueprint {
  inputSections: InputSection[];
}

export const mapInputFieldsById = (blueprint: Blueprint) => {
  const inputFieldsById: Record<string, any> = {};
  forEach(blueprint?.inputSections, section => {
    forEach(section.inputFields, inputField => {
      inputFieldsById[inputField.id] = inputField;
    });
  });
  return inputFieldsById;
};

type ProductInputFieldsById = Record<string, InputField>;

interface GetInputIdsAndValuesArgs {
  filteredDynamicUserInputs: Record<string, any>;
  productInputFieldsById: ProductInputFieldsById;
}

interface FormattedInputValue {
  value: any;
  productInputFieldId: string;
}

export const getInputIdsAndValues = ({
  filteredDynamicUserInputs,
  productInputFieldsById
}: GetInputIdsAndValuesArgs) => {
  const inputIdsAndValues = reduce(
    filteredDynamicUserInputs,
    (formattedInputs, value, key) => {
      const productInputField = find(productInputFieldsById, {
        fieldName: key
      });

      let newFormattedInputs = [...formattedInputs];

      if (productInputField?.id && value !== null && value !== undefined) {
        newFormattedInputs = [
          ...newFormattedInputs,
          { value, productInputFieldId: productInputField.id }
        ];
      }

      return newFormattedInputs;
    },
    [] as FormattedInputValue[]
  );
  return inputIdsAndValues;
};

interface FindAndSetInputErrorsProps {
  productInputFieldsById: ProductInputFieldsById;
  inputValidationErrors: undefined | ValidationError[];
  setError: any;
  clearErrors: any;
  locationId?: string;
  conditionalInputsVisibility: ConditionalInputsVisibility;
}

export type ValidationErrors = {
  inputField?: InputField;
  locationId?: string;
  error: { type?: string; message?: string };
}[];

export const findAndSetInputErrors = ({
  productInputFieldsById,
  inputValidationErrors,
  setError,
  clearErrors,
  locationId,
  conditionalInputsVisibility
}: FindAndSetInputErrorsProps): ValidationErrors => {
  const filteredInputValidationErrors = inputValidationErrors?.filter(error => {
    const inputField = productInputFieldsById[error.inputFieldId];

    // not all inputs are in conditionalInputsVisibility, if it's not it's visible
    if (conditionalInputsVisibility?.[inputField.fieldName]) {
      // the api will return errors for hidden fields, we need to check if the field is visible
      const inputVisible =
        conditionalInputsVisibility?.[inputField.fieldName].isVisible;

      return inputVisible;
    }

    return true;
  });

  if (isEmpty(filteredInputValidationErrors)) {
    // clear them errors
    clearErrors();
    return [];
  }
  const errors: ValidationErrors = [];

  forEach(filteredInputValidationErrors, error => {
    const inputField = productInputFieldsById[error.inputFieldId];

    if (inputField) {
      const errorObj = {
        type: error.errorCode,
        message: messages()?.[error.errorCode] || error.errorCode
      };
      setError(
        `${
          locationId
            ? `${LOCATIONS_OVERRIDES_BY_ID_NAME}.${locationId}.${PROGRAM_FORM_SECTION_DYNAMIC_INPUTS_NAME}`
            : PROGRAM_FORM_SECTION_DYNAMIC_INPUTS_NAME
        }.${inputField.fieldName}`,
        errorObj
      );
      errors.push({ inputField, error: errorObj, locationId });
    }
  });

  return errors;
};

interface GetSelectedLocationsAndOverridesArgs {
  selectedLocations: string[];
  locationsOverrideById: Record<string, any>;
  conditionalInputsVisibility: ConditionalInputsVisibility;
  productInputFieldsById: ProductInputFieldsById;
}

export const getSelectedLocationsAndOverrides = ({
  selectedLocations = [],
  locationsOverrideById = {},
  conditionalInputsVisibility,
  productInputFieldsById
}: GetSelectedLocationsAndOverridesArgs) => {
  const locationsAndOverrides = selectedLocations.map(locationId => {
    const overrides = locationsOverrideById[locationId];

    const variableValues = conditionalInputsVisibility
      ? filterOutConditionalInputs(
          conditionalInputsVisibility,
          overrides?.dynamicUserInputs || []
        )
      : overrides?.dynamicUserInputs || [];

    // formValues is a bit of a confusing name, this is a list of inputs for BE input validations
    const formValues = getInputIdsAndValues({
      filteredDynamicUserInputs: variableValues,
      productInputFieldsById
    });

    return {
      locationId,
      ...(overrides && { variableValues }),
      ...(formValues.length && { formValues })
    };
  });

  return locationsAndOverrides;
};
