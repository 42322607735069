import { useQuery } from '@apollo/client';
import { Trans } from 'react-i18next';
import { pick } from 'lodash';

import { Grid } from '@mui/material';

import { useGlobalContext } from 'src/GlobalContextProvider';
import { useAppSettings } from 'src/AppSettings';

import { getDemoInsights, insightTypes } from 'src/common/demoUser/helpers';

import Kpi from 'src/components/Kpi';
import Loading from 'src/components/Loading';
import ErrorMessage from 'src/components/Containers/ErrorMessage';

import { ordersV2 } from './queries';

const extractKPIs = (programs = []) => {
  return programs.reduce(
    (accum, program) => {
      const kpis = program?.products?.[0]?.kpis || [];
      kpis.forEach(kpi => {
        // Note: some kpis like impressions have the same slug in google and facebook
        //       so we also need to check on channel as well.
        if (
          !accum.kpiKeys.has(kpi.slug) ||
          !accum.kpiChannels.has(kpi.channelCode)
        ) {
          accum.kpiKeys.add(kpi.slug);
          accum.kpiChannels.add(kpi.channelCode);
          accum.kpis.push(pick(kpi, ['slug', 'channelCode']));
        }
      });

      return accum;
    },
    { kpiChannels: new Set(), kpiKeys: new Set(), kpis: [] }
  ).kpis;
};

const DashboardKPIs = () => {
  const appSettings = useAppSettings();
  const globalContext = useGlobalContext();
  const userId = globalContext?.me?.id;

  const { data, loading, error } = useQuery(ordersV2);

  const orders = (data?.ordersV2?.edges || []).map(order => order.node);
  const kpis = extractKPIs(orders);

  const demoInsights = getDemoInsights({
    userId,
    appSettings,
    kpis,
    type: insightTypes.dashboard
  });

  return (
    <Grid item xs={12}>
      {loading && <Loading />}
      {error && (
        <ErrorMessage>
          <Trans i18nKey="programPerf:kepi.error">
            There was an error fetching metrics data
          </Trans>
        </ErrorMessage>
      )}
      {!loading && !error && (
        <Kpi
          demoInsights={demoInsights}
          kpis={kpis}
          programIds={orders.map(p => p.programId)}
          sourcePage="dashboard"
        />
      )}
    </Grid>
  );
};

export default DashboardKPIs;
