import { gql } from 'src/generated/gql';

// States of WORKATO_CONNECT_FIRST_TIME_MODAL_DISMISS:
//  - undefined : first time load user has taken no action
//  - false     : user has dismissed the modal / closed the modal
//  - true      : user dismissed the modal forever or successfully connected to workato
export const setDismissModal = gql(`
  mutation updateUserSettingsFromChrome(
    $updateSettings: JSON!
    $updateType: UserSettingUpdateType
  ) {
    updateUserSettings(
      updateSettings: $updateSettings
      updateType: $updateType
    ) {
      newSettings
      previousSettings
    }
  }
`);
