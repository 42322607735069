import { useHistory, useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';

import { isProgramClone } from 'src/common/cloneProgram';
import { useUserflow } from 'src/PostAuthGlobals';

import { programActions, ProgramActionType } from '../../Constants';
import {
  placeOrder,
  createSupervisorOrder,
  updateSupervisorOrder,
  placeMultiLocationProgram
} from '../../mutations';
import {
  createProgramHandleSubmit,
  submitCreateProgramDataInterface
} from './createProgram';
import {
  createAutomationHandleSubmit,
  submitCreateAutomationDataInterface
} from './createAutomation';
import {
  submitEditAutomationDataInterface,
  editAutomationHandleSubmit
} from './editAutomation';
import { createMultiLocationProgramHandleSubmit } from './createMultiLocationProgram';

// TODO: audit and update what gets passed down in the programProps object
// This is the same across all submit handlers
export interface submitProgramPropsInterface {
  [any: string]: any;
}

const useSubmitHandlerByType = (type: ProgramActionType, draftId?: string) => {
  const history = useHistory();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const isClone = isProgramClone(location.search);

  const [createPlaceOrder] = useMutation(placeOrder);
  const [createMultiLocationProgram] = useMutation(placeMultiLocationProgram);
  const [placeSupervisorOrder] = useMutation(createSupervisorOrder);
  const [editSupervisorOrder] = useMutation(updateSupervisorOrder);
  const { track: trackUserflowEvent } = useUserflow();

  // Program Create
  if (type === programActions.create) {
    return (
      data: submitCreateProgramDataInterface,
      programProps: submitProgramPropsInterface
    ) =>
      createProgramHandleSubmit({
        data,
        programProps,
        isClone,
        draftId,
        mutation: createPlaceOrder,
        history,
        enqueueSnackbar,
        trackUserflowEvent
      });
  }
  // Program Edit
  // NOTE: Edit currently happens in a modal on the program details page

  // Automation Create
  if (type === programActions.automatedCreate) {
    return (
      data: submitCreateAutomationDataInterface,
      programProps: submitProgramPropsInterface
    ) =>
      createAutomationHandleSubmit({
        data,
        programProps,
        draftId,
        mutation: placeSupervisorOrder,
        history,
        enqueueSnackbar
      });
  }
  // Automated Edit
  if (type === programActions.automatedEdit) {
    return (
      data: submitEditAutomationDataInterface,
      programProps: submitProgramPropsInterface
    ) =>
      editAutomationHandleSubmit({
        data,
        programProps,
        mutation: editSupervisorOrder,
        history,
        enqueueSnackbar
      });
  }

  // Multi Location Create
  // Multi Location Edit

  if (type === programActions.multiLocationCreate) {
    return (
      data: submitEditAutomationDataInterface,
      programProps: submitProgramPropsInterface
    ) =>
      createMultiLocationProgramHandleSubmit({
        data,
        programProps,
        draftId,
        mutation: createMultiLocationProgram,
        history,
        enqueueSnackbar,
        trackUserflowEvent
      });
  }

  // unknown type: To keep the return signature consistent
  return (_data: any, _programProps: any) => {
    return new Promise(resolve =>
      resolve({ afterSuccess: () => {}, response: null })
    );
  };
};

export default useSubmitHandlerByType;
