import { BaseSyntheticEvent, useEffect, useMemo } from 'react';
import { useTheme, lighten } from '@mui/system';
import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material';
import {
  capitalize,
  find,
  forEach,
  isEmpty,
  kebabCase,
  map,
  uniq
} from 'lodash';
import {
  FieldValues,
  useFormContext,
  UseFormReturn,
  useWatch
} from 'react-hook-form';
import { t } from 'i18next';
import {
  ChevronLeft as PrevIcon,
  ChevronRight as NextIcon,
  Loop as RegenerateIcon,
  RecordVoiceOverOutlined as StylizeOutlinedIcon,
  RecordVoiceOver as StylizeFilledIcon,
  ThumbDownOutlined as DislikeOutlinedIcon,
  ThumbDown as DislikeFilledIcon
} from '@mui/icons-material';
import { useLazyQuery } from '@apollo/client';

import { useArchitecture } from 'src/pages/Architecture/ArchitectureProvider';
import FormInput from 'src/components/ReduxForm/DynamicForm/FormInput';
import { useGlobalContext } from 'src/GlobalContextProvider';
import { AssetMenu, useMenu } from 'src/components/Menus';
import Instrumentation from 'src/instrumentation';
import useProgram from 'src/pages/Program/utils/useProgram';
import { AiStyle } from 'src/generated/gql/graphql';
import { PROGRAM_FORM_SECTION_DYNAMIC_INPUTS_NAME } from 'src/pages/Program/Constants';
import {
  getAiStylizeText,
  selectableStyles
} from 'src/common/aiAdCopySuggestion';
import MessageBackground from './MessageBackground';
import { InputField } from '../helpers';
import {
  getAiTextSuggestions as getAiTextSuggestionsQuery,
  getAiTextStylizations as getAiTextStylizationsQuery
} from '../queries';
import useHandleError from '../useHandleError';

interface AdCopySuggestionMessageProps {
  input: InputField;
  sharedInputProps: Record<string, any>;
  setIsThinking: (isThinking: boolean) => void;
  parentFormMethods: UseFormReturn<FieldValues, any, undefined>;
  highlightInputs: (blueprintVariableIds?: string[]) => void;
  currentSuggestionIndex: number;
  setCurrentSuggestionIndex: (index: number) => void;
}

const AdCopySuggestionMessage = ({
  input,
  sharedInputProps,
  setIsThinking,
  parentFormMethods,
  highlightInputs,
  currentSuggestionIndex,
  setCurrentSuggestionIndex
}: AdCopySuggestionMessageProps) => {
  const buttonId = `ai-chat-suggestion-message-menu-button-${input?.fieldName}`;
  const menuId = `ai-chat-suggestion-message-menu-${input?.fieldName}`;
  const text = useMemo(() => getAiStylizeText(), []);
  const context = useFormContext();
  const architecture = useArchitecture();
  const [
    getAiTextSuggestions,
    { loading: regenerateLoading, error: regenerateError }
  ] = useLazyQuery(getAiTextSuggestionsQuery);

  const [
    getAiTextStylizations,
    { loading: stylizeLoading, error: stylizeError }
  ] = useLazyQuery(getAiTextStylizationsQuery, { fetchPolicy: 'no-cache' });
  const handleError = useHandleError();

  const { handleOpenMenu, isMenuOpen, menuAnchorEl, handleCloseMenu } =
    useMenu();
  const theme = useTheme();
  const bgcolor = lighten(theme.palette.primary.main, 0.9);

  const metadata = { ...input, isRequired: false };
  const disabled = metadata?.disabled ?? false;
  const globalContext = useGlobalContext();
  const { selectedBlueprint } = useProgram();

  const displayName = input?.displayName;
  const aiChatFormInputValues = useWatch({ name: input?.blueprintVariableId });
  const productId = selectedBlueprint?.id;
  const hasMultipleBusinessObjects =
    sharedInputProps?.businessObjects?.length > 1;
  const businessObjectId = hasMultipleBusinessObjects
    ? ''
    : sharedInputProps?.businessObjects?.[0]?.id || '';

  const suggestions = aiChatFormInputValues?.suggestions || [];

  const value = suggestions[currentSuggestionIndex]?.text;
  const suggestion = suggestions[currentSuggestionIndex];
  const suggestionCount = suggestions.length;

  const commonTrackingData = {
    input: displayName,
    productId,
    architectureId: selectedBlueprint?.architectureId
  };

  const handleGetAiTextSuggestions = async ({
    fields = []
  }: {
    fields?: { blueprintVariableId: string; dislikedIndexes?: number[] }[];
  }) => {
    const catalogId = architecture.catalog?.id || '';
    const catalogFilter = {
      id: {
        in: businessObjectId ? [businessObjectId] : []
      }
    };

    const formattedFields = map(fields, field => {
      return {
        blueprintVariableId: field.blueprintVariableId,
        ...(field.dislikedIndexes?.length && {
          dislikedGeneration: map(field.dislikedIndexes, index => {
            return suggestions?.[index]?.text;
          })
        })
      };
    });

    const variables = {
      input: {
        fields: formattedFields,
        catalogId: catalogId || '',
        catalogFilter,
        productId,
        forceContentLessPrompt: hasMultipleBusinessObjects
      }
    };

    const response = await getAiTextSuggestions({
      variables,
      fetchPolicy: 'no-cache'
    }).catch(e => {
      handleError(e);
    });

    const responseFields = response?.data?.aiTextSuggestions?.fields;

    forEach(responseFields, field => {
      const argumentField = find(fields, {
        blueprintVariableId: field?.blueprintVariableId
      });

      if (
        field?.blueprintVariableId &&
        field?.suggestions?.[0] &&
        argumentField
      ) {
        context.setValue(field?.blueprintVariableId, {
          suggestions: [...suggestions, { text: field?.suggestions?.[0] }],
          ...(argumentField?.dislikedIndexes && {
            disliked: argumentField?.dislikedIndexes
          })
        });
      }
    });
  };

  const handleDislike = async () => {
    const isDisliked = aiChatFormInputValues.disliked?.includes(
      currentSuggestionIndex
    );

    Instrumentation.logEvent(Instrumentation.Events.ClickAiCopyDislike, {
      ...commonTrackingData,
      action: isDisliked ? 'remove' : 'add'
    });

    let newDisliked = [...(aiChatFormInputValues?.disliked || [])];

    // If isDisliked, we want to remove the current suggestion from the disliked list
    if (isDisliked) {
      newDisliked = newDisliked.filter(
        suggestion => suggestion !== currentSuggestionIndex
      );
      context.setValue(input.blueprintVariableId, {
        ...aiChatFormInputValues,
        disliked: newDisliked
      });
    } else {
      setIsThinking(true);
      const existingDisliked = aiChatFormInputValues?.disliked || [];
      newDisliked = uniq([...existingDisliked, currentSuggestionIndex]);

      // Get new suggestions if disliked
      await handleGetAiTextSuggestions({
        fields: [
          {
            blueprintVariableId: input.blueprintVariableId,
            dislikedIndexes: newDisliked
          }
        ]
      }).catch(handleError);
      setCurrentSuggestionIndex(aiChatFormInputValues.suggestions.length);
      setIsThinking(false);
    }
  };

  const handleRegenerate = async () => {
    setIsThinking(true);
    Instrumentation.logEvent(
      Instrumentation.Events.ClickAiCopyRegenerate,
      commonTrackingData
    );

    const existingDisliked = aiChatFormInputValues?.disliked || [];
    await handleGetAiTextSuggestions({
      fields: [
        {
          blueprintVariableId: input.blueprintVariableId,
          dislikedIndexes: existingDisliked
        }
      ]
    })
      .catch(handleError)
      .finally(() => {
        setIsThinking(false);
      });

    setCurrentSuggestionIndex(currentSuggestionIndex + 1);
  };

  const handleApply = () => {
    Instrumentation.logEvent(
      Instrumentation.Events.ClickAiCopyApply,
      commonTrackingData
    );

    const currentSuggestionText = suggestions[currentSuggestionIndex]?.text;

    const parentFormInputName = `${PROGRAM_FORM_SECTION_DYNAMIC_INPUTS_NAME}.${input.fieldName}`;
    parentFormMethods.setValue(`${parentFormInputName}`, currentSuggestionText);

    parentFormMethods.setFocus(parentFormInputName);
  };

  const handleSuggestionChange = (value: string | BaseSyntheticEvent) => {
    let val = '';

    if (typeof value === 'string') {
      // Handles RenderTemplateStringTextField case where onChange arg is a string
      val = value;
    } else if (value?.target?.value) {
      // Handles RenderTextField case where onChange arg is a BaseSyntheticEvent
      val = value.target.value;
    }

    const newSuggestion = {
      ...aiChatFormInputValues.suggestions[currentSuggestionIndex],
      text: val
    };

    const newSuggestions = [...aiChatFormInputValues.suggestions];
    newSuggestions[currentSuggestionIndex] = newSuggestion;

    context.setValue(input.blueprintVariableId, {
      ...aiChatFormInputValues,
      suggestions: newSuggestions
    });
  };

  const nextSuggestion = () => {
    setCurrentSuggestionIndex(
      Math.min(currentSuggestionIndex + 1, suggestionCount - 1)
    );
  };

  const prevSuggestion = () => {
    setCurrentSuggestionIndex(Math.max(currentSuggestionIndex - 1, 0));
  };

  useEffect(() => {
    if (suggestionCount - 1 !== currentSuggestionIndex) {
      setCurrentSuggestionIndex(suggestionCount - 1);
    }
  }, [suggestionCount]);

  const error = regenerateError || stylizeError;
  const loading = regenerateLoading || stylizeLoading;

  useEffect(() => {
    if (error) {
      handleError(error);
    }
  }, [error]);

  const kebabCaseFriendlyName = kebabCase(metadata?.displayName);

  const isDisliked = aiChatFormInputValues?.disliked?.includes(
    currentSuggestionIndex
  );

  const currentSuggestionText = suggestions[currentSuggestionIndex]?.text;
  const isCurrentSuggestionTextEmpty = isEmpty(currentSuggestionText);

  const getStylizeTooltip = () => {
    if (isCurrentSuggestionTextEmpty) {
      return t('aiSuggestion:chat.stylizeButtonTooltipEmpty');
    }

    return `${t('aiSuggestion:chat.stylizeButtonTooltip')}${suggestion?.style ? `: ${text.styleOption[suggestion.style.toLowerCase() as keyof typeof selectableStyles]}` : ''}`;
  };

  const getDislikedTooltip = () => {
    if (isCurrentSuggestionTextEmpty) {
      return t('aiSuggestion:chat.dislikeButtonTooltipEmpty');
    }

    return isDisliked
      ? t('aiSuggestion:chat.dislikedButtonTooltip')
      : t('aiSuggestion:chat.dislikeButtonTooltip');
  };

  return (
    <MessageBackground
      bgcolor={bgcolor}
      data-cy={`ad-copy-suggestion-message-${kebabCaseFriendlyName}`}
      sx={{ pt: 2 }}
    >
      <FormInput
        isHookForm
        isAdCopySuggestionMessageInput
        metadata={metadata}
        disabled={disabled}
        globalContext={globalContext}
        labelBackground={lighten(theme.palette.primary.main, 0.9)}
        onChange={handleSuggestionChange}
        value={value}
        {...sharedInputProps}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
          <Box
            data-cy={`ad-copy-suggestion-pagination-${kebabCaseFriendlyName}`}
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <IconButton
              data-cy="ad-copy-suggestion-pagination-prev"
              size="small"
              onClick={prevSuggestion}
              disabled={loading}
            >
              <PrevIcon fontSize="small" />
            </IconButton>
            <Typography variant="body2">{`${currentSuggestionIndex + 1}/${suggestionCount}`}</Typography>
            <IconButton
              data-cy="ad-copy-suggestion-pagination-next"
              size="small"
              onClick={nextSuggestion}
              disabled={loading}
            >
              <NextIcon fontSize="small" />
            </IconButton>
          </Box>
          <Tooltip title={t('aiSuggestion:chat.regenerateButtonTooltip')}>
            <span>
              <IconButton
                data-cy={`ad-copy-suggestion-regenerate-button-${kebabCaseFriendlyName}`}
                size="small"
                onClick={() => {
                  handleRegenerate().catch(handleError);
                }}
                disabled={loading}
              >
                <RegenerateIcon fontSize="small" />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title={getDislikedTooltip()}>
            <span>
              <IconButton
                data-cy={`ad-copy-suggestion-dislike-button-${kebabCaseFriendlyName}`}
                size="small"
                onClick={() => {
                  handleDislike().catch(handleError);
                }}
                disabled={loading || isCurrentSuggestionTextEmpty}
              >
                {isDisliked ? (
                  <DislikeFilledIcon fontSize="small" />
                ) : (
                  <DislikeOutlinedIcon fontSize="small" />
                )}
              </IconButton>
            </span>
          </Tooltip>
          <>
            <Tooltip title={getStylizeTooltip()}>
              <span>
                <IconButton
                  data-cy={`ad-copy-suggestion-stylize-button-${kebabCaseFriendlyName}`}
                  size="small"
                  aria-controls={isMenuOpen ? menuId : undefined}
                  aria-expanded={isMenuOpen ? 'true' : undefined}
                  aria-haspopup="true"
                  onClick={handleOpenMenu}
                  id={buttonId}
                  disabled={loading || isCurrentSuggestionTextEmpty}
                >
                  {suggestion?.style ? (
                    <StylizeFilledIcon fontSize="small" />
                  ) : (
                    <StylizeOutlinedIcon fontSize="small" />
                  )}
                </IconButton>
              </span>
            </Tooltip>

            <AssetMenu
              id={menuId}
              handleClose={handleCloseMenu}
              open={isMenuOpen}
              ariaLabeledBy={buttonId}
              anchorEl={menuAnchorEl}
              disabled={loading}
              options={Object.keys(selectableStyles).map(style => {
                return {
                  label:
                    text.styleOption[style as keyof typeof selectableStyles],
                  onClick: () => {
                    Instrumentation.logEvent(
                      Instrumentation.Events.ClickAiStylize,
                      {
                        input: displayName,
                        style,
                        productId,
                        architectureId: selectedBlueprint?.architectureId
                      }
                    );

                    setIsThinking(true);

                    const sourceText =
                      suggestions?.[currentSuggestionIndex]?.text;

                    getAiTextStylizations({
                      variables: {
                        input: {
                          productId,
                          fields: [
                            {
                              blueprintVariableId: input.blueprintVariableId,
                              style:
                                AiStyle[
                                  capitalize(style) as keyof typeof AiStyle
                                ],
                              sourceText
                            }
                          ]
                        }
                      }
                    })
                      .then(result => {
                        const newStylization =
                          result?.data?.aiTextStylizations?.fields?.[0]
                            ?.stylizations?.[0];

                        const newSuggestions = [
                          ...aiChatFormInputValues.suggestions,
                          { text: newStylization, style }
                        ];

                        context.setValue(input.blueprintVariableId, {
                          ...aiChatFormInputValues,
                          suggestions: newSuggestions
                        });

                        setCurrentSuggestionIndex(currentSuggestionIndex + 1);
                      })
                      .catch(handleError)
                      .finally(() => {
                        setIsThinking(false);
                      });
                  }
                };
              })}
            />
          </>
        </Box>
        <Button
          data-cy={`ad-copy-suggestion-apply-button-${kebabCaseFriendlyName}`}
          disabled={loading}
          sx={{ mr: '6px' }}
          onClick={handleApply}
          onMouseEnter={() => {
            highlightInputs([input.blueprintVariableId]);
          }}
          onMouseLeave={() => {
            highlightInputs([]);
          }}
        >
          {t('aiSuggestion:chat.applyButton')}
        </Button>
      </Box>
    </MessageBackground>
  );
};

export default AdCopySuggestionMessage;
