import { createContext } from 'react';

export const THEME_NAMES = {
  default: 'default',
  automationPage: 'automationPage',
  programCreatePage: 'programCreatePage',
  facebookPageLinking: 'facebookPageLinking'
} as const;

type ThemeName = (typeof THEME_NAMES)[keyof typeof THEME_NAMES];

export const ThemeSwitcherContext = createContext(
  (_themeName: ThemeName) => {}
);

interface ThemeSwitcherProviderProps {
  value: (themeName: ThemeName) => void;
  children: React.ReactNode;
}
export const ThemeSwitcherProvider = ({
  children,
  value
}: ThemeSwitcherProviderProps) => (
  <ThemeSwitcherContext.Provider value={value}>
    {children}
  </ThemeSwitcherContext.Provider>
);
