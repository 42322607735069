import { useEffect, useMemo, useRef, useState } from 'react';
import { t } from 'i18next';
import { useWatch } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { Grid, Box, Card, CardHeader, CardContent } from '@mui/material';
import { styled, alpha } from '@mui/system';

import { ConditionalInputsVisibility } from 'src/common/conditionals';
import { useArchitecture } from 'src/pages/Architecture/ArchitectureProvider';
import useProgram from 'src/pages/Program/utils/useProgram';
import FacebookSettings from 'src/pages/Program/ProgramSteps/ProgramStepConfigure/FacebookSettings';
import ContentSelector from 'src/pages/Program/ProgramSteps/ProgramStepConfigure/ContentSelector';
import { FormSection } from 'src/components/ReduxForm';
import Instrumentation from 'src/instrumentation';
import {
  PROGRAM_FORM_SECTION_CONFIGURE_NAME,
  programTrackingTypes
} from 'src/pages/Program/Constants';

import DefaultValuesForm from './DefaultValuesForm';
import MultiLocationFooter from '../StepFooters/MultiLocationFooter';
import OrderTypeConfirmationModal from './OrderTypeConfirmationModal';

interface MultiLocationConfigureProps {
  selectedBusinessObjects: {
    selectedBusinessObjects: any[];
  };
  setSelectedBusinessObjects: (selectedObjects: any[]) => void;
  skipStep: any;
  setSkipStep: () => void;
  isChannelValidationLoading: boolean;
  selectedLocationsMetadata?: Location[];
  isMultiLocation?: boolean;
  conditionalInputsVisibility: ConditionalInputsVisibility;
  showValidationErrors: () => void;
}

const ContainerCard = styled(Card)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[300]}`
}));

const ContainerCardHeader = styled(CardHeader)(({ theme }) => ({
  background: alpha(theme.palette.info.main, 0.1),
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  p: theme.spacing(1.5, 3)
}));

const ContainerCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(1.5, 3),

  '&:last-child': { paddingBottom: theme.spacing(1.5) }
}));

const pageText = ({ contentName }: { contentName: string }) => {
  // Needs to be captured in a variable here otherwise scanner will return __TRANSLATION_NOT_FOUND__
  const lowerCaseContentName = contentName?.toLowerCase();

  return {
    defaultValueTitle: t(
      'programCreate:configureMultiLocation.defaultValueTitle'
    ),
    defaultValueDescription: t(
      'programCreate:configureMultiLocation.defaultValueDescription'
    ),
    facebookSectionTitle: t('programCreate:configure.facebookSectionTitle'),
    facebookSectionDescription: t(
      'programCreate:configure.facebookSectionDecription'
    ),
    contentSectionTitle: t('programCreate:configure.contentSectionTitle', {
      contentName
    }),
    contentSectionDescription: t(
      'programCreate:configure.contentSectionDescription',
      {
        contentName: lowerCaseContentName
      }
    )
  };
};

const MultiLocationConfigure = ({
  selectedBusinessObjects,
  setSelectedBusinessObjects,
  skipStep,
  setSkipStep,
  isChannelValidationLoading,
  selectedLocationsMetadata = [],
  conditionalInputsVisibility,
  showValidationErrors
}: MultiLocationConfigureProps) => {
  const architecture = useArchitecture();
  const {
    selectedBlueprint,
    contentName,
    facebook,
    showFacebookField,
    hasCatalog,
    isContentSelectable,
    blueprintChannels,
    showContentSelector,
    trackingData
  } = useProgram();

  const overrides = useWatch({
    name: 'locationsOverrideById',
    defaultValue: {}
  });
  const scheduleType = useWatch({
    name: 'spendStep.scheduleType',
    defaultValue: ''
  });
  const pageGroupId = useWatch({
    name: 'dynamicUserInputs.pageGroupId',
    defaultValue: ''
  });
  const pageId = useWatch({
    name: 'dynamicUserInputs.pageId',
    defaultValue: ''
  });
  const initialScheduleType = useRef();
  const hasOverrides = !isEmpty(overrides);

  const [orderTypeConfirmationModalOpen, setOrderTypeConfirmationModalOpen] =
    useState(false);

  const text = useMemo(
    () =>
      pageText({
        contentName
      }),
    [contentName]
  );

  const closeOrderTypeConfirmationModal = () => {
    setOrderTypeConfirmationModalOpen(false);
  };

  useEffect(() => {
    if (!initialScheduleType.current && scheduleType) {
      initialScheduleType.current = scheduleType;
    }
  }, [scheduleType]);

  useEffect(() => {
    if (initialScheduleType.current !== scheduleType && hasOverrides) {
      setOrderTypeConfirmationModalOpen(true);
    }
  }, [scheduleType, hasOverrides]);

  useEffect(() => {
    Instrumentation.logEvent(Instrumentation.Events.ProgramStartStepClicked, {
      type: programTrackingTypes.multiLocation,
      isClone: false,
      ...trackingData
    });
  }, []);

  return (
    <Box>
      <ContainerCard>
        <ContainerCardHeader
          title={text.defaultValueTitle}
          titleTypographyProps={{ fontWeight: 500 }}
          subheader={text.defaultValueDescription}
          subheaderTypographyProps={{
            fontSize: '13px',
            sx: { mt: 0.5 }
          }}
        />
        <ContainerCardContent>
          <Grid
            sx={{
              marginTop: `0 !important`,
              marginLeft: `0 !important`,
              width: '100% !important'
            }}
            container
            gap={2}
            data-cy="program-form-configure-multi-location-step"
          >
            {showFacebookField && (
              <Grid item xs={12}>
                <FormSection
                  title={text.facebookSectionTitle}
                  description={text.facebookSectionDescription}
                  isCollapsed={
                    !!facebook?.getSelectedFacebookPage({
                      pageGroupId,
                      pageId
                    })
                  }
                  productId={selectedBlueprint.id}
                  channel={blueprintChannels}
                >
                  <Grid container>
                    <Grid item xs={12} md={6}>
                      <FacebookSettings
                        facebook={facebook}
                        isLeadFormFacebookBlueprint={
                          selectedBlueprint?.isLeadFormFacebookBlueprint
                        }
                      />
                    </Grid>
                  </Grid>
                </FormSection>
              </Grid>
            )}

            {showContentSelector && (
              <Grid item xs={12}>
                <FormSection
                  title={text.contentSectionTitle}
                  description={text.contentSectionDescription}
                >
                  <ContentSelector
                    architecture={architecture}
                    architectureHasCatalog={hasCatalog}
                    contentName={contentName}
                    selectedBlueprint={selectedBlueprint}
                    isContentSelectable={isContentSelectable}
                    selectedBusinessObjects={selectedBusinessObjects}
                    setSelectedBusinessObjects={setSelectedBusinessObjects}
                    skipStep={skipStep}
                    setSkipStep={setSkipStep}
                    isHookForm
                    formSectionName={PROGRAM_FORM_SECTION_CONFIGURE_NAME}
                  />
                </FormSection>
              </Grid>
            )}

            <DefaultValuesForm
              selectedBusinessObjects={selectedBusinessObjects}
              selectedLocationsMetadata={selectedLocationsMetadata}
              conditionalInputsVisibility={conditionalInputsVisibility}
            />
          </Grid>
        </ContainerCardContent>
      </ContainerCard>
      <MultiLocationFooter
        selectedBusinessObjects={selectedBusinessObjects}
        isChannelValidationLoading={isChannelValidationLoading}
        initialOrderTypeHasChanged={
          initialScheduleType.current !== scheduleType
        }
        showValidationErrors={showValidationErrors}
      />
      <OrderTypeConfirmationModal
        open={orderTypeConfirmationModalOpen}
        onClose={closeOrderTypeConfirmationModal}
      />
    </Box>
  );
};

export default MultiLocationConfigure;
