import { every, find, forEach, get, isArray, keys } from 'lodash';
import { ParameterType, ProductInputSection } from 'src/generated/gql/graphql';
import { INPUT_TYPES } from 'src/components/ReduxForm/DynamicForm/constants';
import { Input, ConditionalInputsVisibility } from 'src/common/conditionals';

export type InputField = Input & {
  isMultiInput: boolean;
  fieldName: string;
  disabled: boolean;
  defaultValue: string;
  friendlyName: string;
  isExpressionAllowed: boolean;
  isRequired: boolean;
  id: string;
};

type InputSection = ProductInputSection & { inputFields: InputField[] };
interface GetAiAdCopyWriterInputsArgs {
  dynamicUserInputs: Record<string, any>;
  selectedBlueprint: { inputSections: InputSection[] };
  conditionalInputsVisibility: ConditionalInputsVisibility;
}

const eligibleInputConditions = {
  isMultiInput: false,
  isHidden: false,
  'blueprintVariable.isArray': false,
  'blueprintVariable.type': ParameterType.String,
  'displayParameters.inputData.eligibleForAiGenerationChatSupport': true
} as const;

type EligibleInputConditionsKey = keyof typeof eligibleInputConditions;

export const getAiAdCopyWriterInputs = ({
  dynamicUserInputs,
  selectedBlueprint,
  conditionalInputsVisibility
}: GetAiAdCopyWriterInputsArgs) => {
  const adCopyWriterInputs: InputField[] = [];

  forEach(dynamicUserInputs, (_, key) => {
    const fieldName = key;

    forEach(selectedBlueprint?.inputSections, inputSection => {
      const eligibleField = find(inputSection?.inputFields, inputField => {
        const isMultiOrSingleLineString =
          inputField?.displayMethodId === INPUT_TYPES.SINGLE_LINE_STRING ||
          inputField?.displayMethodId === INPUT_TYPES.MULTI_LINE_STRING;

        const isEligibleConfig = every(
          keys(eligibleInputConditions) as EligibleInputConditionsKey[],
          key => {
            const eligibilityCondition = eligibleInputConditions[key];
            const inputConfigValue = get(inputField, key);

            if (isArray(eligibilityCondition)) {
              return eligibilityCondition.includes(inputConfigValue);
            }

            return inputConfigValue === eligibilityCondition;
          }
        );

        // if input has conditional and it's hidden that makes it ineligible
        if (
          conditionalInputsVisibility?.[inputField.fieldName] &&
          !conditionalInputsVisibility?.[inputField.fieldName]?.isVisible
        ) {
          return false;
        }

        return (
          isMultiOrSingleLineString &&
          isEligibleConfig &&
          fieldName === inputField.fieldName
        );
      });

      if (eligibleField) {
        adCopyWriterInputs.push(eligibleField);
      }
    });
  });

  return adCopyWriterInputs;
};
