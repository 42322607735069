export const demoUserData = {
  userLeads: {
    edges: [
      {
        node: {
          id: '1',
          facebookId: 'f1',
          architectureId: '100101',
          programId: '417446556602556989',
          orderId: '417446556619334206',
          submittedAt: '2024-10-25T16:59Z',
          fields: [
            {
              name: 'email',
              values: ['zoey.moran@evocalize.com']
            },
            {
              name: 'phone',
              values: ['18009356891']
            },
            {
              name: 'first_name',
              values: ['Zoey']
            },
            {
              name: 'last_name',
              values: ['Moran']
            }
          ]
        }
      },
      {
        node: {
          id: '2',
          facebookId: 'f2',
          architectureId: '100101',
          programId: '417446556602556989',
          orderId: '417446556619334206',
          submittedAt: '2024-11-16T17:32Z',
          fields: [
            {
              name: 'email',
              values: ['sammy.rodgers@evocalize.com']
            },
            {
              name: 'phone',
              values: ['18009356891']
            },
            {
              name: 'first_name',
              values: ['Sammy']
            },
            {
              name: 'last_name',
              values: ['Rodgers']
            }
          ]
        }
      },
      {
        node: {
          id: '3',
          facebookId: 'f3',
          architectureId: '100101',
          programId: '417446556602556989',
          orderId: '417446556619334206',
          submittedAt: '2024-11-20T17:35Z',
          fields: [
            {
              name: 'email',
              values: ['dominique.sims@evocalize.com']
            },
            {
              name: 'phone',
              values: ['18009356891']
            },
            {
              name: 'first_name',
              values: ['Dominique']
            },
            {
              name: 'last_name',
              values: ['Sims']
            }
          ]
        }
      },
      {
        node: {
          id: '4',
          facebookId: 'f4',
          architectureId: '100101',
          programId: '417446556602556990',
          orderId: '417446556619334207',
          submittedAt: '2024-12-01T12:45Z',
          fields: [
            { name: 'email', values: ['alex.johnson@evocalize.com'] },
            { name: 'phone', values: ['18005551234'] },
            { name: 'first_name', values: ['Alex'] },
            { name: 'last_name', values: ['Johnson'] }
          ]
        }
      },
      {
        node: {
          id: '5',
          facebookId: 'f5',
          architectureId: '100101',
          programId: '417446556602556991',
          orderId: '417446556619334208',
          submittedAt: '2024-12-02T08:30Z',
          fields: [
            { name: 'email', values: ['bella.thomas@evocalize.com'] },
            { name: 'phone', values: ['18005559876'] },
            { name: 'first_name', values: ['Bella'] },
            { name: 'last_name', values: ['Thomas'] }
          ]
        }
      },
      {
        node: {
          id: '6',
          facebookId: 'f6',
          architectureId: '100101',
          programId: '417446556602556992',
          orderId: '417446556619334209',
          submittedAt: '2024-12-03T14:10Z',
          fields: [
            { name: 'email', values: ['carter.williams@evocalize.com'] },
            { name: 'phone', values: ['18005556789'] },
            { name: 'first_name', values: ['Carter'] },
            { name: 'last_name', values: ['Williams'] }
          ]
        }
      },
      {
        node: {
          id: '7',
          facebookId: 'f7',
          architectureId: '100101',
          programId: '417446556602556993',
          orderId: '417446556619334210',
          submittedAt: '2024-12-04T19:25Z',
          fields: [
            { name: 'email', values: ['danielle.brown@evocalize.com'] },
            { name: 'phone', values: ['18005551239'] },
            { name: 'first_name', values: ['Danielle'] },
            { name: 'last_name', values: ['Brown'] }
          ]
        }
      },
      {
        node: {
          id: '8',
          facebookId: 'f8',
          architectureId: '100101',
          programId: '417446556602556994',
          orderId: '417446556619334211',
          submittedAt: '2024-12-05T16:50Z',
          fields: [
            { name: 'email', values: ['ethan.martinez@evocalize.com'] },
            { name: 'phone', values: ['18005559812'] },
            { name: 'first_name', values: ['Ethan'] },
            { name: 'last_name', values: ['Martinez'] }
          ]
        }
      },
      {
        node: {
          id: '9',
          facebookId: 'f9',
          architectureId: '100101',
          programId: '417446556602556995',
          orderId: '417446556619334212',
          submittedAt: '2024-12-06T13:15Z',
          fields: [
            { name: 'email', values: ['fiona.james@evocalize.com'] },
            { name: 'phone', values: ['18005559991'] },
            { name: 'first_name', values: ['Fiona'] },
            { name: 'last_name', values: ['James'] }
          ]
        }
      },
      {
        node: {
          id: '10',
          facebookId: 'f10',
          architectureId: '100101',
          programId: '417446556602556996',
          orderId: '417446556619334213',
          submittedAt: '2024-12-07T09:40Z',
          fields: [
            { name: 'email', values: ['george.davis@evocalize.com'] },
            { name: 'phone', values: ['18005552345'] },
            { name: 'first_name', values: ['George'] },
            { name: 'last_name', values: ['Davis'] }
          ]
        }
      },
      {
        node: {
          id: '11',
          facebookId: 'f11',
          architectureId: '100101',
          programId: '417446556602556997',
          orderId: '417446556619334214',
          submittedAt: '2024-12-08T18:20Z',
          fields: [
            { name: 'email', values: ['hannah.evans@evocalize.com'] },
            { name: 'phone', values: ['18005556780'] },
            { name: 'first_name', values: ['Hannah'] },
            { name: 'last_name', values: ['Evans'] }
          ]
        }
      }
    ]
  },
  dashboardInsights: [
    {
      kpi: {
        slug: 'impressions',
        friendlyName: 'Views'
      },
      summary: {
        sum: 10942
      },
      daily: []
    },
    {
      kpi: {
        slug: 'clicks',
        friendlyName: 'Clicks'
      },
      summary: {
        sum: 945
      },
      daily: []
    },
    {
      kpi: {
        slug: 'link_click',
        friendlyName: 'Clicks'
      },
      summary: {
        sum: 945
      },
      daily: []
    },
    {
      kpi: {
        slug: 'leads',
        friendlyName: 'Leads'
      },
      summary: {
        sum: 112
      },
      daily: []
    },
    {
      kpi: {
        slug: 'views',
        friendlyName: 'Views'
      },
      summary: {
        sum: 48673
      },
      daily: []
    },
    {
      kpi: {
        slug: 'cpc',
        friendlyName: 'Cost Per Click'
      },
      summary: {
        sum: 2.85
      },
      daily: []
    },
    {
      kpi: {
        slug: 'cpl',
        friendlyName: 'Cost Per Lead'
      },
      summary: {
        sum: 28
      },
      daily: []
    },
    {
      kpi: {
        slug: 'ctr',
        friendlyName: 'CTR'
      },
      summary: {
        sum: 2.8
      },
      daily: []
    },
    {
      kpi: {
        slug: 'registrations',
        friendlyName: 'Registrations'
      },
      summary: {
        sum: 29
      },
      daily: []
    },
    {
      kpi: {
        slug: 'cpr',
        friendlyName: 'Cost Per Registration'
      },
      summary: {
        sum: 9.3
      },
      daily: []
    },
    {
      kpi: {
        slug: 'conversions',
        friendlyName: 'Conversions'
      },
      summary: {
        sum: 9
      },
      daily: []
    },
    {
      kpi: {
        slug: 'all_conversions',
        friendlyName: 'All Conversions'
      },
      summary: {
        sum: 14
      },
      daily: []
    }
  ],
  programInsights: [
    {
      kpi: {
        slug: 'impressions',
        friendlyName: 'Views'
      },
      summary: {
        sum: 7942
      },
      daily: []
    },
    {
      kpi: {
        slug: 'link_click',
        friendlyName: 'Clicks'
      },
      summary: {
        sum: 39
      },
      daily: []
    },
    {
      kpi: {
        slug: 'spend',
        friendlyName: 'Cost'
      },
      summary: {
        sum: 119.4
      },
      daily: []
    },
    {
      kpi: {
        slug: 'ad_network_type',
        friendlyName: 'Ad Network Type'
      },
      summary: {
        sum: 'CONTENT'
      },
      daily: []
    },
    {
      kpi: {
        slug: 'device',
        friendlyName: 'Device'
      },
      summary: {
        sum: ['MOBILE', 'DESKTOP']
      },
      daily: []
    },
    {
      kpi: {
        slug: 'conversions',
        friendlyName: 'Conversions'
      },
      summary: {
        sum: 9
      },
      daily: []
    },
    {
      kpi: {
        slug: 'all_conversions',
        friendlyName: 'All Conversions'
      },
      summary: {
        sum: 14
      },
      daily: []
    },
    {
      kpi: {
        slug: 'clicks',
        friendlyName: 'Clicks'
      },
      summary: {
        sum: 223
      },
      daily: []
    },
    {
      kpi: {
        slug: 'cpc',
        friendlyName: 'Cost Per Click'
      },
      summary: {
        sum: 1.21
      },
      daily: []
    },
    {
      kpi: {
        slug: 'ctr',
        friendlyName: 'CTR'
      },
      summary: {
        sum: 2.8
      },
      daily: []
    },
    {
      kpi: {
        slug: 'cpl',
        friendlyName: 'Cost Per Lead'
      },
      summary: {
        sum: 24.53
      },
      daily: []
    },
    {
      kpi: {
        slug: 'leads',
        friendlyName: 'Leads'
      },
      summary: {
        sum: 11
      },
      daily: []
    },
    {
      kpi: {
        slug: 'registrations',
        friendlyName: 'Registrations'
      },
      summary: {
        sum: 29
      },
      daily: []
    },
    {
      kpi: {
        slug: 'cpr',
        friendlyName: 'Cost Per Registration'
      },
      summary: {
        sum: 9.3
      },
      daily: []
    }
  ]
};
