/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useState, useMemo, Fragment } from 'react';
import {
  some,
  isEmpty,
  toString,
  mapValues,
  map,
  forEach,
  chain,
  find,
  trim,
  values,
  uniqBy
} from 'lodash';
import { LoadingButton } from '@mui/lab';
import { Button, Typography, TextField, Tabs, Tab } from '@mui/material';
import { parsePhoneNumber, isValidNumber } from 'libphonenumber-js';
import Crypto from 'crypto-js';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Modal from 'src/components/Modal';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { FileError, FileRejection, useDropzone } from 'react-dropzone';
import Papa from 'papaparse';
import validator from 'validator';
import { useSnackbar } from 'notistack';

import Loading from 'src/components/Loading';
import { Box, styled } from '@mui/system';
import { useMutation } from '@apollo/client';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import SentryUtil from 'src/common/SentryUtil';
import { FlexExpander } from 'src/components/Styling/FlexExpander';
import { HelpTip } from 'src/components/Icons';
import { GoogleAudienceRequirements } from 'src/pages/Audiences/GoogleAudienceRequirements';
import { FacebookAudienceRequirements } from 'src/pages/Audiences/FacebookAudienceRequirements';
import { AudienceDownloadCsvLink } from 'src/pages/Audiences/AudienceDownloadCsvLink';
import AddIcon from '@mui/icons-material/Add';
import { uploadAudience } from './mutations';

const CSV_EXT_REGEX = /.+(\.csv)$/;
const MIN_AUDIENCE_SIZE = 300;
const CSV_LINE_OFFSET = 2;
const PRIORITY_ERROR_INDEX = -1;
const DEFAULT_COUNTRY_CODE = 'US';
const PHONE_NUMBER_FORMAT = 'E.164';

const ModalFooter = styled('div')(({ theme }) => ({
  display: 'flex',
  marginTop: theme.spacing(2),
  alignItems: 'center'
}));

const pageText = () => ({
  nameInput: t('audiences:uploadModal.nameInput'),
  nameInputLabel: t('audiences:uploadModal.nameInputLabel'),
  retry: t('audiences:uploadModal.retry'),
  back: t('audiences:uploadModal.back'),
  continue: t('audiences:uploadModal.continue'),
  error: t('audiences:uploadModal.error'),
  errorNumberOfColumns: t('audiences:uploadModal.errorNumberOfColumns'),
  errorColumnNames: t('audiences:uploadModal.errorColumnNames'),
  errorNoData: t('audiences:uploadModal.errorNoData'),
  errorLength: t('audiences:uploadModal.errorLength', {
    data: MIN_AUDIENCE_SIZE
  }),
  errorEmailColumn: t('audiences:uploadModal.missingEmailColumn'),
  errorEmailFirst: t('audiences:uploadModal.firstColumnEmailError'),
  errorNotCsv: t('audiences:uploadModal.errorNotCsv'),
  uploadErrorGeneric: t('audiences:uploadModal.uploadErrorGeneric'),
  processingErrorGeneric: t('audiences:uploadModal.processingErrorGeneric'),
  dragAndDropOrClick: t('audiences:uploadModal.dragAndDropOrClick'),
  dropzoneText: t('audiences:uploadModal.dragAndDrop'),
  uploadSuccess: t('audiences:uploadModal.uploadSuccess'),
  processingNotice: t('audiences:uploadModal.24hourNotice'),
  submitButton: t('audiences:button.submit'),
  requirementsTab: t('audiences:uploadModal.requirements'),
  requirementsTooltip: t('audiences:uploadModal.requirementsTooltip')
});

const isEmailString = (str = '') => {
  return str && str.toLowerCase() === 'email';
};

// Validate that at least one of the fields has an email column - case
// insensitive.
const hasEmailColumn = (fields: string[] = []) => {
  for (let i = 0; i < fields.length; i++) {
    if (isEmailString(fields[i])) {
      return true;
    }
  }

  return false;
};

const parserBaseConfig = {
  delimiter: ',',
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true
} as const;

const SNACKBAR_VARIANTS = {
  error: 'error',
  success: 'success'
} as const;

export interface AudienceUploadProps {
  variant?: 'text' | 'outlined' | 'contained';
  onAudienceUploaded?: () => void;
}

interface ValidationError {
  index: number;
  message: string;
  isCritical?: boolean;
}

const AudienceUpload = ({
  variant = 'contained',
  onAudienceUploaded
}: AudienceUploadProps) => {
  const [uploadAudienceMutation] = useMutation(uploadAudience);
  const [modalOpen, setModalOpen] = useState(false);

  // selecting and validating CSV state
  const [fileErrors, setFileErrors] = useState<ValidationError[]>([]);
  const [audienceName, setAudienceName] = useState('');
  const [hashedAudienceFile, setHashedAudienceFile] = useState<File | null>(
    null
  );
  const [emailMeta, setEmailMeta] = useState({ valid: 0, invalid: 0 });

  // uploading CSV state
  const [isUploading, setIsUploading] = useState(false);

  const [requirementsOpen, setRequirementsOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState<0 | 1>(0);

  const { enqueueSnackbar } = useSnackbar();

  const text = useMemo(() => pageText(), []);

  const handleModalClose = () => {
    // reset everything
    setModalOpen(false);
    setAudienceName('');
    setFileErrors([]);
    setEmailMeta({ valid: 0, invalid: 0 });
    setIsUploading(false);
    setHashedAudienceFile(null);
    setRequirementsOpen(false);
    setSelectedTab(0);
  };

  const setEmailCount = (valid: number, invalid: number) => {
    setEmailMeta({
      valid,
      invalid
    });
  };

  const clearUploadState = () => {
    setEmailCount(0, 0);
    setHashedAudienceFile(null);
    setFileErrors([]);
  };

  const resetForm = (errors?: ValidationError[]) => {
    setAudienceName('');
    setEmailCount(0, 0);
    setHashedAudienceFile(null);
    if (errors) {
      setFileErrors(errors);
    }
  };

  const getIdStrings = ({
    Email,
    email,
    Phone,
    phone,
    Firstname,
    firstname,
    firstName,
    Lastname,
    lastname,
    lastName
  }: Record<string, string>) => {
    // Flexible column name support
    const emailString = Email || email;
    const phoneString = Phone || phone;
    const firstNameString = Firstname || firstname || firstName;
    const lastNameString = Lastname || lastname || lastName;

    return { emailString, phoneString, firstNameString, lastNameString };
  };

  const handleFileUpload = async () => {
    setIsUploading(true);

    try {
      await uploadAudienceMutation({
        variables: {
          input: {
            name: audienceName,
            description: hashedAudienceFile!.name
          },
          file: hashedAudienceFile
        }
      });
      enqueueSnackbar(text.uploadSuccess, {
        variant: SNACKBAR_VARIANTS.success
      });
      handleModalClose();
      onAudienceUploaded?.();
    } catch (e) {
      enqueueSnackbar(text.uploadErrorGeneric, {
        variant: SNACKBAR_VARIANTS.error
      });
      handleModalClose();
    }
  };

  const processAudienceFile = (file: File) => {
    const contentType = 'text/csv';
    let allErrors: ValidationError[] = [];
    let valid = 0;
    let invalid = 0;

    const onComplete = async (
      result: Papa.ParseResult<Record<string, string>>
    ) => {
      const { data, meta, errors } = result;

      if (some(errors)) {
        allErrors = [
          ...allErrors,
          ...errors.map(e => ({
            index: e.row,
            message: e.message.replace('parsed', 'received'),
            isCritical:
              e.message.includes('Too few fields') ||
              e.message.includes('Too many fields')
          }))
        ];
      }

      if (!some(data)) {
        allErrors = [
          ...allErrors,
          {
            index: PRIORITY_ERROR_INDEX,
            message: text.errorNoData,
            isCritical: true
          }
        ];
      }

      // Hash each row or specific fields
      const hashedData = await Promise.all(
        map(data, (row, index) => {
          const { emailString } = mapValues(getIdStrings(row), toString);

          const hasMisalignedFields = find(allErrors, error => {
            return (
              (error.message.includes('Too few fields') ||
                error.message.includes('Too many fields')) &&
              error.index === index
            );
          });

          const hasEmptyField = values(row).some(val => {
            return isEmpty(trim(val));
          });

          if (hasEmptyField) {
            allErrors = [
              ...allErrors,
              {
                index,
                message: t('audiences:uploadModal.emptyFieldsError'),
                isCritical: true
              }
            ];
          }

          if (!hasEmptyField && !hasMisalignedFields) {
            if (emailString && validator.isEmail(emailString)) {
              valid++;
            } else {
              allErrors = [
                ...allErrors,
                {
                  index,
                  message: t('audiences:uploadModal.invalidEmailError', {
                    emailString
                  })
                }
              ];
              invalid++;
            }
          }

          const hashedRow = { ...row };

          forEach(row, (_value, key) => {
            if (row[key]) {
              let value = row[key];

              if (key.toLowerCase().includes('phone')) {
                // Remove all non-numeric characters to convert stuff like (425) - 555 - 5555 to 4255555555
                // We are assuming US country code here
                const normalizedPhone = value?.toString()?.replace(/\D/g, '');
                const isValidPhoneNumber = isValidNumber(
                  normalizedPhone,
                  DEFAULT_COUNTRY_CODE
                );

                if (isValidPhoneNumber) {
                  value = parsePhoneNumber(
                    normalizedPhone,
                    DEFAULT_COUNTRY_CODE
                  )
                    .format(PHONE_NUMBER_FORMAT)
                    .slice(1); // Google/Facebook doesn't allow "+" in E.164 phone numbers
                }
              }
              hashedRow[key] = Crypto.SHA256(trim(value)).toString();
            }
          });

          return hashedRow;
        })
      );

      // Convert back to CSV
      const csvString = Papa.unparse(hashedData);

      // Create a File object for the hashed CSV
      const blob = new Blob([csvString], { type: contentType });
      const processedFile = new File([blob], file.name, {
        type: contentType
      });

      setHashedAudienceFile(processedFile);
      setEmailCount(valid, invalid);

      if (valid < MIN_AUDIENCE_SIZE) {
        allErrors = [
          ...allErrors,
          {
            index: PRIORITY_ERROR_INDEX,
            message: text.errorLength,
            isCritical: true
          }
        ];
      }

      if (!hasEmailColumn(meta.fields)) {
        allErrors = [
          ...allErrors,
          {
            index: PRIORITY_ERROR_INDEX,
            message: text.errorEmailColumn,
            isCritical: true
          }
        ];
      }

      const firstColumn = meta?.fields?.[0] || '';
      if (!isEmailString(firstColumn)) {
        allErrors = [
          ...allErrors,
          {
            index: PRIORITY_ERROR_INDEX,
            message: text.errorEmailFirst,
            isCritical: true
          }
        ];
      }

      if (isEmpty(allErrors) || !some(allErrors, error => error.isCritical)) {
        // populate the name field with the file name as default
        if (!audienceName || audienceName === '') {
          setAudienceName(file.name.split('.')[0]);
        }
        // no errors so the file is ready to upload
        setEmailCount(valid, invalid);
        setHashedAudienceFile(processedFile);
        setFileErrors(allErrors);
      } else {
        resetForm(allErrors);
      }
    };

    if (file) {
      Papa.parse(file, {
        ...parserBaseConfig,
        complete: (args: Papa.ParseResult<Record<string, string>>) => {
          onComplete(args).catch(e => {
            SentryUtil.captureException(e);
            enqueueSnackbar(text.processingErrorGeneric, {
              variant: SNACKBAR_VARIANTS.error
            });
          });
        }
      });
    }
  };

  const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    // Before we do anything, clear all our old state
    clearUploadState();

    let allErrors: ValidationError[] = [];

    if (some(fileRejections)) {
      const errors = fileRejections.flatMap(r =>
        r.errors.map(e => ({ index: PRIORITY_ERROR_INDEX, message: e.message }))
      );
      allErrors = [
        ...allErrors,
        ...uniqBy(errors, error => `${error.index}-${error.message}`)
      ];
      setFileErrors(allErrors);
    } else if (some(acceptedFiles)) {
      const file = acceptedFiles[0];
      processAudienceFile(file);
    }
  };

  const csvValidation = (file: File) => {
    const errors: FileError[] = [];

    if (!CSV_EXT_REGEX.test(file.name)) {
      errors.push({
        message: text.errorNotCsv,
        code: 'UNSUPPORTED_FILE_TYPE'
      });
    }

    return some(errors) ? errors : null;
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: false,
    onDrop,
    validator: csvValidation
  });

  const audienceSelected = !!hashedAudienceFile;
  const hasCriticalErrors = some(fileErrors, error => error.isCritical);
  const uploadDisabled = !audienceSelected || hasCriticalErrors;

  const uploadContainerStyles = {
    alignItems: 'center',
    border: '2px dashed',
    display: 'flex',
    flexDirection: 'column',
    justifyContent:
      fileErrors.length === 0 || isDragActive ? 'center' : 'flex-start',
    padding: 1,
    minHeight: '100px',
    maxHeight: '300px',
    overflow: 'hidden',
    overflowY: 'auto'
  };

  return (
    <>
      <Button
        color="primary"
        onClick={() => setModalOpen(true)}
        variant={variant}
        data-amp-click-add-audience
        data-cy="add-audience-button"
        sx={theme => ({
          marginBottom: theme.spacing(2)
        })}
        endIcon={<AddIcon />}
      >
        <Trans i18nKey="audiences:header.addButton" />
      </Button>

      <Modal
        fullWidth
        headerText={t('audiences:uploadModal.title')}
        maxWidth="md"
        onClose={() => handleModalClose()}
        open={modalOpen}
        data-cy="audience-upload-modal"
      >
        {isUploading ? (
          <Box sx={uploadContainerStyles}>
            <Typography variant="h6">
              <Trans i18nKey="audiences:uploadModal.uploadingTitle" />
            </Typography>
            <br />
            <Loading />
          </Box>
        ) : (
          <>
            <Typography
              variant="body1"
              sx={theme => ({ marginBottom: theme.spacing(1) })}
            >
              {text.nameInputLabel}
            </Typography>
            <TextField
              id="audienceName"
              label={text.nameInput}
              variant="outlined"
              fullWidth
              onChange={e => setAudienceName(e.target.value)}
              value={audienceName}
              data-cy="audience-name-text-field"
            />

            <Typography
              variant="body1"
              sx={theme => ({
                marginBottom: theme.spacing(1),
                marginTop: theme.spacing(2)
              })}
            >
              <Trans
                i18nKey="audiences:uploadModal.uploadLabel"
                components={[<AudienceDownloadCsvLink />]}
              />
            </Typography>
            <Box sx={uploadContainerStyles} {...getRootProps()}>
              {isDragActive && (
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'center',
                    position: 'absolute',
                    width: '100%',
                    background: '#fff',
                    zIndex: 1
                  }}
                >
                  <CloudUploadIcon
                    sx={{
                      height: '25%',
                      width: '25%'
                    }}
                  />
                  <Typography variant="h5">{text.dropzoneText}</Typography>
                </Box>
              )}
              <input
                data-cy="audiences-csv-drag-and-drop"
                {...getInputProps()}
              />
              <Typography variant="subtitle2">
                <Trans
                  i18nKey="audiences:uploadModal.dropzone"
                  components={[
                    // Note that this button has no click handler on purpose.
                    // The click event bubbles up to the root div that wraps the whole input section together
                    <Button
                      sx={{ textTransform: 'none', padding: 0 }}
                      data-cy="select-file-button"
                      color="primary"
                      variant="text"
                    />
                  ]}
                />
              </Typography>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  maxWidth: '400px'
                }}
              >
                {emailMeta.valid > 0 && (
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex'
                    }}
                  >
                    <Typography
                      data-cy="valid-email-count"
                      color={
                        audienceSelected && emailMeta?.valid < MIN_AUDIENCE_SIZE
                          ? 'error'
                          : 'textSecondary'
                      }
                    >
                      <Trans
                        i18nKey="audiences:uploadModal.emailValidCount"
                        values={emailMeta}
                      />
                    </Typography>
                    <Box
                      component="span"
                      sx={{
                        padding: theme => `0 ${theme.spacing(1)}`
                      }}
                    >
                      |
                    </Box>
                    <Typography
                      data-cy="invalid-email-count"
                      color="textSecondary"
                    >
                      <Trans
                        i18nKey="audiences:uploadModal.emailInvalidCount"
                        values={emailMeta}
                      />
                    </Typography>
                  </Box>
                )}
                {fileErrors.length > 0 && (
                  <>
                    <Typography
                      data-cy="audience-upload-error-message"
                      color="error"
                    >
                      <b>{`${fileErrors.length} Issue(s) Found`}</b>
                      <br />
                      {chain(fileErrors)
                        .sortBy(['index', 'message'])
                        .map(error => {
                          return (
                            <Fragment key={error.message + error.index}>
                              {error.index > PRIORITY_ERROR_INDEX
                                ? `line ${error.index + CSV_LINE_OFFSET}: ${error.message}`
                                : `${error.message}`}
                              <br />
                            </Fragment>
                          );
                        })
                        .value()}
                    </Typography>
                  </>
                )}
              </Box>
            </Box>
            <ModalFooter>
              {!requirementsOpen && <ExpandMore />}
              {requirementsOpen && <ExpandLess />}
              <Button
                variant="text"
                onClick={() => setRequirementsOpen(open => !open)}
              >
                {text.requirementsTab}
              </Button>
              <HelpTip tipText={text.requirementsTooltip} />
              <FlexExpander />
              <LoadingButton
                color="primary"
                loading={isUploading}
                disabled={uploadDisabled}
                onClick={e => {
                  e.stopPropagation();
                  handleFileUpload().catch(e => {
                    SentryUtil.captureException(e);
                    enqueueSnackbar(text.uploadErrorGeneric, {
                      variant: SNACKBAR_VARIANTS.error
                    });
                  });
                }}
                variant="contained"
                data-cy="create-audience-button"
              >
                {text.submitButton}
              </LoadingButton>
            </ModalFooter>
            {requirementsOpen && (
              <Box>
                <Tabs
                  value={selectedTab}
                  onChange={(e, value) => setSelectedTab(value)}
                  sx={theme => ({
                    marginBottom: theme.spacing(2)
                  })}
                >
                  <Tab
                    label="google"
                    id="google-requirements"
                    aria-controls="requirements-tab-panel-google"
                  />
                  <Tab
                    label="facebook"
                    id="facebook-requirements"
                    aria-controls="requirements-tab-panel-facebook"
                  />
                </Tabs>
                <div
                  hidden={selectedTab !== 0}
                  role="tabpanel"
                  id="requirements-tab-panel-google"
                  aria-labelledby="google-requirements"
                >
                  <GoogleAudienceRequirements />
                </div>
                <div
                  role="tabpanel"
                  hidden={selectedTab !== 1}
                  id="requirements-tab-panel-facebook"
                  aria-labelledby="facebook-requirements"
                >
                  <FacebookAudienceRequirements />
                </div>
              </Box>
            )}
          </>
        )}
      </Modal>
    </>
  );
};

export default AudienceUpload;
