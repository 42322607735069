// we should keep all user settings keys here so they are in one place

export const FACEBOOK_MODAL_SETTING = 'facebookModalDismissed';
export const FACEBOOK_DEFAULT_PAGE_ID = 'defaultFacebookPageId';
export const AUTOMATED_PROGRAMS_WIZARD_MODAL_DISMISS = 'apWizardModalDismissed';
export const AUTOMATION_ADOPTION_MODAL = 'automationAdoptionModalDismissed'; // stored in milliseconds
export const AUTOMATION_ADOPTION_MODAL_REMIND =
  'automationAdoptionModalRemindLater'; // stored in milliseconds
export const CLONE_PROGRAM_MODAL_DISMISS = 'cloneProgramModalDismissed';

// Ai Chat Window
export const AI_CHAT_WINDOW_OPEN = 'aiChatWindowOpen';

// Program Drafts
export const DISMISS_PROGRAM_DRAFTS_MODAL = 'dismissProgramDraftsModal';
export const DISMISS_AUTOMATED_PROGRAM_DRAFTS_MODAL =
  'dismissAutomatedProgramDraftsModal';
export const PROGRAM_DRAFTS_TABLE_GROUP_STATE = 'programDraftsTableGroupState'; // true: expand or false: collapse
export const WORKATO_CONNECT_FIRST_TIME_MODAL_DISMISS =
  'workatoConnectFirstTimeModalDismiss';
