import { gql } from 'src/generated/gql';

/**
 * GraphQL query for getting org-level content for the org-content selector.
 * This has to drill down a few layers since we only know the catalog slug
 * when rendering this selector. Along with the fact that many of the other
 * layers like architectures does not provide a way to get at the catalog
 * unless we drill down this way.
 */
export const getOrgContent = gql(`
  query getOrgContent(
    $after: String
    $orderBy: String
    $filter: JSON
    $catalogSlug: String
  ) {
    myOrganization {
      architectures(showInactive: false, types: organization) {
        catalog(slug: $catalogSlug) {
          id
          friendlyName
          slug
          content(after: $after, orderBy: $orderBy, filter: $filter) {
            pageInfo {
              hasNextPage
              endCursor
            }
            edges {
              node {
                id
                items {
                  id
                  fields
                }
              }
            }
          }
        }
      }
    }
  }
`);

// hard coding the integrationType to total_expert for now until we have more integrations
export const fetchEmbeddedLink = gql(`
  query fetchEmbeddedLink {
    fetchEmbeddedLink(integrationType: total_expert) {
      link
      errorMessage
    }
  }
`);

export const fetchContactGroups = gql(`
  query fetchContactGroups {
    fetchContactGroups {
      groupName
      groupId
    }
  }
`);
