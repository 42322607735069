import { FieldArray } from 'redux-form';
import 'draft-js/dist/Draft.css';

import { Grid } from '@mui/material';

import NavigationBlocker from 'src/components/NavigationBlocker';
import HookFormFieldArray from 'src/components/ReduxForm/DynamicForm/HookFormFieldArray';

import {
  INPUT_TYPES,
  EDIT_MODES,
  DEFAULT_MD_BY_DISPLAY_METHOD_ID
} from './constants';
import FormInput from './FormInput';
import MultiInput from './MultiInput';
import { getFieldName, sortAndFilterFormInputs } from './helpers';

const DynamicForm = props => {
  const {
    blueprint,
    contentColumns,
    inputs,
    dirty,
    disabled,
    editType,
    businessObjects,
    formName,
    isContentSelectable,
    contentName,
    isHookForm = false,
    channelInputValidators,
    formSectionName,
    selectedLocationsMetadata = [],
    isMultiLocation,
    aiAdCopyWriterInputs,
    aiChat,
    highlightedInputs,
    conditionalInputsVisibility
  } = props;
  const usableInputs = sortAndFilterFormInputs(inputs);

  const visibleInputs = usableInputs.filter(inputMetadata => {
    // * Note: We only support conditionalRendering when conditionalInputsVisibility is passed
    // *       for more info check out conditionals.ts:getConditionalInputVisibilityFromBlueprint
    if (
      conditionalInputsVisibility?.[inputMetadata.fieldName] &&
      !conditionalInputsVisibility?.[inputMetadata.fieldName]?.isVisible
    ) {
      // if input has conditional and it's hidden don't render it
      return false;
    }
    return true;
  });

  return (
    <Grid container rowSpacing={3} columnSpacing={2}>
      <NavigationBlocker block={!!dirty} />
      {visibleInputs.map(inputMetadata => {
        let md = Number(
          inputMetadata?.displayParameters?.inputData?.columnWidth
        );

        if (!md) {
          md =
            DEFAULT_MD_BY_DISPLAY_METHOD_ID[inputMetadata?.displayMethodId] ||
            2;
        }

        let disableInput = inputMetadata?.disabled ?? false;
        // NOTE: If disabled is passed we want the all inputs disabled so this
        //       will override the individual input disabled.
        if (disabled) {
          disableInput = disabled;
        }

        const fieldName = getFieldName(inputMetadata);

        // Since layouts are in quarters (1 - 4), we multiply by 3
        // to get it into a 12-column grid.
        md *= 3;
        const key = `i-${fieldName}`;
        // hide hidden fields
        const style = inputMetadata?.isHidden ? { display: 'none' } : {};

        // these same props get sent to 2 components
        const sharedProps = {
          blueprint,
          metadata: inputMetadata,
          contentColumns,
          disabled: disableInput,
          editType,
          businessObjects,
          formName,
          isContentSelectable,
          contentName,
          channelInputValidators,
          isHookForm,
          formSectionName,
          selectedLocationsMetadata,
          isMultiLocation,
          aiAdCopyWriterInputs,
          aiChat,
          isHighlighted: highlightedInputs?.includes(
            inputMetadata?.blueprintVariableId
          )
        };

        return (
          <Grid
            key={key}
            item
            xs={12}
            sm={12}
            md={md}
            style={style}
            data-cy={`${fieldName}-root-ele`}
            sx={
              inputMetadata?.displayMethodId === INPUT_TYPES.ANY_BOOLEAN
                ? { mb: -1, mt: -2 }
                : {}
            }
          >
            {/* check if it's an array of inputs then defer to multiInput */}
            {(inputMetadata?.isMultiInput || inputMetadata?.childInputs) &&
              !isHookForm && (
                <FieldArray
                  name={fieldName}
                  component={MultiInput}
                  DynamicForm={DynamicForm}
                  {...sharedProps}
                />
              )}
            {/* TODO: Add react hook form FieldArray component */}
            {(inputMetadata?.isMultiInput || inputMetadata?.childInputs) &&
              isHookForm && (
                <HookFormFieldArray
                  name={fieldName}
                  component={MultiInput}
                  DynamicForm={DynamicForm}
                  {...sharedProps}
                />
              )}
            {!inputMetadata?.isMultiInput && !inputMetadata?.childInputs && (
              <FormInput {...sharedProps} />
            )}
          </Grid>
        );
      })}
    </Grid>
  );
};

DynamicForm.EDIT_MODES = EDIT_MODES;

export default DynamicForm;
