import {
  FloatingNotificationInbox,
  NotificationPreferences
} from '@magicbell/magicbell-react';
import { isNil } from 'lodash';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { IconButton } from '@mui/material';
import SentryUtil from 'src/common/SentryUtil';
import { useHistory } from 'react-router';
import { t } from 'i18next';
import { useSnackbar } from 'notistack';
import { logNotificationClickEventLegacy } from 'src/components/Notification/notificationEventGenerator';
import Instrumentation from 'src/instrumentation';
import { Events } from 'src/instrumentation/constants';
import { useGlobalContext } from 'src/GlobalContextProvider';
import { forwardRef, useRef, useState } from 'react';
import { INotification, useBell } from '@magicbell/react-headless';
import { useFeatures } from 'src/components/Feature/Feature';

const getText = () => ({
  navigationError: t('notifications:navigationError')
});

interface BellIconReplacementProps {
  onClick: () => void;
}

/**
 * This is a replacement for the magic bell icon.
 * This uses a MUI icon and MUI button to match
 * our other icon buttons in the app.
 */
const BellIconReplacement = forwardRef<
  HTMLButtonElement,
  BellIconReplacementProps
>(({ onClick }, ref) => {
  return (
    <IconButton
      size="large"
      color="inherit"
      sx={{
        height: '48px !important'
      }}
      ref={ref}
      onClick={onClick}
      data-cy="notification-bell-icon"
    >
      <NotificationsIcon />
    </IconButton>
  );
});

/**
 * Renders a notification icon button.
 * This opens up to display all the actual notifications.
 */
export const NotificationIconButtonLegacy = () => {
  const { enqueueSnackbar } = useSnackbar();
  const text = getText();
  const features = useFeatures();
  const isPreferencesEnabled = features?.showNotificationPanel;
  const history = useHistory();
  const globalContext = useGlobalContext();
  const userId = globalContext?.me?.id;
  const [isPanelOpen, setIsPanelOpen] = useState(false);

  const handleOpenToggle = () => {
    const eventKey = isPanelOpen
      ? Events.NotificationInboxClose
      : Events.NotificationInboxOpen;

    Instrumentation.logEvent(eventKey, {
      path: history.location.pathname
    });
    setIsPanelOpen(!isPanelOpen);
  };

  const handleNotificationClick = (notification: INotification) => {
    // Regardless of how we handle the notification, log that we did it
    logNotificationClickEventLegacy(notification, history);

    // The return value of this function determines if we should allow magicbell
    // to handle the click.
    const skipMagicBellHandling = false;
    const letMagicBellProcessClick = !skipMagicBellHandling;

    if (notification.actionUrl == null) {
      // If there's no actionURL there's also no click action...
      // but we'll still bounce back to magicbell to handle the click.
      return letMagicBellProcessClick;
    }

    // ActionURLs come from the backend in the form of:
    // https://app.evocalize.com/#/architecture/1/program/2
    // (depending on the notification type)
    // This does not work at all in localdev due to a port number on our
    // localdev URL but not in the actionURL.
    // This also breaks due to multi-domain orgs.
    // So instead, we'll try to pull out the relevant part of the URL (the
    // path) and use that to navigate.
    try {
      const url = new URL(notification.actionUrl);
      // Keeping the hash in the URL breaks the routing and changes
      // the path to #/dashboard/#/architecture/1/program/2
      const path = url.hash.replace('#', '');
      history.push(path);
    } catch (e) {
      SentryUtil.addBreadcrumb({
        level: 'error',
        category: 'notification',
        message: 'Failed to handle notification click',
        data: {
          url: notification.actionUrl,
          notificationId: notification.id
        }
      });
      if (e instanceof Error) {
        SentryUtil.captureException(e);
      } else {
        const errorMessage = e?.toString() ?? 'Unknown error';
        SentryUtil.captureException(
          new Error(`Failed to handle notification click: ${errorMessage}`)
        );
      }
      enqueueSnackbar(text.navigationError, {
        variant: 'error'
      });
    }

    return skipMagicBellHandling;
  };

  const bellIconRef = useRef(null);

  // Use this as our marker to decide if we finished loading everything magibell-y
  const bell = useBell();
  if (
    isNil(bell) ||
    isNil(import.meta.env.EVOCALIZE_MAGICBELL_API_KEY) ||
    isNil(userId) ||
    // Wait to render until we have fetched at least once.
    // That way we don't double-fetch notifications.
    bell?.lastFetchedAt == null
  ) {
    return null;
  }

  return (
    <div data-cy="notifications-root">
      <BellIconReplacement ref={bellIconRef} onClick={handleOpenToggle} />
      <FloatingNotificationInbox
        height={500}
        isOpen={isPanelOpen}
        launcherRef={bellIconRef}
        toggle={handleOpenToggle}
        NotificationPreferences={
          // Strangely, if preferences are enabled we want to turn OFF these
          // preferences. The new preferences are in the account settings section
          isPreferencesEnabled
            ? undefined
            : () => {
                return <NotificationPreferences channels={['in_app']} />;
              }
        }
        notificationPreferencesEnabled={!isPreferencesEnabled}
        onNotificationClick={handleNotificationClick}
      />
    </div>
  );
};
