import { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isEmpty, pick } from 'lodash';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { useFormContext, useWatch } from 'react-hook-form';

import {
  Divider,
  Grid,
  Typography,
  Button,
  Alert,
  AlertTitle
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { contentColumnsFromArchitecture } from 'src/common/dynamicUserInputs';
import Instrumentation from 'src/instrumentation';
import { isProgramClone } from 'src/common/cloneProgram';

import { paths } from 'src/routes/paths';

import AutomatedProgramName from 'src/pages/Program/ProgramName/AutomatedProgramName';
import Loading from 'src/components/Loading';
import { adCreativeTypes } from 'src/common/adChannels';
import {
  getCreativeType,
  getOneOfEachChannelKeys,
  checkBlueprintHasFacebook
} from 'src/common/blueprints';

import { FormSection } from 'src/components/ReduxForm';

import FilterSelector from './FilterSelector';
import ContentSelector from './ContentSelector';
import FacebookSettings from './FacebookSettings';
import {
  PROGRAM_FORM_NAME,
  PROGRAM_FORM_SECTION_CONFIGURE_NAME,
  programActions,
  programTrackingTypes
} from '../../Constants';
import ProgramDynamicUserInputs from '../../ProgramDynamicUserInputs';
import ProgramStepFooter from '../../ProgramStepFooter';
import { setContinueCreateProgram } from '../../actions';

const useStyles = makeStyles(theme => ({
  nothingToDo: {
    margin: theme.spacing(2)
  },
  title: {
    display: 'flex',
    alignItems: 'center'
  },
  emptyText: {
    marginTop: theme.spacing(2)
  },
  selectorFields: {
    paddingBottom: theme.spacing(2)
  },
  warning: {
    position: 'relative',
    top: '5px'
  },
  linkFacebookButton: {
    marginTop: theme.spacing(2),
    textAlign: 'center'
  }
}));

const checkHasFacebookConfig = facebookPage => {
  // require pageId and instagramId for a valid facebook page
  if (
    (facebookPage && facebookPage?.pageGroupId) ||
    (facebookPage?.pageId && facebookPage?.instagramId)
  ) {
    return true;
  }

  return false;
};

const pageText = ({ contentName }) => {
  // Needs to be captured in a variable here otherwise scanner will return __TRANSLATION_NOT_FOUND__
  const lowerCaseContentName = contentName?.toLowerCase();

  return {
    facebookSectionTitle: t('programCreate:configure.facebookSectionTitle'),
    facebookSectionDescription: t(
      'programCreate:configure.facebookSectionDecription'
    ),
    contentSectionTitle: t('programCreate:configure.contentSectionTitle', {
      contentName
    }),
    contentSectionDescription: t(
      'programCreate:configure.contentSectionDescription',
      {
        contentName: lowerCaseContentName
      }
    ),
    automationRulesTitle: t('programCreate:configure.automationRulesTitle'),
    automationNameSection: t('programCreate:configure.automationNameSection')
  };
};

const ProgramStepConfigure = props => {
  const {
    architecture = {},
    architectureHasCatalog,
    blueprints,
    isAutomated,
    contentName,
    facebook,
    isContentSelectable,
    selectedBlueprint,
    handleSelectBlueprint,
    selectedBusinessObjects,
    type,
    skipStep,
    setSkipStep,
    handleNext,
    lockSubmit,
    showValidationErrors,
    channelValidationErrors,
    isChannelValidationLoading,
    adCreativeErrors,
    channelInputValidators,
    setContinueCreateProgram,
    setSelectedBusinessObjects,
    hookFormMethods,
    isHookForm,
    aiAdCopyWriterInputs,
    aiChat,
    highlightedInputs,
    conditionalInputsVisibility
  } = props;

  const dynamicUserInputSections = selectedBlueprint?.inputSections || [];

  const contentColumns = contentColumnsFromArchitecture(architecture);

  const {
    formState: { errors: formSyncErrors },
    setError,
    clearErrors
  } = useFormContext();

  const selectedProductId = selectedBlueprint?.id;

  const classes = useStyles();
  const history = useHistory();
  const isClone = isProgramClone(history.location.search);

  const currentFacebookPageGroupId = useWatch({
    name: 'dynamicUserInputs.pageGroupId'
  });
  const currentFacebookPageId = useWatch({ name: 'dynamicUserInputs.pageId' });

  const shouldCollapseFacebookSection = !!facebook?.getSelectedFacebookPage({
    pageGroupId: currentFacebookPageGroupId,
    pageId: currentFacebookPageId
  });

  const text = useMemo(
    () =>
      pageText({
        contentName
      }),
    [contentName]
  );

  useEffect(() => {
    if (selectedProductId) {
      Instrumentation.logEvent(
        isAutomated
          ? Instrumentation.Events.ProgramAutomationBlueprintSelect
          : Instrumentation.Events.ProgramStartStepClicked,
        {
          productId: selectedProductId,
          architectureId: architecture.id,
          channel: getOneOfEachChannelKeys(
            selectedBlueprint?.blueprint?.channels
          ),
          ...(!isAutomated && { isClone, type: programTrackingTypes.program })
        }
      );
    }
  }, [selectedProductId]);

  const allConfigureStepErrors = () => {
    return {
      ...pick(formSyncErrors, ['configureStep', 'dynamicUserInputs'])
    };
  };

  const renderNothingToDo = () => {
    return (
      <Grid className={classes.nothingToDo} item xs={12}>
        <Trans i18nKey="programCreate:configure.nothingToDo">
          Nothing for you to do here, you can head to the next step!
        </Trans>
      </Grid>
    );
  };

  const renderLinkFacebook = () => {
    return (
      <Grid className={classes.emptyText} item xs={12}>
        <Alert severity="info">
          <AlertTitle>
            <Trans i18nKey="programCreate:configure.linkFacebookTitle" />
          </AlertTitle>
          <Trans i18nKey="programCreate:configure.linkFacebook" />
          <div className={classes.linkFacebookButton}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setContinueCreateProgram(
                  `${history.location.pathname}${history.location.search}`
                );
                history.push(paths.facebook.link);
              }}
            >
              <Trans i18nKey="programCreate:configure.linkFacebookLink" />
            </Button>
          </div>
        </Alert>
      </Grid>
    );
  };

  // Note: the user shouldn't ever be able to get to the page if these
  //       conditions are true. That said, sometimes shit happens so this will
  //       be a nice safety net.
  const renderNoProductState = () => {
    let message = '';

    if (!selectedProductId) {
      message = (
        <Typography className={classes.emptyText} variant="body2">
          <Trans i18nKey="programCreate:configure.pleaseSelectBlueprint">
            You must select a blueprint before you can configure your program.
          </Trans>
        </Typography>
      );
    } else if (!selectedBlueprint) {
      // This state should never happen but just in case.
      message = (
        <Typography className={classes.emptyText} variant="body2">
          <Trans i18nKey="programCreate:configure.noProductFound">
            We were unable to find the blueprint you selected. Please try
            selecting it again.
          </Trans>
        </Typography>
      );
    }

    return (
      <Grid className={classes.nothingToDo} item xs={12}>
        <Typography className={classes.title} variant="body2">
          <Trans i18nKey="programCreate:configure.pleaseSelectBlueprintTitle">
            Program configuration
          </Trans>
        </Typography>
        <Divider />
        {message}
      </Grid>
    );
  };

  const isEditing =
    type === programActions.automatedEdit || type === programActions.edit;

  const blueprintHasFacebook = checkBlueprintHasFacebook(
    dynamicUserInputSections
  );

  const hasFacebookPage = checkHasFacebookConfig(facebook?.defaultFacebookPage);
  const facebookPagesWithNoTos = facebook?.facebookPages?.filter(
    page => !page.hasAcceptedTos
  );

  if (blueprintHasFacebook && !hasFacebookPage) {
    if (!formSyncErrors?.configureStep?.noFacebookAccount) {
      setError('configureStep.noFacebookAccount', {
        type: 'manual',
        message: 'custom message'
      });
    }
  } else if (formSyncErrors?.configureStep?.noFacebookAccount) {
    clearErrors('configureStep.noFacebookAccount');
  }

  let dynamicContent;
  // Check for invalid productId setup.
  if (blueprintHasFacebook && !hasFacebookPage) {
    dynamicContent = renderLinkFacebook({ facebookPagesWithNoTos: [] });
  } else if (!selectedProductId || !selectedBlueprint) {
    dynamicContent = renderNoProductState();
  } else if (isEmpty(dynamicUserInputSections)) {
    // Check for empty state.
    dynamicContent = renderNothingToDo();
  } else {
    dynamicContent = (
      <ProgramDynamicUserInputs
        blueprint={selectedBlueprint}
        dynamicUserInputSections={dynamicUserInputSections}
        contentColumns={contentColumns}
        businessObjects={selectedBusinessObjects?.selectedBusinessObjects}
        isContentSelectable={isContentSelectable}
        formName={PROGRAM_FORM_NAME}
        contentName={
          contentName || (
            <Trans i18nKey="programCreate:configure.defaultContentName">
              Content
            </Trans>
          )
        }
        adCreativeErrors={adCreativeErrors}
        isChannelValidationLoading={isChannelValidationLoading}
        formSyncErrors={formSyncErrors}
        channelInputValidators={channelInputValidators}
        hookFormMethods={hookFormMethods}
        isHookForm={isHookForm}
        aiAdCopyWriterInputs={aiAdCopyWriterInputs}
        aiChat={aiChat}
        highlightedInputs={highlightedInputs}
        conditionalInputsVisibility={conditionalInputsVisibility}
      />
    );
  }

  const { loading: businessObjectsLoading } = selectedBusinessObjects;
  if (blueprints?.loading || isEmpty(architecture)) {
    return <Loading />;
  }

  const creativeType = getCreativeType(
    selectedBlueprint?.blueprint?.publishers
  );

  const showContentSelector =
    (creativeType[0] !== adCreativeTypes.fbDynamicCreative ||
      isContentSelectable) &&
    architectureHasCatalog;

  /*
   * Show the facebook field if:
   *  - A default facebook page has been determined to exist.
   *  - If no eligible default facebook page exists, the BP contains Facebook lead form
   *    and facebook pages have been linked without an accepted ToS.
   */
  const showFacebookField = !!(
    blueprintHasFacebook &&
    (hasFacebookPage ||
      (!hasFacebookPage &&
        facebookPagesWithNoTos?.length &&
        selectedBlueprint?.isLeadFormFacebookBlueprint))
  );
  const showFooter = !(blueprintHasFacebook && !hasFacebookPage);

  const contentSection = showContentSelector && (
    <Grid item xs={12}>
      <FormSection
        title={
          isAutomated ? (
            <span
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              {text.automationRulesTitle}
            </span>
          ) : (
            text.contentSectionTitle
          )
        }
        description={text.contentSectionDescription}
      >
        <Grid container>
          <Grid item xs={12}>
            {isAutomated ? (
              <>
                {!businessObjectsLoading && (
                  <FilterSelector
                    architecture={architecture}
                    contentName={contentName}
                    blueprints={blueprints}
                    isEditing={isEditing}
                    type={type}
                    selectedBlueprint={selectedBlueprint}
                    handleSelectBlueprint={handleSelectBlueprint}
                  />
                )}
              </>
            ) : (
              <ContentSelector
                architecture={architecture}
                architectureHasCatalog={architectureHasCatalog}
                contentName={contentName}
                selectedBlueprint={selectedBlueprint}
                isContentSelectable={isContentSelectable}
                selectedBusinessObjects={selectedBusinessObjects}
                setSelectedBusinessObjects={setSelectedBusinessObjects}
                skipStep={skipStep}
                setSkipStep={setSkipStep}
                businessObjectListItemSx={theme => ({
                  maxWidth: 500,
                  [theme.breakpoints.down('sm')]: {
                    px: 0
                  }
                })}
                businessObjectListSx={{
                  p: theme =>
                    selectedBusinessObjects?.selectedBusinessObjects?.length
                      ? 0
                      : theme.spacing(6.5, 0)
                }}
                isHookForm={isHookForm}
                formSectionName={PROGRAM_FORM_SECTION_CONFIGURE_NAME}
              />
            )}
          </Grid>
        </Grid>
      </FormSection>
    </Grid>
  );

  return (
    <>
      <Grid
        sx={{
          marginTop: `0 !important`,
          marginLeft: `0 !important`,
          width: '100% !important'
        }}
        container
        gap={2}
        data-cy="program-form-configure-step"
      >
        {showFacebookField && (
          <Grid item xs={12}>
            <FormSection
              title={text.facebookSectionTitle}
              description={text.facebookSectionDescription}
              isCollapsed={shouldCollapseFacebookSection}
              productId={selectedProductId}
              channel={getOneOfEachChannelKeys(
                selectedBlueprint?.blueprint?.channels
              )}
            >
              <Grid container>
                <Grid item xs={12} md={6}>
                  <FacebookSettings
                    isHookForm={isHookForm}
                    facebook={facebook}
                    isLeadFormFacebookBlueprint={
                      selectedBlueprint?.isLeadFormFacebookBlueprint
                    }
                  />
                </Grid>
              </Grid>
            </FormSection>
          </Grid>
        )}

        {contentSection}

        {dynamicContent}

        {isAutomated && (
          <Grid xs={12} item>
            <FormSection title={text.automationNameSection}>
              <AutomatedProgramName
                contentName={contentName}
                architecture={architecture}
              />
            </FormSection>
          </Grid>
        )}
      </Grid>

      {/*
          if they need to link a facebook page we hide the footer
      */}
      {showFooter && (
        <ProgramStepFooter
          formStepErrors={allConfigureStepErrors()}
          loading={
            businessObjectsLoading ||
            blueprints?.loading ||
            selectedBusinessObjects.loading
          }
          skipStep={skipStep}
          setSkipStep={setSkipStep}
          blueprint={selectedBlueprint}
          architecture={architecture}
          handleNext={handleNext}
          lockSubmit={lockSubmit}
          showValidationErrors={showValidationErrors}
          channelValidationErrors={channelValidationErrors}
          isChannelValidationLoading={isChannelValidationLoading}
          isAuomated={isAutomated}
          selectedBusinessObjects={selectedBusinessObjects}
        />
      )}
    </>
  );
};

export default connect(null, { setContinueCreateProgram })(
  ProgramStepConfigure
);
