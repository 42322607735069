import WorkatoConnect from 'src/components/Workato/WorkatoConnect';
import { t } from 'i18next';

import { Button, Typography } from '@mui/material';
import Modal from 'src/components/Modal';

type WorkatoConnectModalProps = {
  workatoConnectModalOpen: boolean;
  isWorkatoConnectFirstLoad: boolean;
  closeWorkatoConnectModal: (settingsState?: boolean) => void;
  handleWorkatoConnectSuccess: () => void;
};

const WorkatoConnectModal = ({
  workatoConnectModalOpen,
  isWorkatoConnectFirstLoad,
  closeWorkatoConnectModal,
  handleWorkatoConnectSuccess
}: WorkatoConnectModalProps) => {
  const handleDismiss = () => {
    closeWorkatoConnectModal(true);
  };

  return (
    <Modal
      open={workatoConnectModalOpen}
      maxWidth="md"
      headerText={
        !isWorkatoConnectFirstLoad
          ? t('chrome:workatoConnect.modalHeader')
          : undefined
      }
      HeaderComponent={
        isWorkatoConnectFirstLoad
          ? () => (
              <div>
                <Typography variant="h6">
                  {t('chrome:workatoConnectPrompt.header')}
                </Typography>
                <Typography variant="subtitle2">
                  {t('chrome:workatoConnectPrompt.subHeader')}
                </Typography>
              </div>
            )
          : undefined
      }
      onClose={() => {
        closeWorkatoConnectModal();
      }}
      headerTypographyVariant="h6"
      FooterComponent={
        isWorkatoConnectFirstLoad
          ? () => (
              <>
                <Button
                  onClick={() => closeWorkatoConnectModal()}
                  data-amp-fb_page_modal_dismiss
                >
                  {t('chrome:workatoConnectPrompt.dismissButton')}
                </Button>

                <Button onClick={handleDismiss}>
                  {t('chrome:workatoConnectPrompt.dismissForeverButton')}
                </Button>
              </>
            )
          : undefined
      }
    >
      <>
        <WorkatoConnect onSuccess={handleWorkatoConnectSuccess} />
      </>
    </Modal>
  );
};

export default WorkatoConnectModal;
