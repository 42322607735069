/**
 * The current known experiment names.
 *
 * Verify the values in this object are EXACTLY the same as
 * in amplitude.
 *
 * When an experiment is concluded, comment out the experiment
 * name here and link to the ticket that closes the experiment.
 * This way we don't re-use experiment names accidentally.
 */
export const EXPERIMENT_NAMES = {
  // UIX_PROGRAM_STEPS_CONFIGURATION_V2: 'checkout-flow-test',
  UIX_PROGRAMS_PERFORMANCE_PREDICTION: 'performance-prediction',
  // content-aware-dashboard - https://evocalize.atlassian.net/browse/UF-430,
  // UIX_PROGRAM_CREATION_UX_TEST_V3: 'program-creation-ux-test-v3',
  // AI_CONTENT_GENERATION: 'ai-generated-copy-v1',
  // AI_STYLIZATION: 'ai-generated-copy-v2',
  // INFORMATION_ARCHITECTURE: 'information-architecture-v1',
  // AD_CREATIVE_VALIDATIONS: 'ad-creative-validations',
  // RENDER_LINK_URL_URL_VALIDATION: 'renderlinkurl-url-validation',
  // QUICK_AUTOMATIONS_V1: 'quick-automations-v1',
  // TRANSLATIONS: 'translations',
  // FACEBOOK_PAGE_LINKING_UX_V1: 'facebook-page-linking-ux-v1',
  // MAGICBELL_NOTIFICATION_PREFERENCES: 'magicbell-notification-preferences',
  // PROGRAM_CLONING: 'program-cloning',
  // PROGRAM_DRAFTS_V1: 'program-drafts-v1',
  GENAI_AD_COPY_WRITING_V3: 'genai-ad-copy-writing-v3',
  SUPRSEND_INBOX_MIGRATION: 'suprsend-inbox-migration'
} as const;

export type ExperimentNameKey = keyof typeof EXPERIMENT_NAMES;

export type ExperimentName = (typeof EXPERIMENT_NAMES)[ExperimentNameKey];

export const EXPERIMENT_VARIANTS = {
  CONTROL: 'control',
  TREATMENT: 'treatment',
  ON: 'on'
};
