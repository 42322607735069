import SentryUtil from 'src/common/SentryUtil';

// import {
//     generateApiUrl,
//     getStandardHeaders,
//     responseWrapper
// } from 'src/api/ApiUtil';

// const ONBOARDING_BASE = '/facebook/onboarding';
const FACEBOOK_BASE = 'https://graph.facebook.com/v21.0';

// export const getFacebookPages = appSettings => {
//     return Promise.resolve(
//         fetch(generateApiUrl(appSettings, `${ONBOARDING_BASE}/pages`), {
//             method: 'GET',
//             headers: getStandardHeaders()
//         })
//     )
//         .then(response => responseWrapper(response))
//         .then(response => {
//             if (response && Array.isArray(response.data)) {
//                 response.data = response.data.map(page => ({
//                     id: page.pageId,
//                     name: page.pageName
//                 }));
//             }

//             return {
//                 pages: response
//             };
//         });
// };

export const FB_STATUSES = {
  PENDING: 'pending',
  BOT_ASSIGNED: 'bot_assigned',
  COMPLETED: 'completed',
  ERROR: 'error'
};

// /////////////////////////////////////////////////////////////////////////////
// New World test values
// /////////////////////////////////////////////////////////////////////////////

// Commenting out but keeping around for easy testing of Facebook linking.
/*
export const testData = {
    pending: {
        id: 'ev-fbPage-123',
        userId: 'ev-userId-123',
        groupId: 'ev-groupId-123',

        pageId: '159111791561409',
        pageName: 'Lawless Realty',
        facebookUserId: '44901843',
        facebookDisplayName: 'Daniel Lawless',
        instagramId: 'randomInstagramId',
        pageStatus: 'pending',
        businessManagerId: 'randomBusinessManagerId',
        createdAt: 'some-date-we-wont-use',
        updatedAt: 'another-date-we-wont-use'
    },
    pageIdSetWithTOSNot: {
        id: 'ev-fbPage-123',
        userId: 'ev-userId-123',
        groupId: 'ev-groupId-123',

        pageId: '159111791561409',
        pageName: 'Lawless Realty',
        facebookUserId: '44901843',
        facebookDisplayName: 'Daniel Lawless',
        instagramId: 'randomInstagramId',
        pageStatus: 'bot_assigned',
        businessManagerId: 'randomBusinessManagerId',
        createdAt: 'some-date-we-wont-use',
        updatedAt: 'another-date-we-wont-use'
    },
    completedSuccess: {
        id: 'ev-fbPage-123',
        userId: 'ev-userId-123',
        groupId: 'ev-groupId-123',
        pageId: '159111791561409',
        pageName: 'Lawless Realty',
        facebookUserId: '44901843',
        facebookDisplayName: 'Daniel Lawless',
        instagramId: 'randomInstagramId',
        pageStatus: 'completed',
        businessManagerId: 'randomBusinessManagerId',
        createdAt: 'some-date-we-wont-use',
        updatedAt: 'another-date-we-wont-use'
    },
    error: {
        id: 'ev-fbPage-123',
        userId: 'ev-userId-123',
        groupId: 'ev-groupId-123',
        pageId: '159111791561409',
        pageName: 'Lawless Realty',
        facebookUserId: '44901843',
        facebookDisplayName: 'Daniel Lawless',
        instagramId: 'randomInstagramId',
        pageStatus: 'error',
        businessManagerId: 'randomBusinessManagerId',
        createdAt: 'some-date-we-wont-use',
        updatedAt: 'another-date-we-wont-use'
    }
};
*/

// Facebook Direct API requests.
export const getFacebookPagesWithToken = token => {
  return Promise.resolve(
    fetch(
      `${FACEBOOK_BASE}/me/accounts?fields=is_published,promotion_eligible,access_token,category,category_list,id,name,store_number,tasks&limit=1000`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
  ).then(response => response.json());
};

export const getFacebookUserInfoWithToken = token => {
  return Promise.resolve(
    fetch(`${FACEBOOK_BASE}/me`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  ).then(response => response.json());
};

const getFacebookPageStoreLocation = async pages => {
  return Promise.all(
    pages.data.map(async value => {
      if (value?.store_number) {
        return fetch(
          `${FACEBOOK_BASE}/${value?.id}?access_token=${value.access_token}&fields=store_location_descriptor`,
          { method: 'GET' }
        ).then(async response => {
          const data = await response.json();
          return {
            ...value,
            // modifying the name to include the store location descriptor so all Facebook page names get updated
            name: `${value.name} - ${data.store_location_descriptor}`,
            store_location_descriptor: data.store_location_descriptor
          };
        });
      }
      return value;
    })
  );
};

export const getFacebookPagesAndUserWithToken = async token => {
  try {
    const values = await Promise.all([
      getFacebookPagesWithToken(token),
      getFacebookUserInfoWithToken(token)
    ]);

    const pagesWithStoreLocation = await getFacebookPageStoreLocation(
      values[0]
    );

    return {
      pages: { data: pagesWithStoreLocation },
      user: values[1]
    };
  } catch (error) {
    // Send error information off to Sentry.
    SentryUtil.addBreadcrumb({
      category: 'facebook-page-linking',
      data: {
        message: 'Error getting Facebook pages, user, and store location',
        errObject: error
      }
    });
    SentryUtil.captureException(error);

    return {
      pages: [],
      user: {}
    };
  }
};

// promotion_eligible | is_published
// "MANAGE"
