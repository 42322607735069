// TODO: rename this to reflect program types vs the redux actions
// TODO: edit should be a flag on program vs a type
// program types
export const programActions = {
  create: 'PROGRAM_CREATE',
  edit: 'PROGRAM_EDIT',
  automatedCreate: 'AUTOMATED_PROGRAM_CREATE',
  automatedEdit: 'AUTOMATED_PROGRAM_EDIT',
  multiLocationCreate: 'MULTI_LOCATION_PROGRAM_CREATE',
  multiLocationEdit: 'MULTI_LOCATION_PROGRAM_EDIT',
  unknown: 'UNKNOWN'
};

export type ProgramActionType =
  (typeof programActions)[keyof typeof programActions];

// Note: Order here matters because we map over the data. We want
//       One-time payment to show up first
export const scheduleTypes = {
  purchase: {
    value: 'purchase',
    name: 'One-time payment'
  },
  subscription: {
    value: 'subscription',
    name: 'Recurring subscription'
  }
};

export const programErrorTypes = {
  billingException: 'LithiumBillingException',
  validationException: 'LithiumValidationErrors',
  paymentAuthorizationException: 'LithiumPaymentAuthorizationException'
};

export const programErrorMessageToMatch = {
  startDateBeforeToday:
    /Order validation failed; scheduleStartEpochSeconds cannot be before today/gm
};

export const PROGRAM_STEP_NAMES = {
  configure: 'configure',
  spend: 'spend',
  summary: 'summary'
};

export const PROGRAM_STEP_NAME_TO_ORDER = {
  [PROGRAM_STEP_NAMES.configure]: 0,
  [PROGRAM_STEP_NAMES.spend]: 1,
  [PROGRAM_STEP_NAMES.summary]: 2
};

export const CONDENSED_PROGRAM_STEP_NAMES = {
  configure: 'configure',
  spend: 'spend'
};

export const PROGRAM_FORM_NAME = 'program';
export const AUTOMATED_MIN_DAYS = 2;
export const AUTOMATED_DEFAULT_DAYS = 7;
export const PROGRAM_FORM_SECTION_SPEND_NAME = 'spendStep';
export const PROGRAM_FORM_SECTION_DYNAMIC_INPUTS_NAME = 'dynamicUserInputs';
export const PROGRAM_FORM_SECTION_CONFIGURE_NAME = 'configureStep';

export const programTrackingTypes = {
  program: 'program',
  multiLocation: 'multiLocation',
  automation: 'automation'
};

export const getProgramTrackingType = ({
  isMultiLocation = false,
  isSupervisable = false
}) => {
  if (isMultiLocation) {
    return programTrackingTypes.multiLocation;
  }

  if (isSupervisable) {
    return programTrackingTypes.automation;
  }

  return programTrackingTypes.program;
};
