import { ReactNode, useState } from 'react';
import { Redirect, Link as RouterLink } from 'react-router-dom';
import { t } from 'i18next';
import { Trans } from 'react-i18next';

import {
  Box,
  Grid,
  Button,
  useTheme,
  useMediaQuery,
  darken,
  Link
} from '@mui/material';

import { useAppSettings } from 'src/AppSettings';
import { paths } from 'src/routes/paths';

interface PlgWrapperProps {
  children: ReactNode;
}

const PlgWrapper = ({ children }: PlgWrapperProps) => {
  const theme = useTheme();
  const appSettings = useAppSettings();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('md'));

  const { organizationPlgConfig } = appSettings;

  // we will consider the mobile start complete if the user is on a desktop
  const [mobileStartComplete, setMobileStartComplete] =
    useState<boolean>(!isMobileSize);

  // redirect to dashboard if the users does not have the organizationPlgConfig
  if (!organizationPlgConfig) {
    return (
      <Redirect
        to={{
          pathname: paths.dashboard.base,
          state: { from: window?.location?.href }
        }}
      />
    );
  }

  const showStepOne = (isMobileSize && !mobileStartComplete) || !isMobileSize;
  const showStepTwo = (isMobileSize && mobileStartComplete) || !isMobileSize;

  return (
    <Grid container sx={{ height: '100vh' }}>
      {showStepOne && (
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            background: '#0C121D',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Box
            sx={{
              position: 'relative', // Ensure the button is positioned relative to the image
              maxWidth: '100%',
              height: 'auto',
              maxHeight: '100vh',
              minHeight: '800px'
            }}
          >
            <img
              src="https://ev-prod.s3.amazonaws.com/public/plg/plg-signup.png"
              alt="Collaborative Marketing Platform"
              style={{
                maxWidth: '100%',
                height: 'auto',
                objectFit: 'contain',
                maxHeight: '100vh',
                minHeight: '800px',
                display: 'block'
              }}
            />
            {isMobileSize && (
              <Box
                sx={{
                  position: 'absolute',
                  bottom: '22%',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
              >
                <Button
                  size="large"
                  variant="contained"
                  onClick={() => setMobileStartComplete(true)}
                  sx={{
                    background: 'white',
                    color: '#0E0035',
                    width: '300px',
                    '&:hover': {
                      background: darken('#fff', 0.2)
                    }
                  }}
                >
                  {t('common:plg.signUp.getStarted')}
                </Button>
                <Box sx={{ color: 'white', '& a': { color: 'white' } }}>
                  <Box
                    sx={{
                      marginTop: theme => theme.spacing(1),
                      fontSize: theme => theme.typography.body2.fontSize
                    }}
                  >
                    <Trans
                      i18nKey="common:plg.signUp.haveAccount"
                      components={[
                        <Link
                          component={RouterLink}
                          to={paths.dashboard.base}
                        />
                      ]}
                    />
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Grid>
      )}
      {showStepTwo && (
        <Grid
          item
          xs={12}
          md={7}
          sx={{
            background: theme => theme.palette.grey[200],
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            padding: '8%'
          }}
        >
          {children}
        </Grid>
      )}
    </Grid>
  );
};

export default PlgWrapper;
