import { useState, useEffect, CSSProperties } from 'react';
import { noop, isEqual, pick, isEmpty } from 'lodash';
import querystring from 'query-string';
import classNames from 'classnames';
import { useForm, FormProvider, useWatch } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { t } from 'i18next';
import { useSnackbar } from 'notistack';

import {
  useMediaQuery,
  Box,
  Typography,
  IconButton,
  Alert,
  AlertTitle,
  CircularProgress
} from '@mui/material';
import { useTheme, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import { BreadcrumbTrail } from 'src/components/BreadcrumbTrail/BreadcrumbTrail';
import PageTitle from 'src/components/PageTitle';
import {
  useExperiment,
  EXPERIMENT_NAMES
} from 'src/experiments/ExperimentContextProvider';
import { contentColumnsFromArchitecture } from 'src/common/dynamicUserInputs';
import { useFeatures } from 'src/components/Feature';

import { paths } from 'src/routes/paths';

import { useGlobalContext } from 'src/GlobalContextProvider';
import { useArchitecture } from 'src/pages/Architecture/ArchitectureProvider';

import useLockBodyScroll from 'src/hooks/useLockBodyScroll';
import { isProgramClone } from 'src/common/cloneProgram';

import { getConditionalInputVisibilityFromBlueprint } from 'src/common/conditionals';
import { useSwitchTheme } from 'src/hooks/useSwitchTheme';
import NavigationBlocker from 'src/components/NavigationBlocker';
import Instrumentation from 'src/instrumentation';
import { formErrorMapper } from 'src/components/ReduxForm/helpers';
import {
  paymentErrorByBackendDisplayCode,
  genericCardDeclinedError
} from 'src/common/paymentUtils';

import { LOCATIONS_OVERRIDES_BY_ID_NAME } from 'src/pages/Program/ProgramSteps/MultiLocationPublish/utils';
import { AiChat, getAiAdCopyWriterInputs } from 'src/components/AiChat';
import { LocationConnection } from 'src/generated/gql/graphql';

import useCreativeValidationsHandler from './useCreativeValidationsHandler';
import ProgramPreviewDrawer from './ProgramPreviewDrawer';
import StepSkipper from './ProgramSteps/StepSkipper';
import {
  PROGRAM_FORM_NAME,
  PROGRAM_STEP_NAME_TO_ORDER,
  programActions,
  programErrorTypes,
  programErrorMessageToMatch,
  PROGRAM_STEP_NAMES,
  getProgramTrackingType
} from './Constants';

import { useIsDrawerFullScreen } from './ProgramPreviewDrawer/useIsDrawerFullScreen';
import { DRAWER_FULL_SCREEN_BREAKPOINT } from './ProgramPreviewDrawer/constants';
import ProgramStepper from './ProgramSteps/ProgramStepper';
import useProgram from './utils/useProgram';
import useHandleStepNavigation from './utils/useHandleStepNavigation';
import { getSelectedLocationsMetadata } from './utils/dataFormatters';
import InnerContainer from './components/InnerContainer';

const useStyles = makeStyles((theme: Theme) => {
  const getColumnLayout = (spacing: number) => ({
    display: 'flex',
    flexDirection: 'column' as CSSProperties['flexDirection'],
    gap: theme.spacing(spacing)
  });

  const baseContentStyles = {
    flexGrow: 1,
    transition: theme.transitions.create('padding', {
      duration: 0
    })
  };

  const { previewDrawerWidth } = theme.evSizes;

  return {
    content: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(5),
      flexDirection: 'row',
      display: 'flex',
      gap: theme.spacing(7.5),
      ...baseContentStyles,
      [theme.breakpoints.between(0, DRAWER_FULL_SCREEN_BREAKPOINT)]: {
        gap: 0
      },
      [theme.breakpoints.down('md')]: {
        padding: 0
      }
    },
    contentOpen: {
      paddingRight: 0,
      [theme.breakpoints.between(0, DRAWER_FULL_SCREEN_BREAKPOINT)]: {
        paddingRight: `calc(${theme.spacing(3)} + ${previewDrawerWidth}px)`
      }
    },
    contentClosed: {
      paddingRight: theme.spacing(12),
      [theme.breakpoints.down('xl')]: {
        paddingRight: theme.spacing(6)
      },
      [theme.breakpoints.down('md')]: {
        paddingRight: theme.spacing(0)
      }
    },
    stepperRoot: () => ({
      background: 'inherit',
      padding: 0,
      width: '78%',
      margin: '0 auto',
      [theme.breakpoints.between(0, DRAWER_FULL_SCREEN_BREAKPOINT)]: {
        marginRight: 'auto'
      },
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        justifyContent: 'space-between'
      },
      [theme.breakpoints.down('md')]: {
        width: '100%'
      }
    }),
    paper: {
      padding: theme.spacing(2)
    },

    paperV2: () => ({
      padding: 0,
      marginTop: 0,
      [theme.breakpoints.between(0, DRAWER_FULL_SCREEN_BREAKPOINT)]: {
        marginRight: 0
      },

      [theme.breakpoints.down('lg')]: {
        padding: theme.spacing(0, 0)
      }
    }),

    step: {
      transition: theme.transitions.create('height', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    stepLabelRoot: {
      cursor: 'pointer',
      textTransform: 'uppercase',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        '& .MuiStepLabel-labelContainer': {
          padding: 0
        }
      }
    },
    stepLabelIconContainer: {
      [theme.breakpoints.down('sm')]: {
        paddingRight: 0,
        paddingBottom: theme.spacing(1)
      }
    },
    previewButton: {
      borderRadius: '0',
      position: 'fixed',
      right: 0
    },
    missingBlueprintSubtitle: {
      color: theme.palette.error.main
    },
    missingBlueprintSubtitleClick: {
      cursor: 'pointer'
    },
    subtitle: {
      height: '28px',
      display: 'flex',
      alignItems: 'center'
    },
    blueprintContainer: {
      display: 'flex',
      alignItems: 'center'
    },
    contentMissing: {
      maxWidth: '500px',
      margin: '0 auto'
    },

    formContainer: {
      ...getColumnLayout(6)
    }
  };
});

const Program = () => {
  const {
    programStepper: { stepRefs, selectExactStep, currentStep },
    aiChatContext: { toggleAiChatWindow, aiChatOpen },
    type,
    blueprints,
    selectedBlueprint,
    handleSelectBlueprint,
    openBlueprintsModal,
    contentName,
    initialValues,
    isAutomated,
    isAutomatedEdit,
    isMultiLocation,
    offersChanged,
    facebook,
    preselectedBusinessObjectIds,
    hasCatalog,
    isContentSelectable,
    blueprintChannels,
    allMlpLocations,
    handleProgramSubmit,
    previewDrawerOpen,
    togglePreviewDrawer,
    setCreativeValidationErrors,
    disableNavigationBlocker
  } = useProgram();

  const [highlightedInputs, setHighlightedInputs] = useState<string[]>([]);
  const architecture = useArchitecture();
  const location = useLocation();
  const { showCreativeValidations } = useFeatures();

  const globalContext = useGlobalContext();
  const params = querystring.parse(location.search);
  const { enqueueSnackbar } = useSnackbar();

  const { value: isGenAiAdCopyWritingV3Enabled, experimentsLoaded } =
    useExperiment(EXPERIMENT_NAMES.GENAI_AD_COPY_WRITING_V3);

  const { setThemeName, THEME_NAMES } = useSwitchTheme();
  const isDrawerFullScreen = useIsDrawerFullScreen();
  const userMetadataFields = globalContext?.me?.metadata?.fields;
  const isClone = isProgramClone(location.search);

  const [selectedBusinessObjects, setSelectedBusinessObjects] = useState({
    selectedBusinessObjects: [],
    loading:
      isContentSelectable &&
      type !== programActions.automatedEdit &&
      type !== programActions.automatedCreate,
    error: null
  });

  const isConfigureStep = currentStep === PROGRAM_STEP_NAME_TO_ORDER.configure;

  const formMethods = useForm({
    defaultValues: initialValues,
    mode: 'all'
  });

  const {
    reset,
    handleSubmit,
    trigger,
    formState: { errors: allFormErrors, isDirty: formIsDirty },
    watch,
    setError,
    getValues,
    setValue,
    control
  } = formMethods;

  const {
    handleAdContentChannelValidation,
    isValidatingCreative,
    isPollingPreview,
    setIsPollingPreview,
    setIsValidatingCreative,
    creativeValidationErrors: channelValidationErrors,
    clearUpdatedInputCreativeErrors,
    inputValidators: channelInputValidators
  } = useCreativeValidationsHandler({
    product: selectedBlueprint,
    isAutomated,
    trigger
  });

  const allFormValues = useWatch({ control });

  const selectedLocationIds = useWatch({ name: 'selectedLocations', control });
  const dynamicUserInputs = useWatch({ name: 'dynamicUserInputs', control });
  const fbPageGroupId = useWatch({
    name: 'dynamicUserInputs.pageGroupId',
    control
  });
  const fbPageId = useWatch({ name: 'dynamicUserInputs.pageId', control });

  const selectedLocationsMetadata = getSelectedLocationsMetadata(
    allMlpLocations?.data?.locations as LocationConnection,
    selectedLocationIds
  );

  // TODO: resetProgram
  const resetProgram = noop;
  // TODO: figure equivalent to destroy in hookForm
  const destroy = noop;

  // This only used to manage the loading state for polling/genereating validations
  // I think we can remove this and store the state in here or the ProgramProvider.
  const [isValidatingCreativeMLP, setIsValidatingCreativeMLP] = useState<
    boolean | null
  >(null);
  const [isPollingPreviewMLP, setIsPollingPreviewMLP] = useState(false);

  const conditionalInputsVisibility =
    getConditionalInputVisibilityFromBlueprint(
      selectedBlueprint,
      allFormValues,
      userMetadataFields,
      selectedBusinessObjects?.selectedBusinessObjects,
      selectedLocationsMetadata
    );

  const [drawerPosition, setDrawerPosition] = useState('absolute');

  // User can't scroll body (program form underneath) while the drawer is open and full screen
  useLockBodyScroll(previewDrawerOpen && isDrawerFullScreen);

  // Sets the body background-color to off-white for program and automation checkout forms
  // Sets theme back to default when component unmounts
  useEffect(() => {
    setThemeName(THEME_NAMES.programCreatePage);

    return () => {
      setThemeName(THEME_NAMES.default);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = useStyles({
    drawerPosition
  });

  // this tracks progressing through the steps one by one
  // if the step=spend|summary parameter is set
  const [skipStep, setSkipStep] = useState(() => {
    // This conditional ensures that an attempt to deeplink to step 3 (summary) will not crash the form in V3 treatment UI
    if (params.step === PROGRAM_STEP_NAMES.summary) {
      return;
    }
    if (params.step && PROGRAM_STEP_NAME_TO_ORDER[params.step as string]) {
      return {
        currentStep: 0,
        targetStep: PROGRAM_STEP_NAME_TO_ORDER[params.step as string],
        // we have to make sure we have tried to pull any missing content before we try and skip steps
        // this is just for pull providers and it sucks
        contentVerified: !(
          isContentSelectable && preselectedBusinessObjectIds.length > 0
        )
      };
    }
  });

  // this is when we are verifying if any content isn't pulled via pull provider
  const [fetchingContent, setFetchingContent] = useState(false);

  const [orderSuccess, setOrderSuccess] = useState(false);

  const stepTrackingData = {
    architectureId: architecture?.id,
    productId: selectedBlueprint?.id,
    channel: blueprintChannels,
    ...(!isAutomated && { isClone })
  };

  const allConfigureStepErrors = () => {
    return pick(allFormErrors, ['configureStep', 'dynamicUserInputs']);
  };

  const showValidationErrors = () => {
    if (!skipStep) {
      enqueueSnackbar(t('program:snackbar.formErrors'), {
        variant: 'error'
      });
    }

    formErrorMapper(allConfigureStepErrors()).forEach((fieldName: string) => {
      // manually set all fields to touched in react-hook-form
      // not really a better way to set all these fields to touched
      // ideally we re-write all fields to watch some form level validaiton state
      const formValue = getValues(fieldName);
      setValue(fieldName, formValue, { shouldTouch: true });
    });
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    trigger();
  };

  const { handleNext, lockSubmit } = useHandleStepNavigation({
    skipStep,
    setSkipStep,
    trackingData: stepTrackingData,
    showValidationErrors
  });

  const trackingData = {
    architectureId: architecture?.id,
    productId: selectedBlueprint?.id,
    type: isAutomated ? 'automation' : 'program',
    channel: blueprintChannels
  };

  const hasLocationsOverrides = !isEmpty(watch(LOCATIONS_OVERRIDES_BY_ID_NAME));

  const handleStepChange = (step: number) => {
    if (step === currentStep) {
      return;
    }
    if (step < currentStep) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      trigger().then(isValid => {
        if (isValid) {
          selectExactStep(step, trackingData);
        }
      });
    } else {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      trigger().then(isValid => {
        // don't allow the user to skip steps if they have errors or have overrides b/c they need to
        // use the proceed button to trigger the apply modal
        if (isValid && !hasLocationsOverrides) {
          return handleNext({
            currentStep,
            formStepErrors: allConfigureStepErrors()
          });
        }
        // ensure we are showing all the errors
        showValidationErrors();
      });
    }
  };

  const theme: Theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  // const upToSmall = useMediaQuery(theme.breakpoints.up('sm'));

  useEffect(() => {
    // component will mount
    // Note We need to reset() redux form on mount because we have set destroyOnUnmount:false to
    //      preserve the form state for the stepper. This will allow form elements in each step to
    //      mount in and out and maintain their state.
    reset();

    // reset program back to initial state
    resetProgram();

    if (isMobile && previewDrawerOpen) {
      togglePreviewDrawer();
    }

    if (isAutomated) {
      Instrumentation.logEvent('program-automation-load');
    } else {
      Instrumentation.logEvent('program-load', {
        isClone,
        type: getProgramTrackingType({ isMultiLocation })
      });
    }

    // compunent will unmount
    return () => {
      destroy();
      // reset program back to initial state
      resetProgram();
    };
  }, []);

  const handleSubmitHandler = (data: Record<string, any>) => {
    return handleProgramSubmit(data, {
      architecture,
      selectedBlueprint,
      selectedBusinessObjects,
      userMetadataFields
    })
      .then(({ afterSuccess }: { afterSuccess: () => void }) => {
        // we have to set the form to success before we can navigate etc.
        setOrderSuccess(true);
        setTimeout(() => {
          afterSuccess();
        }, 0);
      })
      .catch(({ error, postErrorMessage }) => {
        setIsPollingPreviewMLP(false);
        setIsValidatingCreativeMLP(false);
        const errorName = error?.graphQLErrors?.[0]?.extensions?.errorName;
        const errorDisplayCode =
          error?.graphQLErrors?.[0]?.extensions?.additionalExceptionDetails
            ?.displayCode;

        // if card declined / billing error
        if (
          errorName === programErrorTypes.billingException ||
          errorName === programErrorTypes.paymentAuthorizationException
        ) {
          const errorMessage =
            paymentErrorByBackendDisplayCode(errorDisplayCode) ||
            genericCardDeclinedError();

          enqueueSnackbar(errorMessage, {
            variant: 'error'
          });

          // set a submission error that we look for on the payment selection component
          setError('paymentMethodId', {
            type: 'manual',
            message: errorMessage
          });
        }

        // validation errors
        if (
          error?.graphQLErrors?.[0]?.extensions?.errorName ===
          programErrorTypes?.validationException
        ) {
          // if start date is before today
          if (
            error?.graphQLErrors?.[0]?.message &&
            error?.graphQLErrors?.[0]?.message.match(
              programErrorMessageToMatch.startDateBeforeToday
            )
          ) {
            enqueueSnackbar(t('programCreate:snackbar.invalidStartDate'), {
              variant: 'error'
            });

            // set a submission error on startDate
            setError('spendStep.startDate', {
              type: 'custom',
              message: t(
                'programCreate:snackbar.invalidStartDateSubmissionError'
              )
            });
          }
        }

        enqueueSnackbar(
          postErrorMessage || t('programCreate:snackbar.submitErrorGeneric'),
          {
            variant: 'error'
          }
        );
      });
  };

  const aiAdCopyWriterInputs = isGenAiAdCopyWritingV3Enabled
    ? getAiAdCopyWriterInputs({
        dynamicUserInputs,
        selectedBlueprint,
        conditionalInputsVisibility
      })
    : [];

  // we need to remove the fixSyncErrorInputNameFilters from the form values so it does not trigger the navigation blocker
  const dirtyCheckFormValues = allFormValues;
  delete dirtyCheckFormValues?.fixSyncErrorInputNameFilters;

  const isAutomatedDirty =
    isAutomated && !isEqual(initialValues, dirtyCheckFormValues);

  // Warn the user when they navigate away from the page only if the form
  // has been changed (i.e. keep them from throwing away order work
  // they've done on accident.
  // Note: Once the user has placed the order, the form stays in a dirty
  //       state so we override this variable to always be false if we
  //       have a successful order.
  let shouldBlockNavigation = isAutomated ? isAutomatedDirty : formIsDirty;

  if (disableNavigationBlocker || orderSuccess) {
    shouldBlockNavigation = false;
  }

  const submitForm = handleSubmit(handleSubmitHandler);

  const strings = {
    previewButtonTip: t('program:preview.button'),
    missingBlueprintSubtitle: t('program:create.missingBlueprintSubtitle')
  } as const;

  const programActionStrings = {
    [programActions.create]: {
      title: t('program:create.title')
    },
    [programActions.automatedCreate]: {
      title: t('program:automatedCreate.title'),
      subtitle: t('program:automatedCreate.subtitle')
    },
    [programActions.automatedEdit]: {
      title: t('program:automatedEdit.title'),
      subtitle: t('program:automatedCreate.subtitle')
    }
  } as const;

  const getString = (stringId: 'title' | 'subtitle') => {
    return programActionStrings?.[type]?.[stringId];
  };

  let title = getString('title');

  if (architecture?.name) {
    title += `: ${architecture.name}`;
  }

  const isBreadcrumbVisible = !isAutomatedEdit;

  const breadcrumbPieces = [
    {
      text:
        type === programActions.create
          ? t('program:kind')
          : t('program:automatedKind'),
      to:
        type === programActions.create
          ? paths.programs.base
          : paths.automations.base
    },
    {
      text: getString('title') as string
    }
  ];

  const programStepProps = {
    allFormValues,
    architecture,
    architectureHasCatalog: hasCatalog,
    blueprints,
    contentName,
    facebook,
    formName: PROGRAM_FORM_NAME,
    initialValues,
    isAutomated,
    isAutomatedEdit,
    isContentSelectable,
    selectedBlueprint,
    handleSelectBlueprint,
    selectedBusinessObjects,
    setSelectedBusinessObjects,
    stepRefs,
    type,
    skipStep,
    setSkipStep,
    fetchingContent,
    setFetchingContent,
    submitForm,
    handleNext,
    lockSubmit,
    showValidationErrors,
    conditionalInputsVisibility,
    hookFormMethods: formMethods,
    isHookForm: true,
    ...(isMultiLocation && {
      isChannelValidationLoading:
        isPollingPreviewMLP || isValidatingCreativeMLP,
      isPollingPreview: isPollingPreviewMLP,
      setIsPollingPreview: setIsPollingPreviewMLP,
      selectedLocationsMetadata,
      setIsValidatingCreative: setIsValidatingCreativeMLP
    }),
    ...(isConfigureStep &&
      !isMultiLocation && {
        ...(showCreativeValidations && {
          channelValidationErrors,
          channelInputValidators,
          isChannelValidationLoading: isPollingPreview || isValidatingCreative,
          adCreativeErrors: channelValidationErrors
        }),
        ...(isGenAiAdCopyWritingV3Enabled && {
          aiAdCopyWriterInputs,
          aiChat: {
            toggleAiChatWindow,
            aiChatOpen
          },
          highlightedInputs
        })
      }),
    ...(currentStep === PROGRAM_STEP_NAME_TO_ORDER.spend && {
      offersChanged
    }),
    isMultiLocation
  };

  if (fetchingContent) {
    return (
      <Alert
        severity="info"
        icon={<CircularProgress />}
        className={classes.contentMissing}
      >
        <AlertTitle>
          {t('programCreate:contentMissing.title', {
            contentName
          })}
        </AlertTitle>
        {t('programCreate:contentMissing.description', {
          contentName
        })}
      </Alert>
    );
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          paddingTop: theme.spacing(3),
          paddingRight: previewDrawerOpen
            ? `${theme.evSizes.previewDrawerWidth}px`
            : 0
        }}
        data-cy={type}
      >
        <PageTitle subPageTitle={title} />
        <NavigationBlocker block={shouldBlockNavigation} />
        {!isMultiLocation && (
          <Box sx={{ marginBottom: 4 }}>
            {isBreadcrumbVisible && (
              <BreadcrumbTrail
                sx={theme => ({ marginBottom: theme.spacing(1) })}
                pieces={breadcrumbPieces}
              />
            )}
            <div className={classes.blueprintContainer}>
              {/* TODO update to uuse heading */}
              <Typography
                component="h1"
                variant="h4"
                sx={{ fontWeight: 'bold', fontSize: '30px' }}
                data-cy="selected-bp-title"
                className={classNames({
                  [classes.missingBlueprintSubtitle]: !selectedBlueprint,
                  [classes.missingBlueprintSubtitleClick]:
                    !isAutomatedEdit && isAutomated
                })}
                onClick={
                  isAutomated && !isAutomatedEdit ? openBlueprintsModal : noop
                }
              >
                {selectedBlueprint?.name ?? strings.missingBlueprintSubtitle}
              </Typography>
              {!isAutomatedEdit && isAutomated && currentStep === 0 && (
                // we don't allow blueprint changes on edit
                <IconButton
                  data-cy="change-blueprint-button"
                  size="small"
                  color={selectedBlueprint ? 'default' : 'error'}
                  onClick={openBlueprintsModal}
                >
                  <EditIcon fontSize="inherit" />
                </IconButton>
              )}
            </div>
          </Box>
        )}
        <InnerContainer
          previewDrawerOpen={previewDrawerOpen}
          data-cy="innnercontainer"
        >
          <StepSkipper skipStep={skipStep}>
            <FormProvider {...formMethods}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%'
                }}
              >
                <form
                  style={{ width: '100%' }}
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  onSubmit={handleSubmit(handleSubmitHandler)}
                >
                  <ProgramStepper
                    isMobile={isMobile}
                    classes={classes} // remove
                    programStepProps={programStepProps}
                    handleStepChange={handleStepChange}
                  />
                </form>
              </Box>
              <ProgramPreviewDrawer
                contentName={contentName}
                selectedBusinessObjects={selectedBusinessObjects}
                defaultFacebookPage={facebook?.getSelectedFacebookPage({
                  pageGroupId: fbPageGroupId,
                  pageId: fbPageId
                })}
                conditionalInputsVisibility={conditionalInputsVisibility}
                drawerPosition={drawerPosition}
                setDrawerPosition={setDrawerPosition}
                setCreativeValidationErrors={setCreativeValidationErrors}
                {...(isMultiLocation && {
                  handleAdContentChannelValidation,
                  setIsPollingPreview: setIsPollingPreviewMLP,
                  isPollingPreview: isPollingPreviewMLP,
                  setIsValidatingCreative: setIsValidatingCreativeMLP,
                  clearUpdatedInputCreativeErrors
                })}
                {...(isConfigureStep &&
                  !isMultiLocation && {
                    handleAdContentChannelValidation,
                    setIsPollingPreview,
                    isPollingPreview,
                    setIsValidatingCreative,
                    clearUpdatedInputCreativeErrors
                  })}
              />
            </FormProvider>
          </StepSkipper>
        </InnerContainer>
      </Box>
      {isGenAiAdCopyWritingV3Enabled &&
        experimentsLoaded &&
        isConfigureStep &&
        !isMultiLocation &&
        aiAdCopyWriterInputs?.length > 0 && (
          <AiChat
            inputs={aiAdCopyWriterInputs}
            setHighlightedInputs={setHighlightedInputs}
            sharedInputProps={{
              blueprint: selectedBlueprint,
              businessObjects: selectedBusinessObjects?.selectedBusinessObjects,
              isContentSelectable,
              contentName,
              contentColumns:
                contentColumnsFromArchitecture(architecture) || [],
              formName: PROGRAM_FORM_NAME
            }}
            parentFormMethods={formMethods}
          />
        )}
    </>
  );
};

export default Program;
