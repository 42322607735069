import { INotification } from '@magicbell/react-headless';
import { isNil, isObject, isString } from 'lodash';
import Instrumentation from 'src/instrumentation';
import { Events } from 'src/instrumentation/constants';
import { History } from 'history';
import SentryUtil from 'src/common/SentryUtil';

enum KnownCategories {
  ProgramEnding = 'program-ending'
}

const getCustomAttributesLegacy = (notification: INotification) => {
  if (isString(notification.customAttributes)) {
    return { default: notification.customAttributes };
  }

  if (isObject(notification.customAttributes)) {
    return notification.customAttributes;
  }

  return {};
};

/**
 * Extracts custom data specific to each notification category, since they all
 * can differ.
 */
const extractCategorySpecificDataLegacy = (notification: INotification) => {
  const customData = getCustomAttributesLegacy(notification);

  switch (notification.category) {
    case KnownCategories.ProgramEnding:
      return {
        programId: customData.programId,
        architectureId: customData.architectureId,
        orderId: customData.orderId
      };
    default:
      return {};
  }
};

export const logNotificationClickEventLegacy = (
  notification: INotification,
  history: History
) => {
  Instrumentation.logEvent(Events.NotificationClick, {
    id: notification.id,
    category: notification.category,
    path: history.location.pathname,
    ...extractCategorySpecificDataLegacy(notification)
  });
};

/**
 * Non-legacy SuprSend Versions
 * Extracts custom data specific to each notification category, since they all
 * can differ.
 */

// extraData is a JSON string
export interface SuprSendNotification {
  n_id: string;
  message: { url?: string; extra_data?: string };
}

export const parseExtraData = (extraData: string) => {
  try {
    return JSON.parse(extraData);
  } catch (e) {
    SentryUtil.captureException(e as Error);
    SentryUtil.addBreadcrumb({
      level: 'error',
      category: 'notification',
      message: 'Failed to parse notification metadata',
      data: {
        extraData
      }
    });
    return {};
  }
};

const getCustomAttributes = (notification: SuprSendNotification) => {
  if (!isNil(notification.message.extra_data)) {
    return parseExtraData(notification.message.extra_data);
  }

  return {};
};

const extractCategorySpecificData = (notification: SuprSendNotification) => {
  const customData = getCustomAttributes(notification);

  switch (customData?.eventType) {
    case KnownCategories.ProgramEnding:
      return {
        programId: customData.programId,
        architectureId: customData.architectureId,
        orderId: customData.orderId
      };
    default:
      return {};
  }
};

export const logNotificationClickEvent = (
  notification: SuprSendNotification,
  history: History
) => {
  const extraData = parseExtraData(notification.message.extra_data || '');

  Instrumentation.logEvent(Events.NotificationClick, {
    id: notification.n_id,
    category: extraData?.eventType || 'event type not present',
    path: history.location.pathname,
    ...extractCategorySpecificData(notification)
  });
};
