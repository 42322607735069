import { Fragment, useState, useMemo } from 'react';
import { flow, cloneDeep, isEmpty } from 'lodash';
import {
  FormSection,
  reduxForm,
  getFormMeta,
  getFormSyncErrors,
  getFormValues
} from 'redux-form';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

import {
  Button,
  Divider,
  Accordion,
  AccordionDetails,
  Grid,
  Menu,
  MenuItem,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import { withAppSettings } from 'src/AppSettings';
import { demoUserData } from 'src/common/demoUser/demoUserData';
import Logger from 'src/common/Logger';

import { DynamicForm } from 'src/components/ReduxForm';
import { headerInErrorState } from 'src/components/ReduxForm/helpers';
import { OFFICE_THEME_OVERRIDE_LOCAL_STORAGE } from 'src/components/theme/Constants';
import { getFeaturesFromSettings } from 'src/components/Feature/constants';
import Heading from 'src/components/PageElements/Heading';

import {
  FORM_NAME,
  SKIN_PREVIEW_LOCAL_STORAGE,
  skinSections,
  evAppSkinInputs,
  paletteSkinInputs,
  typographySkinInputs,
  evAssetsSkinInputs,
  evSizesSkinInputs,
  evAssetsQuickstarts,
  featuresInputs,
  automationFeaturesInputs,
  experimentsInputs,
  FONT_INPUT_METHOD
} from './Constants';
import {
  clearSkinPreview,
  formatInitialFontValues,
  formatSkinData
} from './helpers';
import { createOrUpdateSkinSettings } from './mutations';
import SkinSettingHeading from './SkinSettingHeading';
import AdminSkinPreviewModal from './SkinPreviewModal';
import AdminSkinResetModal from './SkinResetModal';
import AdminSkinPublishModal from './SkinPublishModal';
import CnameSettings from './CnameSettings';
import ConfigureAutomationAdoptionModal from './ConfigureAutomationAdoptionModal';
import useSnackbarOnSuccess from './hooks/useSnackbarOnSuccess';

const styles = theme => ({
  helpTip: {
    color: theme.palette.grey[500]
  },
  buttonContainer: {
    display: 'flex',
    marginTop: theme.spacing(2)
  },
  footerButton: {
    marginRight: theme.spacing(1)
  }
});

const AdminSkinSettings = props => {
  const {
    createOrUpdateSkinSettings,
    classes,
    formErrors,
    formMeta,
    handleSubmit,
    submitFailed,
    fontInputMethod
  } = props;

  // this is not being set anyplace??
  const [diff] = useState({});
  const [isPublishing, setIsPublishing] = useState(false);
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const [publishError, setPublishError] = useState(null);
  const [publishModalOpen, setPublishModalOpen] = useState(false);
  const [resetModalOpen, setResetModalOpen] = useState(false);
  const [copyMenuAnchorEl, setCopyMenuAnchorEl] = useState(null);
  const [automationAdoptionConfig, setAutomationAdoptionConfig] =
    useState(false);

  // Sets up useEffect to remove search params and enqueue successs snackbar
  useSnackbarOnSuccess();

  const openPublishModal = () => {
    setPublishModalOpen(true);
  };

  const closePublishModal = () => {
    setPublishModalOpen(false);
  };

  const openCopyMenu = event => {
    setCopyMenuAnchorEl(event.currentTarget);
  };

  const closeCopyMenu = () => {
    setCopyMenuAnchorEl(null);
  };

  const copyToClipboard = data => {
    const formatted = formatSkinData(data);

    navigator.clipboard
      .writeText(
        `localStorage.setItem(
                "${OFFICE_THEME_OVERRIDE_LOCAL_STORAGE}",
                JSON.stringify(${JSON.stringify({
                  skinSettings: formatted
                })})
            )`
      )
      .then(() => {
        closeCopyMenu();
      });
  };

  const copyToClipboardWithMetrics = data => {
    const formatted = formatSkinData(data);
    const dataWithDemoOverrides = cloneDeep(formatted);
    dataWithDemoOverrides.evApp.demoOverrides = demoUserData;

    navigator.clipboard
      .writeText(
        `localStorage.setItem(
                "${OFFICE_THEME_OVERRIDE_LOCAL_STORAGE}",
                JSON.stringify(${JSON.stringify({
                  skinSettings: dataWithDemoOverrides
                })})
            )`
      )
      .then(() => {
        closeCopyMenu();
      });
  };

  const openPreviewModal = () => {
    setPreviewModalOpen(true);
  };

  const closePreviewModal = () => {
    setPreviewModalOpen(false);
  };

  const setSkinPreview = data => {
    const formatted = formatSkinData(data);

    localStorage.setItem(
      OFFICE_THEME_OVERRIDE_LOCAL_STORAGE,
      JSON.stringify({
        skinSettings: formatted
      })
    );
    localStorage.setItem(SKIN_PREVIEW_LOCAL_STORAGE, true);
    window.location.reload();
  };

  const openResetModal = () => {
    setResetModalOpen(true);
  };

  const closeResetModal = () => {
    setResetModalOpen(false);
  };

  const closeAutomationAdoptionConfig = () => {
    setAutomationAdoptionConfig(false);
  };
  const openAutomationAdoptionConfig = () => {
    setAutomationAdoptionConfig(true);
  };

  const updatedAutomationFeaturesInputs = useMemo(
    () => automationFeaturesInputs(openAutomationAdoptionConfig),
    [automationAdoptionConfig]
  );

  const handleSubmitHandler = data => {
    const formatted = formatSkinData(data);

    setIsPublishing(true);
    setPublishError(null);

    const mutationParams = {
      updateSettings: formatted,
      updateType: 'OVERWRITE'
    };

    return createOrUpdateSkinSettings({
      variables: {
        ...mutationParams
      }
    })
      .then(() => {
        setIsPublishing(false);
        setPublishError(null);
        clearSkinPreview();
        closePublishModal();
        window.location.href += '?success=true'; // Need to save success state to show success snackbar on reload
        window.location.reload();
      })
      .catch(error => {
        Logger.error('Error publishing skin settings ', error);
        setIsPublishing(false);
        setPublishError(<Trans i18nKey="adminSkinSettings:publish.error" />);
      });
  };

  return (
    <>
      <Heading
        title={t('adminSkinSettings:mainPage.pageHeading')}
        subTitle={t('adminSkinSettings:mainPage.pageSubHeading')}
        pageTitle={t('adminSkinSettings:mainPage.pageHeading')}
      />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Accordion>
            <SkinSettingHeading title={skinSections.cname()} />
            <AccordionDetails>
              <CnameSettings />
            </AccordionDetails>
            <Divider />
          </Accordion>

          <form autoComplete="off">
            <Accordion>
              <SkinSettingHeading
                title={skinSections.palette()}
                error={headerInErrorState({
                  formErrors,
                  formMeta,
                  submitFailed,
                  key: 'palette'
                })}
              />
              <AccordionDetails>
                <FormSection name="palette">
                  <DynamicForm inputs={paletteSkinInputs()} />
                </FormSection>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <SkinSettingHeading
                title={skinSections.fonts()}
                error={headerInErrorState({
                  formErrors,
                  formMeta,
                  submitFailed,
                  key: 'fonts'
                })}
              />
              <AccordionDetails>
                <FormSection name="fonts">
                  <DynamicForm inputs={typographySkinInputs(fontInputMethod)} />
                </FormSection>
              </AccordionDetails>
              <Divider />
            </Accordion>

            {/* Note: We are setting FormSection component to a fragment as a div cause thing to render improperly */}
            <FormSection name="evApp" component={Fragment}>
              {Object.keys(evAppSkinInputs()).map(key => {
                return (
                  <Accordion key={key}>
                    <SkinSettingHeading
                      title={skinSections[key]()}
                      error={headerInErrorState({
                        formErrors,
                        formMeta,
                        submitFailed,
                        key: `evApp.${key}`
                      })}
                    />
                    <AccordionDetails>
                      <FormSection name={key}>
                        <DynamicForm inputs={evAppSkinInputs()[key]} />
                      </FormSection>
                    </AccordionDetails>
                    <Divider />
                  </Accordion>
                );
              })}
            </FormSection>

            <Accordion data-cy="skin-features">
              <SkinSettingHeading
                title={skinSections.features()}
                error={headerInErrorState({
                  formErrors,
                  formMeta,
                  submitFailed,
                  key: 'evApp.features'
                })}
              />
              <AccordionDetails>
                <FormSection name="evApp.features">
                  <DynamicForm inputs={featuresInputs()} />

                  <br />
                  <Typography variant="subtitle1">
                    <Trans i18nKey="adminSkinSettings:sections.featuresAutomations" />
                  </Typography>
                  <Divider />

                  <DynamicForm inputs={updatedAutomationFeaturesInputs} />

                  <br />
                  <Typography variant="subtitle1">
                    <Trans i18nKey="adminSkinSettings:sections.experiments" />
                  </Typography>
                  <Divider />
                  <Typography variant="subtitle2">
                    <Trans i18nKey="adminSkinSettings:sections.experimentsLinkText" />
                  </Typography>
                  <List>
                    {isEmpty(experimentsInputs) ? (
                      <ListItem alignItems="flex-start">
                        <ListItemText
                          primary={
                            <Trans i18nKey="adminSkinSettings:sections.experimentsEmpty" />
                          }
                        />
                      </ListItem>
                    ) : (
                      experimentsInputs.map(input => {
                        return (
                          <ListItem alignItems="flex-start">
                            <ListItemAvatar>
                              <Avatar>UIX</Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={input.displayName}
                              secondary={input.name}
                            />
                          </ListItem>
                        );
                      })
                    )}
                  </List>
                  <DynamicForm inputs={experimentsInputs} />
                </FormSection>
                <FormSection name="evApp.featureSettings">
                  <ConfigureAutomationAdoptionModal
                    formName={FORM_NAME}
                    closeModal={closeAutomationAdoptionConfig}
                    isOpen={automationAdoptionConfig}
                  />
                </FormSection>
              </AccordionDetails>
              <Divider />
            </Accordion>

            <Accordion data-cy="skin-assets">
              <SkinSettingHeading
                title={skinSections.assets()}
                error={headerInErrorState({
                  formErrors,
                  formMeta,
                  submitFailed,
                  key: 'evAssets'
                })}
              />
              <AccordionDetails>
                <FormSection name="evAssets">
                  <DynamicForm inputs={evAssetsSkinInputs()} />

                  <br />
                  <Typography variant="subtitle1">
                    <Trans i18nKey="adminSkinSettings:mainPage.quickstartHeading" />
                  </Typography>
                  <Divider />

                  <DynamicForm inputs={evAssetsQuickstarts()} />
                </FormSection>
              </AccordionDetails>
              <Divider />
            </Accordion>

            <Accordion>
              <SkinSettingHeading
                title={skinSections.size()}
                error={headerInErrorState({
                  formErrors,
                  formMeta,
                  submitFailed,
                  key: 'evSize'
                })}
              />
              <AccordionDetails>
                <FormSection name="evSize">
                  <DynamicForm inputs={evSizesSkinInputs()} />
                </FormSection>
              </AccordionDetails>
              <Divider />
            </Accordion>

            <div className={classes.buttonContainer}>
              <Button
                data-cy="skin-publish-button"
                className={classes.footerButton}
                color="primary"
                variant="contained"
                onClick={openPublishModal}
              >
                <Trans i18nKey="adminSkinSettings:mainPage.publish" />
              </Button>

              <Button
                className={classes.footerButton}
                color="secondary"
                onClick={openCopyMenu}
                variant="outlined"
              >
                <Trans i18nKey="adminSkinSettings:mainPage.copyToClipboard" />
              </Button>

              <Menu
                id="copy-to-clip-menu"
                anchorEl={copyMenuAnchorEl}
                keepMounted
                open={Boolean(copyMenuAnchorEl)}
                onClose={closeCopyMenu}
              >
                <MenuItem onClick={handleSubmit(copyToClipboard)}>
                  Skin
                </MenuItem>
                <MenuItem onClick={handleSubmit(copyToClipboardWithMetrics)}>
                  Skin + Sample Data
                </MenuItem>
              </Menu>

              <Button
                className={classes.footerButton}
                color="secondary"
                onClick={openPreviewModal}
                variant="outlined"
              >
                <Trans i18nKey="adminSkinSettings:mainPage.skinPreview" />
              </Button>

              <Button color="primary" onClick={openResetModal}>
                <Trans i18nKey="adminSkinSettings:mainPage.reset" />
              </Button>
            </div>
          </form>
        </Grid>
        <AdminSkinPublishModal
          open={publishModalOpen}
          close={closePublishModal}
          submit={handleSubmit(handleSubmitHandler)}
          diff={diff}
          loading={isPublishing}
          error={publishError}
        />
        <AdminSkinPreviewModal
          open={previewModalOpen}
          close={closePreviewModal}
          submit={handleSubmit(setSkinPreview)}
        />
        <AdminSkinResetModal open={resetModalOpen} close={closeResetModal} />
      </Grid>
    </>
  );
};

function mapStateToProps(state, ownProps) {
  const { appSettings } = ownProps;
  const inSkinPreviewMode = localStorage.getItem(SKIN_PREVIEW_LOCAL_STORAGE);
  const formValues = getFormValues(FORM_NAME)(state);
  const officeThemeWithOverrides = appSettings?.rawSkinSettingsWithOverrides;
  const officeTheme = appSettings?.rawSkinSettings || {};

  const initialValues = inSkinPreviewMode
    ? officeThemeWithOverrides
    : officeTheme;

  const fontInputMethod =
    formValues?.fonts?.fontInputMethod || FONT_INPUT_METHOD.selectDefault;

  const initialFontValues = formatInitialFontValues(initialValues);

  return {
    fontInputMethod,
    inSkinPreviewMode,
    officeTheme,
    initialValues: {
      ...initialValues,
      evApp: {
        ...initialValues.evApp,
        // this set's feature defaults and converts from old feature name to new feature names
        features: getFeaturesFromSettings(initialValues?.evApp?.features),
        featureSettings: {
          ...appSettings.app.featureSettings
        }
      },
      fonts: initialFontValues
    },
    formErrors: getFormSyncErrors(FORM_NAME)(state),
    formMeta: getFormMeta(FORM_NAME)(state)
  };
}

export default flow(
  reduxForm({
    enableReinitialize: true,
    form: FORM_NAME
  }),
  connect(mapStateToProps),
  graphql(createOrUpdateSkinSettings, {
    name: 'createOrUpdateSkinSettings'
  }),
  withStyles(styles),
  withAppSettings
)(AdminSkinSettings);
