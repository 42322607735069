import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { t } from 'i18next';

import { Box, Alert } from '@mui/material';
import { styled } from '@mui/system';

import {
  fetchEmbeddedLink,
  fetchEmbeddedLinkPublic
} from 'src/components/Workato/queries';
import WorkatoConnectSkeleton from 'src/components/Workato/WorkatoConnectSkeleton';
import { isFunction } from 'lodash';

const WorkatoIframe = styled('iframe')<{ isLoading: boolean }>(
  ({ isLoading }) => ({
    border: 'none',
    display: isLoading ? 'none' : 'block'
  })
);

interface WorkatoConnectProps {
  onSuccess: (connected: boolean) => void | Promise<void>;
  onError?: () => void | Promise<void>;
  isPublic?: {
    fqdn: string;
    externalId: string; // for plg this will be the user email
  };
}

const WorkatoConnect = ({
  onSuccess,
  onError,
  isPublic
}: WorkatoConnectProps) => {
  const [workatoIframeUrl, setWorkatoIframeUrl] = useState<string | undefined>(
    undefined
  );
  const [iframeHeight, setIframeHeight] = useState(400);
  const [workatoError, setWorkatoError] = useState<string | null | undefined>(
    null
  );
  const [loading, setLoading] = useState(true);

  const [getEmbeddedLinkPrivate] = useLazyQuery(fetchEmbeddedLink);
  const [getEmbeddedLinkPublic] = useLazyQuery(fetchEmbeddedLinkPublic);

  const getEmbeddedLink = async () => {
    try {
      if (isPublic) {
        if (isPublic.externalId && isPublic.fqdn) {
          const publicResponse = await getEmbeddedLinkPublic({
            fetchPolicy: 'no-cache',
            variables: {
              fqdn: isPublic.fqdn,
              externalId: isPublic.externalId
            }
          });
          // set the iframe url
          setWorkatoIframeUrl(
            publicResponse?.data?.public?.fetchEmbeddedLinkPublic?.link ||
              undefined
          );
          setLoading(false);
        } else {
          setWorkatoError(t('common:workato.error.linkFetchError'));
        }
        return;
      }

      const privateResponse = await getEmbeddedLinkPrivate({
        fetchPolicy: 'no-cache'
      });
      // set the iframe url
      setWorkatoIframeUrl(
        privateResponse?.data?.fetchEmbeddedLink?.link || undefined
      );
      setLoading(false);
    } catch (error) {
      // Todo: instrument error
      setWorkatoError(t('common:workato.error.linkFetchError'));
      setLoading(false);
    }
  };

  // onMount
  useEffect(() => {
    const handleMessage = (event: any) => {
      const data =
        typeof event.data === 'string' ? JSON.parse(event.data) : event.data;

      if (!event.origin.includes('workato')) {
        // only accept messages from workato
        return;
      }
      setWorkatoError(undefined);

      const type = data?.type;

      if (type === 'connectionStatusChange') {
        const connected = data?.payload?.connected;

        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        onSuccess(connected);
      }

      if (type === 'heightChange') {
        const height = data?.payload?.height;

        setIframeHeight(height);
      }

      if (type === 'error') {
        // the error does not report the correct hight for the iframe so setting it here
        setIframeHeight(400);
        setWorkatoError(t('common:workato.error.iframeError'));
        setLoading(false);

        if (isFunction(onError)) {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          onError();
        }
      }
    };

    // Note: if we are going to have more than one of these on a page we need
    //       to make sure we are only listening to the correct iframe

    // setup iframe listener
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
    if (!workatoIframeUrl) {
      setLoading(true);
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      getEmbeddedLink();
    }
  }, [workatoIframeUrl]);

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
      }}
    >
      {workatoError && (
        <Alert
          severity="error"
          sx={{
            marginBottom: theme => theme.spacing(2),
            marginLeft: theme => theme.spacing(2)
          }}
        >
          {workatoError}
        </Alert>
      )}
      {loading && !workatoIframeUrl ? (
        <WorkatoConnectSkeleton />
      ) : (
        <>
          <WorkatoIframe
            src={workatoIframeUrl}
            width={350}
            height={iframeHeight}
            id="workatoId"
            isLoading={false}
          />
        </>
      )}
    </Box>
  );
};

export default WorkatoConnect;
