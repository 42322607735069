import { useCallback, useEffect, useState } from 'react';
import { Button, Grid, Alert, Box, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { FormSection } from 'src/components/ReduxForm';
import { useWatch, useFieldArray, useFormContext } from 'react-hook-form';
import { t } from 'i18next';
import { debounce, isEmpty, keys } from 'lodash';
import Instrumentation from 'src/instrumentation';

import { useAppState } from 'src/AppStateProvider';
import { ConditionalInputsVisibility } from 'src/common/conditionals';

import SelectLocationsModal from './SelectLocationsModal';
import LocationManagementForm from './LocationManagementForm';
import {
  LOCATIONS_OVERRIDES_BY_ID_NAME,
  SELECTED_LOCATIONS_FIELD_NAME
} from './utils';
import EditDefaultsButton from './EditDefaultsButton';
import Billing from './Billing';
import useProgram from '../../utils/useProgram';

interface LocationManagementProps {
  isChannelValidationLoading: boolean;
  selectedBusinessObjects: any;
  selectedLocationsMetadata: any;
  conditionalInputsVisibility: ConditionalInputsVisibility;
}

const LocationManagment = ({
  isChannelValidationLoading,
  selectedBusinessObjects,
  selectedLocationsMetadata,
  conditionalInputsVisibility
}: LocationManagementProps) => {
  const theme = useTheme();
  const formContext = useFormContext();
  const {
    setSelectedLocation,
    selectedLocation,
    previewDrawerOpen,
    trackingData
  } = useProgram();
  const { sideNavOpen } = useAppState();

  const [windowDimensions, setWindowDimensions] = useState<{ width: number }>({
    width: window.innerWidth
  });

  const {
    evSizes: { navigationWidth, previewDrawerWidth }
  } = theme;

  // side nav: 250 | preview: 500 | padding: 72 | misc padding/margins: 100
  let windowWidthOffset = 172; // sideNav and previewDrawer are closed

  if (sideNavOpen) {
    windowWidthOffset += navigationWidth;
  }
  if (previewDrawerOpen) {
    windowWidthOffset += previewDrawerWidth;
  }

  const tableContainerWidth = windowDimensions?.width - windowWidthOffset;

  useEffect(() => {
    const handleResize = debounce(() => {
      setWindowDimensions({
        width: window.innerWidth
      });
    }, 200);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const selectedLocations: string[] = useWatch({
    name: SELECTED_LOCATIONS_FIELD_NAME,
    defaultValue: []
  });

  const locationsOverrideById = useWatch({
    name: LOCATIONS_OVERRIDES_BY_ID_NAME,
    defaultValue: {}
  });

  const { replace } = useFieldArray({
    name: SELECTED_LOCATIONS_FIELD_NAME
  });

  const setSelectedLocations = (ids: string[]) => {
    replace(ids);
  };

  const removeOverrideById = (id: string) => {
    // Remove location override if it exists
    const newOverrides = { ...locationsOverrideById };
    delete newOverrides[id];

    formContext.setValue(LOCATIONS_OVERRIDES_BY_ID_NAME, newOverrides);
  };

  const restoreToDefaults = (id: string) => {
    removeOverrideById(id);
  };

  const removeLocation = (id: string) => {
    // Remove selected location id from the list
    const newLocations = selectedLocations.filter(
      locationId => locationId !== id
    );
    setSelectedLocations(newLocations);

    removeOverrideById(id);

    // If we're removing the location corresponding to the currently displayed ad preview
    // default back to the defaults!
    if (selectedLocation === id) {
      setSelectedLocation(undefined);
    }
  };

  const showAdPreview = (id: string) => {
    setSelectedLocation(id);
  };

  const actions = {
    button: 'button',
    step: 'step'
  } as const;

  type ActionKeys = keyof typeof actions;
  type Action = (typeof actions)[ActionKeys];

  const logClickMlpModifyLocationsEvent = (action: Action) => {
    Instrumentation.logEvent(Instrumentation.Events.ClickMlpModifyLocations, {
      ...trackingData,
      action
    });
  };

  const [selectLocationsModalOpen, setSelectLocationsModalOpen] = useState(
    () => {
      const noSelectedLocations = isEmpty(selectedLocations);

      if (noSelectedLocations) {
        logClickMlpModifyLocationsEvent(actions.step);
      }

      return noSelectedLocations;
    }
  );

  const closeSelectLocationsModal = useCallback(() => {
    setSelectLocationsModalOpen(false);
  }, [setSelectLocationsModalOpen]);

  const openSelectLocationsModal = useCallback(() => {
    setSelectLocationsModalOpen(true);
  }, [setSelectLocationsModalOpen]);

  const { formState } = formContext;
  const locationErrors = formState.errors[LOCATIONS_OVERRIDES_BY_ID_NAME];
  const selectedLocationsError = formState?.errors?.selectedLocations;

  useEffect(() => {
    if (locationErrors) {
      const count = keys(locationErrors).length;
      if (selectedLocations.length === count) {
        // All locations have errors
        return formContext.setError('selectedLocations', {
          type: 'locationsInError',
          message: t('programCreate:manageMultiLocation.locationTableAllErrors')
        });
      }

      // some locations have errors
      return formContext.setError('selectedLocations', {
        type: 'locationsInError',
        message: t(
          'programCreate:manageMultiLocation.locationTableManyErrors',
          { count }
        )
      });
    }

    // no location selected
    if (selectedLocations.length <= 0) {
      return formContext.setError('selectedLocations', {
        type: 'locationsInError',
        message: t('programCreate:manageMultiLocation.emptyLocationsTable')
      });
    }

    // no errors
    formContext.clearErrors('selectedLocations');
  }, [selectedLocations, locationErrors]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {selectedLocationsError && (
            <Alert
              severity="error"
              sx={{ marginBottom: theme => theme.spacing() }}
            >
              {selectedLocationsError && selectedLocationsError?.message}
            </Alert>
          )}
          <FormSection
            title={t('programCreate:manageMultiLocation.header')}
            description={t('programCreate:manageMultiLocation.subheader')}
            action={
              <>
                <EditDefaultsButton>
                  {t('programCreate:manageMultiLocation.headerActionEdit')}
                </EditDefaultsButton>
                <Button
                  startIcon={<AddIcon />}
                  onClick={() => {
                    logClickMlpModifyLocationsEvent(actions.button);
                    openSelectLocationsModal();
                  }}
                  color="primary"
                  data-cy="location-management-modify-locations-button"
                >
                  {t('programCreate:manageMultiLocation.headerActionLocations')}
                </Button>
              </>
            }
          >
            <SelectLocationsModal
              open={selectLocationsModalOpen}
              closeSelectLocationsModal={closeSelectLocationsModal}
              setSelectedLocations={setSelectedLocations}
              selectedLocations={selectedLocations}
            />

            <Box
              sx={{
                width: `${tableContainerWidth}px`,
                height: '430px',
                overflow: 'hidden',
                transition: theme =>
                  theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen
                  })
              }}
              data-cy="location-management-form"
            >
              {selectedLocations.length > 0 ? (
                <LocationManagementForm
                  selectedLocations={selectedLocations}
                  removeLocation={removeLocation}
                  restoreToDefaults={restoreToDefaults}
                  showAdPreview={showAdPreview}
                  isChannelValidationLoading={isChannelValidationLoading}
                  selectedBusinessObjects={selectedBusinessObjects}
                  selectedLocationsMetadata={selectedLocationsMetadata}
                  conditionalInputsVisibility={conditionalInputsVisibility}
                />
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%'
                  }}
                >
                  <Button
                    startIcon={<AddIcon />}
                    onClick={openSelectLocationsModal}
                    color="error"
                  >
                    {t(
                      'programCreate:manageMultiLocation.missingLocationButton'
                    )}
                  </Button>
                </Box>
              )}
            </Box>
          </FormSection>
        </Grid>

        <Grid item xs={12}>
          <Billing />
        </Grid>
      </Grid>
    </>
  );
};

export default LocationManagment;
