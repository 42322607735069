import { gql } from 'src/generated/gql';

export const deleteAsset = gql(`
  mutation DeleteAudienceAsset($galleryId: ID!, $assetId: ID!) {
    deleteAsset(galleryId: $galleryId, assetId: $assetId)
  }
`);

export const uploadAudience = gql(`
  mutation uploadAudience($input: UploadAudienceInput!, $file: Upload) {
    uploadAudience(input: $input, file: $file) {
      id
    }
  }
`);
