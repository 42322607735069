import { useRef, useCallback, useState, useEffect } from 'react';
import { isEmpty, isFunction, find, forEach } from 'lodash';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useFormContext } from 'react-hook-form';
import { t } from 'i18next';

import { filterOutConditionalInputs } from 'src/common/conditionals';
import { hasCatalog } from 'src/common/blueprints';

import { startBulkAdPreviewCreation as startBulkAdPreviewCreationMutation } from 'src/components/AdPreview/mutations';
import { getBulkPreviewJob } from 'src/components/AdPreview/queries';
import {
  POLLING_RATE,
  MAX_POLLING_LIMIT,
  MAX_POLLING_INTERVAL
} from 'src/components/AdPreview/Constants';
import {
  findAndSetInputErrors,
  getInputIdsAndValues,
  getSelectedLocationsAndOverrides,
  mapInputFieldsById,
  ValidationErrors
} from 'src/components/AdPreview/helpers';
import { useSnackbar } from 'notistack';
import { getAdPreviewSetErrors } from 'src/common/adPreviewSet';

interface UseBulkValidationProps {
  setIsPollingPreview?: (isPolling: boolean) => void;
  setIsLoadingAd?: (isLoading: boolean) => void;
  isPollingPreviewExternal?: boolean;
  conditionalInputsVisibility: any;
  architecture: any;
  setIsValidatingCreative: (isValidating: boolean) => void;
  previewData: any;
  selectedLocation?: string;
  setCreativeValidationErrors?: (errors: Record<string, any> | null) => void;
  isValidation?: boolean;
}

export const ValidationErrorTypes = {
  CREATIVE_VALIDATION: 'creative validation errors',
  INPUT_VALIDATION: 'input validation errors'
};

const useBulkValidations = (props: UseBulkValidationProps) => {
  const {
    setIsPollingPreview,
    setIsLoadingAd,
    isPollingPreviewExternal,
    conditionalInputsVisibility,
    architecture,
    setIsValidatingCreative,
    previewData,
    selectedLocation,
    setCreativeValidationErrors
  } = props;
  const { setError, clearErrors } = useFormContext();
  const hasCreatedInitialAdPreview = useRef(false);

  const {
    blueprint,
    dynamicUserInputs,
    businessObjects,
    locationsOverrideById,
    selectedLocations
  } = previewData;

  const architectureHasCatalog = hasCatalog(architecture, blueprint);

  // Map input fields by id once so we don't have to loop within a loop each time to find an input.
  const productInputFieldsById = mapInputFieldsById(blueprint);

  const selectedLocationsAndOverrides = getSelectedLocationsAndOverrides({
    selectedLocations,
    locationsOverrideById,
    conditionalInputsVisibility,
    productInputFieldsById
  });

  const selectedBusinessObjectIds = businessObjects.map(
    (businessObject: { id: string }) => {
      return businessObject?.id;
    }
  );

  // to ensure we render the right preview we need to remove the conditionally rendered inputs
  // so the preview data that is returned is accurate to what we expect to see
  const filteredDynamicUserInputs = conditionalInputsVisibility
    ? filterOutConditionalInputs(
        conditionalInputsVisibility,
        dynamicUserInputs || []
      )
    : dynamicUserInputs;

  const inputIdsAndValues = getInputIdsAndValues({
    filteredDynamicUserInputs,
    productInputFieldsById
  });

  const requests = [
    find(selectedLocationsAndOverrides, {
      locationId: selectedLocation
    }) || { locationId: null }
  ];

  const [adPreviewError, setAdPreviewError] = useState<string | null>(null);
  const [adPreviews, setAdPreviews] = <any>useState(null);
  const [hasLoadedFirstPreview, setHasLoadedFirstPreview] = useState(false);
  const [haveInputsChanged, setHaveInputsChanged] = useState(false);
  const [lastMutationData, setLastMutationData] = <any>useState(null);
  const abortController = useRef(new AbortController());
  const timeoutID = <any>useRef(null);
  const [startBulkAdPreviews] = useMutation(startBulkAdPreviewCreationMutation);
  const { enqueueSnackbar } = useSnackbar();
  const [maxPollingLimit, setMaxPollingLimit] =
    useState<number>(MAX_POLLING_LIMIT);
  const validationPromise = useRef<{
    resolve: (value?: any) => void;
    reject: (reason?: any) => void;
  } | null>(null);

  const resolveValidationPromise = (value?: any) => {
    if (validationPromise.current?.resolve) {
      validationPromise.current.resolve(value);
    }
  };

  const rejectValidationPromise = (error?: any) => {
    if (validationPromise.current?.reject) {
      validationPromise.current.reject(error);
    }
  };

  // For some use cases, we need to raise isPolling/setIsPolling state up to the parent component
  // this logic allows us to switch between internal isPolling and external isPolling
  // based on whether or not an external setter/state is present
  const [internalIsPollingPreview, internalSetIsPollingPreview] =
    useState(false);

  const pollingPreview = isPollingPreviewExternal || internalIsPollingPreview;

  const handleSetPollingPreview = useCallback(
    isPolling => {
      if (isFunction(setIsLoadingAd)) {
        setIsLoadingAd(isPolling);
      }
      if (setIsPollingPreview && isFunction(setIsPollingPreview)) {
        setIsPollingPreview(isPolling);
      } else {
        internalSetIsPollingPreview(isPolling);
      }
    },
    [setIsLoadingAd, setIsPollingPreview]
  );

  const [
    fetchGeneratedAdPreview,
    { loading: loadingAdPreviewSet, refetch: refetchAdPreviewSet }
  ] = useLazyQuery(getBulkPreviewJob, {
    context: { fetchOptions: { signal: abortController?.current?.signal } }
  });

  const abortPollingQuery = useCallback(() => {
    abortController.current.abort();
    abortController.current = new AbortController();
  }, [abortController]);

  const onStopPolling = useCallback(() => {
    if (timeoutID.current) {
      clearTimeout(timeoutID.current);
      timeoutID.current = null;
    }

    handleSetPollingPreview(false);

    // reset our polling limit to the default
    if (maxPollingLimit > MAX_POLLING_LIMIT) {
      setMaxPollingLimit(MAX_POLLING_LIMIT);
    }

    if (loadingAdPreviewSet) {
      abortPollingQuery();
    }
  }, [handleSetPollingPreview, abortPollingQuery, loadingAdPreviewSet]);

  const onPollingError = useCallback(
    (error, isSubmit = false) => {
      rejectValidationPromise(error);

      if (isFunction(setIsValidatingCreative)) {
        setIsValidatingCreative(false);
      }
      onStopPolling();
      if (!isSubmit) {
        setAdPreviewError(t('adPreview:pollingError'));
      }
    },
    [onStopPolling, setIsValidatingCreative, validationPromise.current]
  );

  const checkForAdPreviewCompletion = useCallback(
    async (jobId, pollingNum = 1) => {
      try {
        const status =
          pollingNum === 1
            ? await fetchGeneratedAdPreview({
                variables: { jobId }
              })
            : await refetchAdPreviewSet();

        const bulkPreviewJob = status?.data?.getBulkPreviewJob;
        const adPreviews = bulkPreviewJob?.previewSets;

        // previewSets can come back as empty array and that does not mean complete
        const allPreviewsComplete =
          !isEmpty(adPreviews) &&
          adPreviews?.every(preview => {
            return preview?.status === 'complete';
          });

        const previewHasError = adPreviews?.some(preview => {
          return preview?.status === 'error';
        });

        if (allPreviewsComplete) {
          setAdPreviews(adPreviews);

          const allErrors: ValidationErrors = [];

          const creativeErrors = getAdPreviewSetErrors(adPreviews as any[]);

          if (isFunction(setCreativeValidationErrors)) {
            setCreativeValidationErrors(creativeErrors);
          }

          if (creativeErrors) {
            forEach(creativeErrors, (_, locationId) => {
              allErrors.push({
                locationId,
                error: {
                  type: ValidationErrorTypes.CREATIVE_VALIDATION,
                  message: ValidationErrorTypes.CREATIVE_VALIDATION
                }
              });
            });
          }

          adPreviews?.forEach(preview => {
            const locationId = preview?.locationId;
            allErrors.push(
              ...findAndSetInputErrors({
                productInputFieldsById,
                setError,
                clearErrors,
                inputValidationErrors: preview?.inputFieldValidationErrors,
                ...(locationId && { locationId }),
                conditionalInputsVisibility
              })
            );
          });

          resolveValidationPromise(allErrors);

          if (!hasLoadedFirstPreview) {
            setHasLoadedFirstPreview(true);
          }

          onStopPolling();
          setIsValidatingCreative(false);
          setAdPreviewError(null);
          return;
        }

        if (previewHasError || pollingNum >= maxPollingLimit) {
          onPollingError(
            pollingNum >= maxPollingLimit
              ? new Error('max polling limit exceeded')
              : new Error('preview has error')
          );
        } else {
          const pollingRate = POLLING_RATE + pollingNum * (POLLING_RATE / 4);
          timeoutID.current = setTimeout(
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            () => checkForAdPreviewCompletion(jobId, pollingNum + 1),
            pollingRate > MAX_POLLING_INTERVAL
              ? MAX_POLLING_INTERVAL
              : pollingRate
          );
        }
      } catch (error) {
        onPollingError(error);
      }
    },
    [
      fetchGeneratedAdPreview,
      hasLoadedFirstPreview,
      onPollingError,
      onStopPolling,
      refetchAdPreviewSet
    ]
  );

  const createUpdateAdPreviews = useCallback(
    async (isSubmit = false) => {
      // Check if the method is currently executing, in this case we need to avoid calling createAdPreviews
      handleSetPollingPreview(true);

      // on submit we want to validate all locations vs just one
      const requestsAll = isEmpty(selectedLocationsAndOverrides)
        ? [{ locationId: null }]
        : selectedLocationsAndOverrides;

      setAdPreviewError(null);

      const mutationParams = {
        variableValues: filteredDynamicUserInputs,
        productId: blueprint?.id,
        // If orderId is present on the request then it will create an iframe preview (if possible)
        // we only want to do this if we can show the iframe preview.
        //   orderId: canShowIframePreview() ? previewData.orderId : undefined,
        ...(!isEmpty(selectedBusinessObjectIds) &&
          architectureHasCatalog && {
            catalogId: architecture?.catalog?.id
          }),
        ...(!isEmpty(businessObjects) &&
          architectureHasCatalog && {
            catalogFilter: {
              id: {
                in: selectedBusinessObjectIds
              }
            }
          }),
        shouldRunValidations: isSubmit || hasCreatedInitialAdPreview.current,
        // The location object with locationId:null is used to generate preview data for the default values (which technically has no location)
        requests: isSubmit ? requestsAll : requests,
        formValues: inputIdsAndValues,
        ignoreMissingInterpolationValues: !isSubmit
      };

      // set current state of inputs so we know what was used in the most recent call
      setLastMutationData({
        inputs: filteredDynamicUserInputs,
        selectedBusinessObjectIds,
        blueprintId: blueprint?.id,
        conditionalInputsVisibility,
        requests: isSubmit ? requestsAll : requests
      });
      setHaveInputsChanged(false);

      // create / update ad preview
      try {
        const response = await startBulkAdPreviews({
          variables: {
            input: mutationParams
          }
        });

        if (hasCreatedInitialAdPreview.current === false) {
          hasCreatedInitialAdPreview.current = true;
        }

        // Clear the previous timeout if it exists
        if (timeoutID.current) {
          clearTimeout(timeoutID.current);
        }

        setAdPreviewError(null);
        // trigger first polling check polling funciton will call itself for subsequent checks
        timeoutID.current = setTimeout(
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          jobId => checkForAdPreviewCompletion(jobId),
          POLLING_RATE,
          response?.data?.startBulkAdPreviewCreation?.jobId
        );
      } catch (error) {
        onPollingError(error, isSubmit);
        if (!isSubmit) {
          setAdPreviewError(t('adPreview:createError'));
        }
        if (isSubmit) {
          enqueueSnackbar(t('programCreate:publish.submitError'), {
            variant: 'error'
          });
        }
      }
    },
    [
      architecture?.catalog?.id,
      architectureHasCatalog,
      blueprint?.id,
      businessObjects,
      checkForAdPreviewCompletion,
      conditionalInputsVisibility,
      startBulkAdPreviews,
      filteredDynamicUserInputs,
      handleSetPollingPreview,
      lastMutationData?.conditionalInputsVisibility,
      previewData.orderId,
      selectedBusinessObjectIds,
      requests,
      selectedLocation
    ]
  );

  const executeSubmitValidations = () =>
    new Promise<ValidationErrors | undefined>((resolve, reject) => {
      // if we are already validating creative we should cancel the current validation
      if (pollingPreview) {
        // this stops polling and this effect will still fire net render cycle
        onStopPolling();
      }
      if (validationPromise.current?.resolve) {
        resolveValidationPromise();
      }

      validationPromise.current = { resolve, reject };

      // big limit 15ish minutes to allow for all locations to be validated
      setMaxPollingLimit(500);
      // we handle the custom promise manually so we can ignore this promise
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      createUpdateAdPreviews(true);
    });

  useEffect(() => {
    if (haveInputsChanged) {
      if (pollingPreview) {
        // this stops polling and this effect will still fire net render cycle
        onStopPolling();
      } else {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        createUpdateAdPreviews();
      }
    }
  }, [
    createUpdateAdPreviews,
    haveInputsChanged,
    onStopPolling,
    pollingPreview
  ]);

  return {
    createUpdateAdPreviews,
    pollingPreview,
    adPreviews,
    haveInputsChanged,
    setHaveInputsChanged,
    adPreviewError,
    timeoutID,
    hasLoadedFirstPreview,
    lastMutationData,
    requests,
    executeSubmitValidations,
    clearErrors
  };
};

export default useBulkValidations;
