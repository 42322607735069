/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useRef, useState, useEffect, ReactNode, FocusEvent } from 'react';
import {
  noop,
  flow,
  xorBy,
  xorWith,
  isEqual,
  debounce,
  camelCase
} from 'lodash';
import { connect } from 'react-redux';
import { change as changeAction, WrappedFieldProps } from 'redux-form';
import {
  ControllerRenderProps,
  FieldValues,
  UseFormReturn
} from 'react-hook-form';
import Draft, {
  CharacterMetadata,
  CompositeDecorator,
  convertFromRaw,
  Editor,
  EditorState,
  Modifier,
  RawDraftContentState,
  SelectionState
} from 'draft-js';

import {
  FormControl,
  FormHelperText,
  InputLabel,
  Tooltip,
  InputAdornment,
  Box
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import HelpIcon from '@mui/icons-material/HelpOutline';
import { isTemplate, translateMaps } from 'src/common/templateTranslator';
import { AiSuggestionIcon } from 'src/components/AiSuggestion/AiSuggestionIcon';
import {
  createHandleApplyAiText,
  createHandleFooterAiTextChange,
  resetAiTextAnalysisField
} from 'src/components/AiSuggestion/utils';
import { EmojiPickerIcon } from 'src/components/EmojiPicker';
import { useIsProgramOrAutomationPage } from 'src/routes/useIsProgramOrAutomationPage';
import { validationAllowsEmoji } from 'src/components/EmojiPicker/helpers';
import { getChangeFormValue } from 'src/common/utilities/inputConversionHelpers';
import {
  AiTemplateTextFieldSx,
  AiTextFooter
} from 'src/components/AiSuggestion/AiTextFooter';
import { Theme } from '@mui/system';
import { ThemeAugmentations } from 'src/AppThemeWrapper';
import { BusinessObject } from 'src/common/businessObjects';
import {
  DynamicValueColumns,
  DynamicValue
} from 'src/components/ReduxForm/commonTypes';
import { DynamicValueSelector } from 'src/components/ReduxForm/TemplateChip/DynamicValueSelector';
import { TokenSpan } from 'src/components/ReduxForm/TemplateChip/TokenSpan';
import { HelperTextFooter } from 'src/components/ReduxForm/HelperTextFooter';

import CharacterCountAdornment from './CharacterCountAdornment';
import AiChatAdCopywriterIcon from '../AiChat/AiChatAdCopywriterIcon';

interface StyleProps {
  variant: string;
  isInAiModal: boolean;
  readOnly: boolean;
  labelBackground: string;
  isHighlighted: boolean;
}

const useStyles = makeStyles((theme: Theme & ThemeAugmentations) => ({
  formControl: ({
    variant,
    isInAiModal,
    readOnly,
    isHighlighted
  }: StyleProps) => {
    const isOutlinedVariant = variant === 'outlined';
    const aiModalStyles = isInAiModal ? AiTemplateTextFieldSx : {};
    return {
      width: '100%',
      marginTop: `0px`,
      marginBottom: 0,
      borderRadius: isOutlinedVariant ? `4px` : 0,
      borderColor: `rgba(0, 0, 0, 0.23)`,
      padding: isOutlinedVariant ? '1px' : '0 0 1px 0',
      borderWidth: '1px',
      ...(isOutlinedVariant
        ? { border: '1px solid' }
        : { borderBottom: '1px solid' }),
      '&:hover': {
        cursor: 'text',
        ...(!readOnly && { borderColor: `rgba(0, 0, 0, 0.87)` })
      },
      '&:focus-within': {
        borderColor: theme.palette.primary.main,
        borderWidth: '2px',
        padding: '0px'
      },
      ...(isHighlighted && {
        borderColor: theme.palette.primary.main,
        borderWidth: '2px',
        padding: '0px'
      }),
      ...aiModalStyles
    };
  },
  editor: {
    padding: ({ variant }) =>
      variant === 'outlined' ? `15px` : '18px 24px 1px 0',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      cursor: 'text'
    },
    // Reach far into the DraftEditor to add in force word breaking.
    // This is specifically to handle the case where a user pastes
    // in a long URL or otherwise types in a super long word (especially
    // on small screens)
    '& .public-DraftEditor-content': {
      overFlowWrap: 'anywhere'
    }
  },
  label: ({ labelBackground }) => {
    return {
      background: labelBackground
    };
  },
  labelShrink: {},
  error: {
    color: theme.palette.error
  },
  helpTip: ({ variant }) => {
    const isOutlinedVariant = variant === 'outlined';

    return {
      color: theme.palette.grey[500],
      position: 'absolute',
      right: '3px',
      zIndex: theme.zIndex.mobileStepper,
      ...(isOutlinedVariant ? { top: '13px' } : { bottom: '16px' })
    };
  },
  helpIcon: {
    width: '20px'
  }
}));

// Updated to match our new handlebars expression introduced with Locations
export const TEMPLATE_TAG_REGEX =
  /{{#with \(LOCATION locationId\)}}({{[^}]+}}){{\/with}}|{{[^}]+}}/g;

export const getTemplateMeta = (
  match: string,
  dynamicValuesMap: Record<string, DynamicValue>
): { fieldName: string; label: string } => {
  // tags can be {{ FILTER tag_name parameter }} or {{tag_name}}
  // and we just need the tag_name
  // we now need to account for tags that look like this {{#with (LOCATION locationId)}} {{name}} {{/with}}

  // remove template brackets & parentheses, trim and split on whitespace
  // The regex is just a set of OR conditions
  let sections = match
    .replace(/{{|}}|\(|\)/g, '')
    .trim()
    .split(' ');

  // After the processing above, if the match is from a location tag, it will look like this after being split:
  // sectionos = ["#with", "LOCATION", "locationId", "name", "/with"]
  const locationTagFieldNameIndex = 2;

  if (sections.includes('LOCATION')) {
    const cleanTagName = sections[locationTagFieldNameIndex]
      ?.replace('locationId', '')
      .replace('/with', '');

    sections = [camelCase(`location ${cleanTagName}`)];
  }

  const tag = sections.filter(w => dynamicValuesMap[w])[0];
  if (!tag) {
    // eslint-disable-next-line no-console
    console.error(
      `the template tag ${match} does not exist in the display parameters`
    );
    // just return the tag so we know something is ammiss?
    return match as any;
  }
  return {
    // we pad label with space which is replaced with spaces in teh chip
    // to deal with draftjs selection bug where it ignores chip padding
    label: ` ${dynamicValuesMap?.[tag]?.label || tag} `,
    fieldName: dynamicValuesMap?.[tag]?.fieldName || tag
  };
};

const createDefaultText = (
  defaultText = '',
  dynamicValuesMap: Record<string, DynamicValue>
): RawDraftContentState => {
  const matches = Array.from(defaultText.matchAll(TEMPLATE_TAG_REGEX));
  let newDefaultText = defaultText;

  const meta = matches.reduce(
    (map, tag, index) => {
      const templateTag = tag[0];
      const { label, fieldName } = getTemplateMeta(
        templateTag,
        dynamicValuesMap
      );

      // round about way of getting index accounts for multilength characters (emoji etc.)
      const offsetIndex = Array.from(
        newDefaultText.split(templateTag)[0]
      ).length;

      const key = `${templateTag}-${index}`;

      const entities = {
        entityRanges: [
          ...map.entityRanges,
          {
            offset: offsetIndex,
            length: label?.length || 0,
            key
          }
        ],
        entityMap: {
          ...map.entityMap,
          [key]: {
            type: 'TAG',
            mutability: 'IMMUTABLE',
            data: {
              tagTemplate: templateTag,
              label,
              fieldName
            }
          }
        }
      };
      newDefaultText = newDefaultText.replace(templateTag, label);
      return entities;
    },
    {
      entityRanges: [] as Draft.RawDraftEntityRange[],
      entityMap: {} as Record<string, Draft.RawDraftEntity>
    } as any
  );

  return {
    blocks: [
      {
        text: newDefaultText,
        type: 'unstyled',
        entityRanges: meta.entityRanges
      } as any
    ],
    entityMap: meta.entityMap
  };
};

const entityStrategy = (
  contentBlock: Draft.ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: Draft.ContentState
) => {
  contentBlock.findEntityRanges((character: CharacterMetadata) => {
    const entityKey = character.getEntity();
    if (entityKey === null) {
      return false;
    }
    return contentState.getEntity(entityKey).getType() === 'TAG';
  }, callback);
};

const maxTranslatedStringLength = (
  value = '',
  businessObjects: BusinessObject[] = [],
  userMetadataFields: Record<string, any>[] = [],
  selectedLocationsMetadata: Record<string, any>[] = []
) => {
  let max = 0;

  businessObjects.forEach(businessObject => {
    if (selectedLocationsMetadata.length > 0) {
      selectedLocationsMetadata.forEach(locationMetadata => {
        const translatedValue = translateMaps(value, {
          ...businessObject,
          ...userMetadataFields,
          ...locationMetadata
        });

        if (translatedValue.length > max) {
          max = translatedValue.length;
        }
      });
    } else {
      const translatedValue = translateMaps(value, {
        ...businessObject,
        ...userMetadataFields
      });

      if (translatedValue.length > max) {
        max = translatedValue.length;
      }
    }
  });

  if (businessObjects.length <= 0) {
    if (isTemplate(value)) {
      if (selectedLocationsMetadata.length > 0) {
        selectedLocationsMetadata.forEach(locationMetadata => {
          const translatedValue = translateMaps(value, {
            ...userMetadataFields,
            ...locationMetadata
          });

          if (translatedValue.length > max) {
            max = translatedValue.length;
          }
        });
      } else {
        const translatedValue = translateMaps(value, {
          ...userMetadataFields
        });

        if (translatedValue.length > max) {
          max = translatedValue.length;
        }
      }
      return max;
    }

    // If no BOs selected and no location metadata, return the raw length
    return value.length;
  }

  return max;
};

const replaceTag = (
  entityKey: string,
  replacementText = '',
  externalEditorState: EditorState
) => {
  const editorState = externalEditorState;

  const contentState = editorState.getCurrentContent();
  let selection;
  let newEditorState = editorState;
  contentState.getBlockMap().forEach(block => {
    block!.findEntityRanges(
      character => {
        if (character.getEntity() !== null) {
          return character.getEntity() === entityKey;
        }
        return false;
      },
      (start, end) => {
        // updating selection with appropriate positions
        selection = SelectionState.createEmpty(block!.getKey()).merge({
          anchorOffset: start,
          focusOffset: end
        });

        const removeEntity = Modifier.applyEntity(
          contentState,
          selection,
          null
        );

        const removeText = Modifier.replaceText(
          removeEntity,
          selection,
          replacementText,
          null as any,
          null as any
        );

        newEditorState = EditorState.push(
          editorState,
          removeText,
          'insert-fragment'
        );
        if (replacementText === '') {
          newEditorState = EditorState.forceSelection(
            newEditorState,
            selection.merge({ focusOffset: start })
          );
        }
      }
    );
  });
  return newEditorState;
};

interface EditorEntity {
  entityKey: string;
  blockKey: string;
  entity: Draft.EntityInstance;
  start: number;
  end: number;
}

export interface RenderTemplateStringTextFieldProps extends WrappedFieldProps {
  children?: ReactNode;
  margin?: 'none' | 'dense' | 'normal';
  variant?: 'standard' | 'filled' | 'outlined';
  businessObjects?: BusinessObject[];
  userMetadataFields?: Record<string, any>[];
  tooltip?: string;
  helperText?: string;
  type?: string;
  dynamicValues: DynamicValueColumns[];
  dynamicValuesMap: Record<string, DynamicValue>;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  transposeValue?: (value: string) => string;
  change: typeof changeAction;
  displayName: string;
  stringMaxLength?: number;
  blueprintVariableId: string;
  validationTypes?: string[];
  missingColumns: Set<string>;
  label: string;
  formName: string;
  productId: string;
  allowAiGeneration?: boolean;
  parentFormName: string;
  parentFieldName: string;
  isInAiModal?: boolean;
  readOnly?: boolean;
  blockEmojiPicker?: boolean;
  aiTextAnalysisValues?: any;
  selectedLocationsMetadata: Record<string, any>[];
  labelBackground?: string;
  isAdCopySuggestionMessageInput?: boolean;
  showAiChatInputIconButton?: boolean;
  aiChat: {
    toggleAiChatWindow?: () => void;
    aiChatOpen?: boolean;
  };
  isHighlighted?: boolean;
  productInputFieldId?: string;
  /**
   * Optionally renders some extra footer content under the input element.
   * This can be useful to customize the template text field per-input-type.
   */
  additionalFooterContent?: (footerProps: WrappedFieldProps) => ReactNode;
  /**
   * Extra onChange notification fired when the input value changes.
   * This should NOT be used for normal change processing! That's handled
   * by input.onChange.
   * Instead, this should be used for things like starting extra type specific
   * validation.
   */
  extraOnChangeNotification?: (value: string) => void;
  isHookForm?: boolean;
  hookFormField?: ControllerRenderProps<FieldValues, string>;
  hookFormContext?: UseFormReturn<FieldValues, any, undefined>;
}

const RenderTemplateStringTextField = (
  props: RenderTemplateStringTextFieldProps
) => {
  const {
    input,
    input: { value = '', onChange },
    meta: { touched, error },
    tooltip,
    transposeValue = noop,
    dynamicValues = [],
    dynamicValuesMap,
    label,
    missingColumns,
    formName,
    businessObjects = [],
    userMetadataFields,
    stringMaxLength,
    allowAiGeneration,
    blueprintVariableId,
    helperText,
    productId,
    validationTypes,
    isInAiModal = false,
    variant = 'outlined',
    change: reduxChange,
    parentFormName,
    parentFieldName,
    aiTextAnalysisValues,
    readOnly = false,
    blockEmojiPicker = false,
    additionalFooterContent,
    extraOnChangeNotification,
    hookFormField,
    hookFormContext,
    isHookForm,
    selectedLocationsMetadata,
    labelBackground = '#FFF',
    isAdCopySuggestionMessageInput = false,
    showAiChatInputIconButton = false,
    aiChat,
    displayName,
    isHighlighted = false
  } = props;

  const [active, setActive] = useState(false);

  const isOnProgramOrAutomationPage = useIsProgramOrAutomationPage();
  const allowEmoji =
    !blockEmojiPicker && validationAllowsEmoji(validationTypes);
  const showEmojiPicker =
    isOnProgramOrAutomationPage &&
    allowEmoji &&
    !isAdCopySuggestionMessageInput;

  const editorRef = useRef<Draft.Editor | null>(null);
  const inputRef = useRef<HTMLDivElement>(null);
  const combinedRef = (node: Draft.Editor) => {
    if (node !== null) {
      editorRef.current = node;

      if (hookFormField?.ref) {
        hookFormField.ref(node);
      }
    }
  };

  const change = getChangeFormValue({
    reduxChange,
    hookSetValue: hookFormContext?.setValue
  });

  const classes = useStyles({
    variant,
    isInAiModal,
    readOnly,
    labelBackground,
    isHighlighted
  });

  // this is workaround where the hook form does not like the debounce and causes this input not to update values correctly in the overrides form specifically
  const debouncedChange = isHookForm ? onChange : debounce(onChange, 200);

  const getEntities = (editorState: EditorState) => {
    const content = editorState.getCurrentContent();
    const entities: EditorEntity[] = [];
    content.getBlocksAsArray().forEach(block => {
      let selectedEntity: Pick<
        EditorEntity,
        'entityKey' | 'blockKey' | 'entity'
      > | null = null;
      block.findEntityRanges(
        character => {
          if (character.getEntity() !== null) {
            const entity = content.getEntity(character.getEntity());
            if (entity.getType() === 'TAG') {
              selectedEntity = {
                entityKey: character.getEntity(),
                blockKey: block.getKey(),
                entity: content.getEntity(character.getEntity())
              };
              return true;
            }
          }
          return false;
        },
        (start, end) => {
          entities.push({ ...selectedEntity!, start, end });
        }
      );
    });
    return entities;
  };

  const [editorState, setEditorState] = useState<EditorState>();
  /**
   * Unfortunately draftJS calls handleEditorChange A LOT including when the
   * content doesn't actually change. Notably, when the editor is focused draftJS
   * will fire an editor change.
   * We sometimes only want to trigger change notifications when the content has
   * meaningfully changed.
   */
  const lastEditorPlainTextValue = useRef<string>();

  const handleEditorChange = (editorState: EditorState) => {
    const entityKeys = getEntities(editorState);
    let newEditorState = editorState;

    // we create a copy of the editor with chips and we replace
    // the chip text with the template tags and save that to redux form
    // and we just pass the original edit (with chips) to the editState
    entityKeys.forEach(entity => {
      newEditorState = replaceTag(
        entity.entityKey,
        entity.entity.getData().tagTemplate,
        newEditorState
      );
    });

    setEditorState(editorState);

    if (
      allowAiGeneration &&
      newEditorState.getCurrentContent().getPlainText().trim() === '' &&
      !!aiTextAnalysisValues
    ) {
      resetAiTextAnalysisField({
        change,
        formName: parentFormName || formName,
        inputName: parentFieldName || input.name
      });
    }

    const editorPlainText = newEditorState.getCurrentContent().getPlainText();

    if (editorPlainText !== lastEditorPlainTextValue.current) {
      extraOnChangeNotification?.(editorPlainText);
      debouncedChange(editorPlainText as any);
    }

    lastEditorPlainTextValue.current = editorPlainText;
  };

  const [businessObjectsLocal, setBusinessObjectsLocal] = useState<
    BusinessObject[]
  >([]);

  const createCompositeDecorator = () => {
    return new CompositeDecorator([
      {
        strategy: entityStrategy,
        component: props => (
          <TokenSpan
            {...props}
            readOnly={readOnly}
            setEditorState={setEditorState}
            handleEditorChange={handleEditorChange}
            missingColumns={missingColumns}
            businessObjects={businessObjects}
          />
        )
      }
    ]);
  };

  const createEditorStateFromText = (value: string) => {
    return EditorState.createWithContent(
      convertFromRaw(
        (createDefaultText as any)(
          `${value.trim()} `,
          dynamicValuesMap,
          missingColumns
        )
      ),
      createCompositeDecorator()
    );
  };

  // initialize the editor state.
  // we do this here becasue we need to access handleEditorChange
  // to be able to handle when we delete chips to make sure the content is in sync
  useEffect(() => {
    // we need to reset the chips if business objects are actually different.
    // and check if they are different from fast to slow (length diff, id exists, object is same)
    let isDiff;
    // first just check if the length is the same
    isDiff = businessObjectsLocal.length !== businessObjects.length;
    if (!isDiff && businessObjects.length > 0) {
      // then check by id or sameness if id doesn't exist;
      if (businessObjects[0]?.id) {
        isDiff = xorBy(businessObjectsLocal, businessObjects, 'id').length > 0;
      } else {
        isDiff = xorWith(businessObjectsLocal, businessObjects, isEqual).length;
      }
    }
    if (editorState && !isDiff) {
      // if we ahve already initialized and the business objects are the same then don't do anything
      return;
    }
    setBusinessObjectsLocal(businessObjects || []);

    // we have to re-initalize the chips here whenever the buiness objects refresh
    // because teh props get stale otherwize and there's not a good way to pass props
    // to children entities of draftjs
    const compositeDecorator = createCompositeDecorator();

    if (editorState) {
      setEditorState(
        EditorState.set(editorState, { decorator: compositeDecorator })
      );
    } else {
      setEditorState(createEditorStateFromText(value));
    }
  }, [businessObjects]);

  const [focused, setFocused] = useState(false);

  useEffect(() => {
    if (value !== undefined && value !== lastEditorPlainTextValue.current) {
      const editorState = createEditorStateFromText(value);
      if (focused) {
        handleEditorChange(EditorState.moveFocusToEnd(editorState));
      } else {
        handleEditorChange(editorState);
      }
    }
  }, [value]);

  const handleAddTag = (tagText: string) => {
    const { label, fieldName } = getTemplateMeta(tagText, dynamicValuesMap);

    const contentState = editorState!.getCurrentContent();
    const selection = editorState!.getSelection();
    const contentStateWithEntity = contentState.createEntity(
      'TAG',
      'IMMUTABLE',
      { tagTemplate: tagText, label, fieldName }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    const contentStateWithSpace = Modifier.replaceText(
      contentStateWithEntity,
      selection,
      ' '
    );

    const contentStateWithNewText = Modifier.replaceText(
      contentStateWithSpace,
      selection,
      label,
      null as any,
      entityKey
    );

    const newEditorState = EditorState.set(editorState!, {
      currentContent: contentStateWithNewText
    });

    const newSelection = new SelectionState({
      anchorKey: selection.getAnchorKey(),
      anchorOffset: selection.getStartOffset() + label.length + 1, // extra space
      focusKey: selection.getFocusKey(),
      focusOffset: 0
    });

    handleEditorChange(
      EditorState.forceSelection(newEditorState, newSelection)
    );
  };

  const handleKeyDown = (key: string) => {
    // {} are for handlebar templates and [] are for ad preview chips so we are not allowing either to be used
    if (key === '{' || key === '}' || key === '[' || key === ']') {
      // handled in draftjs is like preventDefault()
      return 'handled';
    }
  };

  let { length } = value;

  const showCharacterCount = !!stringMaxLength;

  if (showCharacterCount) {
    length = maxTranslatedStringLength(
      value,
      businessObjects,
      userMetadataFields,
      selectedLocationsMetadata
    );
  }

  let localHelperText = transposeValue(input?.value || '');

  const localError = touched && !!error;

  if (localError) {
    localHelperText = error;
  }

  const replaceEditorText = (text: string) => {
    // Force a change to the editor by creating a new state and pretending
    // that change came from the editor itself.
    const editorState = createEditorStateFromText(text);
    handleEditorChange(editorState);
  };

  const handleFooterAiTextChange = createHandleFooterAiTextChange({
    callback: replaceEditorText,
    formName: parentFormName || formName,
    inputName: parentFieldName || input.name,
    change
  });

  const handleApplyAiText = createHandleApplyAiText({
    callback: replaceEditorText,
    formName: parentFormName || formName,
    inputName: parentFieldName || input.name,
    change
  });

  const contentState = editorState?.getCurrentContent();
  // prevent focus when readOnly
  const editorStateFocus = editorState?.getSelection()?.getHasFocus() || false;

  const hasFocus = readOnly ? false : editorStateFocus;
  // we have to handle focus manually since it's not an input element
  if (hasFocus && !active) {
    setActive(true);
  }

  if (!hasFocus && active) {
    setActive(false);
  }

  const selectAllInputValue = () => {
    const textValue = contentState?.getPlainText();
    const selectionState = editorState?.getSelection();

    const newSelectionState = selectionState!.merge({
      anchorKey: contentState!.getFirstBlock().getKey(),
      anchorOffset: 0,
      focusKey: contentState!.getLastBlock().getKey(),
      focusOffset: textValue!.length
    });
    const newEditorState = EditorState.forceSelection(
      editorState!,
      newSelectionState
    );

    handleEditorChange(newEditorState);
  };

  /**
   * Handles adding in a new emoji.
   * Specifically, it replaces the current selection with the emoji.
   * This has to handle cases like reverse selection or no selection.
   * After placing an emoji we need to move the cursor immediately after
   * the emoji.
   */
  const handleEmojiAdded = (emoji: string) => {
    const selection = editorState!.getSelection();
    const currentContent = editorState!.getCurrentContent();

    const textWithEmoji = Modifier.replaceText(
      currentContent,
      selection,
      emoji
    );
    const newEditorState = EditorState.set(editorState!, {
      currentContent: textWithEmoji
    });

    // We want to move the selection to the next character after the emoji
    // Both the focus and anchor offset need to be equal so we don't select
    // any text and have a plain cursor.
    let nextOffSet;
    if ((selection as any).isBackward) {
      // Backwards selection = focus -> anchor
      nextOffSet = selection.getFocusOffset() + emoji.length;
    } else {
      // Forwards selection = anchor -> focus
      nextOffSet = selection.getAnchorOffset() + emoji.length;
    }
    const newSelection = selection.merge({
      focusOffset: nextOffSet,
      anchorOffset: nextOffSet
    });

    handleEditorChange(
      EditorState.acceptSelection(newEditorState, newSelection)
    );
  };

  // We need to initialize our connected component here to prevent linting
  // errors around use-before-define
  const aiTextFieldComponent = useRef(
    flow(
      connect(null, {
        change: changeAction
      })
    )(RenderTemplateStringTextField)
  );

  // A subset of props that represent this text field
  // but without any of the redux related props
  const aiModalTextFieldProps = {
    tooltip,
    transposeValue,
    dynamicValues,
    dynamicValuesMap,
    label,
    missingColumns,
    formName,
    businessObjects,
    userMetadataFields,
    stringMaxLength,
    blueprintVariableId,
    helperText,
    productId,
    validationTypes,
    displayName,
    variant,
    // prevent infinite loops since this is inside the AI generator modal
    allowAiGeneration: false,
    // But since this will be in the modal we need to mark it as such
    isInAiModal: true
  };

  const handleAiChatAdCopywriterClick = () => {
    if (aiChat?.toggleAiChatWindow) {
      aiChat.toggleAiChatWindow();
    }
  };

  const hasLocalHelperText =
    localHelperText !== null && localHelperText !== undefined;

  return (
    <>
      <div ref={inputRef}>
        <div data-cy={`${input.name}-template-string-input`}>
          <FormControl disabled={readOnly} className={classes.formControl}>
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <div
              className={classes.editor}
              onClick={() => {
                editorRef.current!.focus();
                handleEditorChange(EditorState.moveFocusToEnd(editorState!));
              }}
            >
              <InputLabel
                disabled={readOnly}
                className={classes.label}
                error={localError}
                shrink={hasFocus || contentState?.hasText()}
                variant={variant}
                classes={{
                  shrink: classes.labelShrink
                }}
              >
                {label}
              </InputLabel>
              {editorState && (
                // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                <div
                  onClick={event => {
                    if (event.detail === 3) {
                      selectAllInputValue();
                    }
                    event.stopPropagation();
                  }}
                  style={{
                    lineHeight: '1.5',
                    flex: '1 1 auto',
                    ...(readOnly && {
                      color: 'rgba(0, 0, 0, 0.23)'
                    })
                  }}
                  data-cy="template-string-editor-parent"
                >
                  <Editor
                    readOnly={readOnly} // mainly prevents typing in the input
                    ref={combinedRef}
                    editorState={editorState}
                    handleBeforeInput={readOnly ? (noop as any) : handleKeyDown}
                    onChange={readOnly ? noop : handleEditorChange}
                    stripPastedStyles
                    onFocus={(e: FocusEvent<any>) => {
                      if (input?.onFocus) {
                        input.onFocus(e);
                      }
                      setFocused(true);
                    }}
                    onBlur={e => {
                      input.onBlur(e);
                      setFocused(false);
                    }}
                  />
                </div>
              )}
              {tooltip && (
                <InputAdornment className={classes.helpTip} position="end">
                  <Tooltip arrow title={tooltip}>
                    <HelpIcon className={classes.helpIcon} />
                  </Tooltip>
                </InputAdornment>
              )}
              {showCharacterCount && (
                <InputAdornment
                  sx={{ position: 'absolute', right: 3, bottom: 10 }}
                  position="end"
                >
                  <CharacterCountAdornment
                    length={length}
                    stringMaxLength={stringMaxLength}
                  />
                </InputAdornment>
              )}
            </div>
          </FormControl>
        </div>
        {additionalFooterContent?.(props)}
        {isInAiModal && !isAdCopySuggestionMessageInput && (
          <AiTextFooter
            onAiTextChange={handleFooterAiTextChange}
            productId={productId}
            blueprintVariableId={blueprintVariableId}
            catalogItemId={businessObjects[0]?.id}
            currentValue={value}
          />
        )}
        {(allowAiGeneration ||
          showEmojiPicker ||
          helperText ||
          error ||
          localError ||
          showAiChatInputIconButton) &&
          !isAdCopySuggestionMessageInput && (
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                gap: 0.5
              }}
            >
              <Box sx={{ flex: 1 }}>
                {hasLocalHelperText && (
                  <FormHelperText
                    error={localError}
                    sx={{
                      mt: 0.5,
                      ml: 1,
                      whiteSpace: 'pre-line' // Needed to allow for new lines in helper text strings (ad preview creative validations)
                    }}
                  >
                    <Box component="span" data-cy="input-validation-error">
                      {localHelperText}
                    </Box>
                  </FormHelperText>
                )}
                {helperText && (
                  <HelperTextFooter
                    helperText={helperText}
                    stacked={hasLocalHelperText}
                  />
                )}
              </Box>
              <Box>
                {showEmojiPicker && (
                  <EmojiPickerIcon onEmojiSelected={handleEmojiAdded} />
                )}
                {showAiChatInputIconButton && (
                  <AiChatAdCopywriterIcon
                    onClick={handleAiChatAdCopywriterClick}
                  />
                )}
                {allowAiGeneration && (
                  <AiSuggestionIcon
                    onAiTextChange={handleApplyAiText}
                    initialValue={value}
                    displayName={displayName}
                    fieldName={`${input.name}-ai`}
                    textField={{
                      render: aiTextFieldComponent.current,
                      props: aiModalTextFieldProps
                    }}
                    parentFormName={formName}
                    parentFieldName={input.name}
                    hookFormContext={hookFormContext}
                    change={change}
                  />
                )}
              </Box>
            </Box>
          )}
      </div>
      <DynamicValueSelector
        dynamicValues={dynamicValues}
        missingColumns={missingColumns}
        anchorElement={inputRef.current}
        handleAddTag={handleAddTag}
        active={active}
      />
    </>
  );
};

export default flow(connect(null, { change: changeAction }))(
  RenderTemplateStringTextField
);
