import React from 'react';
import { useFormContext, useController } from 'react-hook-form';

// export type Normalizer = (
//   value: any,
//   previousValue?: any,
//   allValues?: any,
//   previousAllValues?: any
// ) => any;
// export type Formatter = (value: any, name: string) => any;
// export type Parser = (value: any, name: string) => any;
// export type Validator = (
//   value: any,
//   allValues?: any,
//   props?: any,
//   name?: any
// ) => any;

// export type EventHandler<Event> = (event: Event, name?: string) => void;
// export type EventWithDataHandler<Event> = (
//   event?: Event,
//   newValue?: any,
//   previousValue?: any,
//   name?: string
// ) => void;

// export interface EventOrValueHandler<Event> extends EventHandler<Event> {
//   (value: any): void;
// }
// export interface CommonFieldInputProps {
//   name: string;
//   onDragStart: EventHandler<DragEvent<any>>;
//   onDrop: EventHandler<DragEvent<any>>;
//   onFocus: EventHandler<FocusEvent<any>>;
// }

// export interface WrappedFieldInputProps extends CommonFieldInputProps {
//   checked?: boolean | undefined;
//   value: any;
//   onBlur: EventOrValueHandler<FocusEvent<any>>;
//   onChange: EventOrValueHandler<ChangeEvent<any>>;
// }

// export interface WrappedFieldMetaProps {
//   active?: boolean | undefined;
//   autofilled: boolean;
//   asyncValidating: boolean;
//   dirty: boolean;
//   dispatch: Dispatch<any>;
//   error?: any;
//   form: string;
//   initial: any;
//   invalid: boolean;
//   pristine: boolean;
//   submitting: boolean;
//   submitFailed: boolean;
//   touched: boolean;
//   valid: boolean;
//   visited: boolean;
//   warning?: any;
// }

// export interface WrappedFieldProps {
//   input: WrappedFieldInputProps;
//   meta: WrappedFieldMetaProps;
// }

// export interface CommonFieldProps extends CommonFieldInputProps {
//   onBlur: EventWithDataHandler<FocusEvent<any>>;
//   onChange: EventWithDataHandler<ChangeEvent<any>>;
// }

// export interface BaseFieldProps<P = {}> extends Partial<CommonFieldProps> {
//   name: string;
//   component?:
//     | ComponentType<WrappedFieldProps & P>
//     | 'input'
//     | 'select'
//     | 'textarea'
//     | undefined;
//   format?: Formatter | null | undefined;
//   normalize?: Normalizer | undefined;
//   props?: P | undefined;
//   parse?: Parser | undefined;
//   validate?: Validator | Validator[] | undefined;
//   warn?: Validator | Validator[] | undefined;
//   forwardRef?: boolean | undefined;
//   immutableProps?: string[] | undefined;
// }

// export interface GenericField<P> extends Component<BaseFieldProps<P> & P> {
//   dirty: boolean;
//   name: string;
//   pristine: boolean;
//   value: any;
//   getRenderedComponent(): Component<WrappedFieldProps & P>;
// }

// export type GenericFieldHTMLAttributes =
//   | InputHTMLAttributes<HTMLInputElement>
//   | SelectHTMLAttributes<HTMLSelectElement>
//   | TextareaHTMLAttributes<HTMLTextAreaElement>;

// export class Field<
//   P extends GenericFieldHTMLAttributes | BaseFieldProps =
//     | GenericFieldHTMLAttributes
//     | BaseFieldProps
// > extends Component<P> {
//   dirty: boolean;
//   name: string;
//   pristine: boolean;
//   value: any;
//   getRenderedComponent(): Component<WrappedFieldProps & P>;
// }

// export default Field;

type HookFormProps = {
  key?: string;
  name: string;
  component: React.JSXElementConstructor<any>;
  label?: string;
  helperText?: string;
  tooltip?: string;
  fullWidth?: boolean;
  autoComplete?: string;
  readOnly?: boolean;
  validate?: any;
  disabled?: boolean;
  businessObjects?: any;
  userMetadataFields?: any[];
  extraProps?: any;
  aiTextAnalysisValues?: any;
  formNamespace?: string;
  children?: React.ReactNode;
  onChange?: (value: any) => void;
  validateBackendOnly?: boolean;
  selectedLocationsMetadata?: Record<string, any>[];
  value?: any;
  isHighlighted?: boolean;
};

const HookFormWrapper = (props: HookFormProps) => {
  const {
    name: fieldName,
    component,
    disabled,
    label,
    helperText,
    tooltip,
    fullWidth,
    autoComplete,
    readOnly,
    validate,
    businessObjects,
    userMetadataFields,
    extraProps,
    aiTextAnalysisValues,
    formNamespace,
    children,
    onChange,
    validateBackendOnly = false,
    selectedLocationsMetadata,
    value,
    isHighlighted = false
  } = props;

  const name = `${formNamespace ? `${formNamespace}.` : ''}${fieldName}`;
  const Input = component;
  const hookFormContext = useFormContext();
  const { control } = hookFormContext;

  const {
    field,
    fieldState: { error, isValidating, isDirty, invalid, isTouched },
    formState: { isSubmitSuccessful, isSubmitting }
  } = useController({
    name,
    control,
    disabled,
    ...(!validateBackendOnly && {
      rules: {
        validate
      }
    })
  });

  const input = {
    name,
    onBlur: field.onBlur,
    onChange: onChange || field.onChange,
    // onDragStart: ƒ (event)
    // onDrop: ƒ (event)
    // onFocus: ƒ (event)
    value: value || field.value || ''
    // we should explicitly check for null values in our components since a form might be not a string right?
  };

  const meta = {
    // active: false,
    asyncValidating: isValidating,
    // autofilled: false,
    dirty: isDirty,
    // dispatch: ƒ (action),
    error: error?.message || error?.type,
    // form: "program",
    // initial: undefined,
    invalid,
    // pristine: true,
    submitFailed: !isSubmitSuccessful,
    submitting: isSubmitting,
    touched: isTouched,
    valid: !invalid,
    visited: false
    // warning: undefined,
  };

  const sharedProps = {
    isHookForm: true,
    disabled,
    label,
    helperText,
    tooltip,
    fullWidth,
    autoComplete,
    readOnly,
    businessObjects,
    userMetadataFields,
    input,
    meta,
    hookFormField: field,
    hookFormContext,
    ...(aiTextAnalysisValues && { aiTextAnalysisValues }),
    selectedLocationsMetadata,
    extraProps,
    isHighlighted,
    ...extraProps
  };

  return children ? (
    <Input {...sharedProps}>{children}</Input>
  ) : (
    <Input {...sharedProps} />
  );
};

export default HookFormWrapper;
