import { Typography, Box, SxProps, useTheme } from '@mui/material';
import { isEmpty, isFunction } from 'lodash';

import S3Image from 'src/components/AdPreview/S3Image';

import {
  getFullPlaceholderImagePath,
  placeholderImageNames,
  type PlaceholderVariant,
  type PlaceholderImageName
} from './constants';

interface GenericPlaceholderProps {
  imageName?: PlaceholderImageName;
  alt?: string;
  title?: string;
  subtitle?: string;
  buttons?: React.ReactNode[];
  sx?: SxProps;
  variant?: PlaceholderVariant;
}

const GenericPlaceholder = ({
  imageName = placeholderImageNames.noAudiences,
  alt,
  title,
  subtitle,
  buttons,
  sx,
  variant
}: GenericPlaceholderProps) => {
  let imagePath = getFullPlaceholderImagePath(imageName);

  if (variant) {
    imagePath = getFullPlaceholderImagePath(placeholderImageNames[variant]);
  }

  const theme = useTheme();

  let customSx;

  if (isFunction(sx)) {
    customSx = sx(theme);
  } else {
    customSx = sx;
  }

  const hasButtons = !isEmpty(buttons);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 1,
        maxWidth: '100%',
        px: 1,
        textAlign: { xs: 'center', sm: 'left' },
        ...customSx
      }}
    >
      <S3Image
        imageName={imagePath}
        alt={alt}
        style={{ maxWidth: 500, width: '100%' }}
      />
      {title && <Typography variant="h6">{title}</Typography>}
      {subtitle && (
        <Typography
          variant="body2"
          sx={{
            typography: { xs: 'body2', sm: 'body1' }
          }}
          {...(hasButtons && { mb: 1 })}
        >
          {subtitle}
        </Typography>
      )}
      {hasButtons && (
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {buttons}
        </Box>
      )}
    </Box>
  );
};

export default GenericPlaceholder;
