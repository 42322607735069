import { createContext, useState, useMemo, useEffect, useContext } from 'react';
import { flow, isNil } from 'lodash';
import { useMutation } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import { withRouter, useLocation, useRouteMatch } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { useSnackbar } from 'notistack';

import { Button, Alert, AlertTitle } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { withAppSettings } from 'src/AppSettings';
import { useGlobalContext } from 'src/GlobalContextProvider';

import WarningIcon from '@mui/icons-material/Warning';

import { getAppName } from 'src/common/appBranding';
import {
  dismissDeactivationNotice,
  showDeactivationNotice,
  getDeactivationDateFromTheme
} from 'src/common/deactivation';

import { allowListPaths, paths } from 'src/routes/paths';
import { generateLinkPath } from 'src/routes/RouteUtil';

import Auth from 'src/Auth/Auth';

import { DeactivationError } from 'src/pages/Error';
import AdminSkinResetModal from 'src/pages/Admin/SkinSettings/SkinResetModal';
import { SKIN_PREVIEW_LOCAL_STORAGE } from 'src/pages/Admin/SkinSettings/Constants';
import { WORKATO_CONNECT_FIRST_TIME_MODAL_DISMISS } from 'src/common/userSettings';
import { formatDate, dayjs } from 'src/common/dates';

import Loading from 'src/components/Loading';
import Modal from 'src/components/Modal';
import ClientHtml from 'src/components/ClientHtml';
import TermsOfServiceModal from 'src/components/TermsOfService/TermsOfServiceModal';
import PlgWrapper from 'src/pages/Plg/PlgWrapper';
import WorkatoConnectModal from 'src/pages/Chrome/WorkatoConnectModal';
import { removeConnection } from 'src/components/Workato/mutations';
import { setDismissModal } from 'src/pages/Chrome/mutations';

import { checkPullProviderExecutionStatuses } from './queries';
import ChromeAppBar from './ChromeAppBar';
import ChromeDrawer from './ChromeDrawer';
import LoggedInOnly from './LoggedInOnly';
import FacebookLinkModal from './FacebookLinkModal';
import AutomationAdoptionModal from './AutomationAdoptionModal';

const ImportUserContext = createContext({});

export const useImportUserContext = () => useContext(ImportUserContext);

const styles = theme => ({
  container: {
    display: 'flex',
    width: '100%'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    flexGrow: 1,
    flexBasis: '100%',
    marginLeft: 0,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: '100%'
  },
  alert: {
    marginBottom: theme.spacing(2),
    width: '100%',

    '& > div': {
      marginBottom: theme.spacing(2)
    },

    '& > div:last-child': {
      marginBottom: 0
    }
  },
  alertButton: {
    marginRight: theme.spacing(1)
  },
  toolbarSpacer: theme.mixins.toolbar,
  warningModalHeader: {
    alignItems: 'center',
    color: theme.palette.warning[600],
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    fontSize: '24px'
  },
  warningIcon: {
    margin: `0 ${theme.spacing(2)}`
  }
});

const pageText = ({ deactivationDate, appName, orgName }) => ({
  alertHeadingTextDeactivation: t('chrome:deactivation.alertHeadingText', {
    appName
  }),
  alertBodyTextDeactivation: t('chrome:deactivation.alertBodyText', {
    appName,
    deactivationDate,
    orgName
  }),
  headingTextDeactivation: t('chrome:deactivation.headingText', {
    appName
  }),
  bodyTextDeactivation: t('chrome:deactivation.bodyText', {
    appName,
    deactivationDate,
    orgName
  }),
  modalDismissButtonDeactivation: t('chrome:deactivation.modalDismissButton'),
  siteDeactivatedMessage: t('chrome:deactivation.siteDeactivatedMessage', {
    deactivationDate
  })
});

const Chrome = ({ children, classes, history, importingUser, appSettings }) => {
  const [workatoConnectModalOpen, setWorkatoConnectModalOpen] = useState(false);
  const [isWorkatoConnectFirstLoad, setIsWorkatoConnectFirstLoad] =
    useState(false);
  const [removeWorkatoConnection] = useMutation(removeConnection);
  const { enqueueSnackbar } = useSnackbar();
  const { loading, me, workatoConnection, refetch, orgTypes } =
    useGlobalContext();

  const plgConfig = appSettings?.organizationPlgConfig;

  const needsTermsAcceptance =
    plgConfig?.type === 'mortgage_total_expert' &&
    me?.userTosDetails?.userNeedsToAcceptTos;

  const isAuthenticated = Auth.isAuthenticated() && !needsTermsAcceptance;
  const appName = getAppName(appSettings);
  const orgName = appSettings?.app?.organizationName;

  // scroll to the top on navigation
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const themeDeactivationDate = getDeactivationDateFromTheme(appSettings);
  const deactivationDate =
    themeDeactivationDate &&
    formatDate({ format: 'ddd, MMM DD YYYY', date: themeDeactivationDate });
  const pastDeactivationDate =
    themeDeactivationDate &&
    dayjs(deactivationDate).diff(dayjs(), 'hours') <= 0;

  const [deactivationNoticeActive, setDeactivationNoticeActive] = useState(
    showDeactivationNotice()
  );

  const handleDismissDeactivationNotice = () => {
    dismissDeactivationNotice();
    setDeactivationNoticeActive(false);
  };

  const text = useMemo(
    () => pageText({ appName, deactivationDate, orgName }),
    [appName, deactivationDate, orgName]
  );

  const [skinResetModalOpen, toggleSkinResetModal] = useState(false);

  const skinPreviewAlert = localStorage.getItem(SKIN_PREVIEW_LOCAL_STORAGE);

  const [dismissImportingUser, setDismissImportingUser] = useState(false);

  const alertHeadingTextDeactivation = `<span>${text.alertHeadingTextDeactivation}</span>`;
  const alertBodyTextDeactivation = `<span>${text.alertBodyTextDeactivation}</span>`;
  const headingTextDeactivation = `<span>${text.headingTextDeactivation}</span>`;
  const bodyTextDeactivation = `<span>${text.bodyTextDeactivation}</span>`;

  const [dismissModalMutation] = useMutation(setDismissModal);
  const handleWorkatoConnectSuccess = connected => {
    if (connected) {
      // when we successfully connect dismiss forever the modal that pops up for new users
      dismissModalMutation({
        variables: {
          updateSettings: {
            [WORKATO_CONNECT_FIRST_TIME_MODAL_DISMISS]: true
          },
          updateType: 'DEEP_MERGE'
        }
      })
        .then(() => {
          setWorkatoConnectModalOpen(false);
        })
        .catch(() => {
          setWorkatoConnectModalOpen(false);
        });

      refetch();

      enqueueSnackbar(t('chrome:workatoConnect.successSnackbar'), {
        variant: 'success'
      });
    }
  };

  // I'm a special PLG route
  const isPlgSignUp = useRouteMatch(paths.plg.signUp)?.isExact && plgConfig;
  const isPlgActivate = useRouteMatch(paths.auth.activate.base) && plgConfig;

  // Show error when past deactivation date so users cannot use the site
  if (pastDeactivationDate) {
    return (
      <div>
        <DeactivationError customErrorMessage={text.siteDeactivatedMessage} />
      </div>
    );
  }

  // PLG pages have a different layout and they do not get the chrome treatment like the rest of the app. For now this is the
  // only exception to the chrome layout so we won't refactor this until we have more exceptions.
  if (isPlgSignUp || isPlgActivate) {
    return <PlgWrapper>{children}</PlgWrapper>;
  }

  const showTotalExpertAlert =
    !loading &&
    workatoConnection &&
    workatoConnection?.connectionExists &&
    !workatoConnection?.isActive;

  if (
    !loading &&
    isAuthenticated &&
    orgTypes?.isPlg &&
    // important null or undefined means not a total expert user while false means they are but no audiences
    !isNil(me?.metadata?.fields?.userMetadata__total_expert_audiences) &&
    // check active connection exists
    workatoConnection &&
    !workatoConnection?.isActive &&
    // see src/pages/Chrome/mutations.ts for states of WORKATO_CONNECT_FIRST_TIME_MODAL_DISMISS
    (isNil(me?.settings?.[WORKATO_CONNECT_FIRST_TIME_MODAL_DISMISS]) ||
      me?.settings?.[WORKATO_CONNECT_FIRST_TIME_MODAL_DISMISS] !== true) &&
    !workatoConnectModalOpen &&
    !isWorkatoConnectFirstLoad
  ) {
    // Show new eplg users the total expert connect option
    setWorkatoConnectModalOpen(true);
    setIsWorkatoConnectFirstLoad(true);
  }

  const closeWorkatoConnectModal = (settingsState = false) => {
    // defaults to false to allow the modal to show again if the user has not connected
    // dismiss forever will pass true to this function
    dismissModalMutation({
      variables: {
        updateSettings: {
          [WORKATO_CONNECT_FIRST_TIME_MODAL_DISMISS]: settingsState
        },
        updateType: 'DEEP_MERGE'
      }
    })
      .then(() => {
        setWorkatoConnectModalOpen(false);
      })
      .catch(() => {
        setWorkatoConnectModalOpen(false);
      });

    if (isWorkatoConnectFirstLoad) {
      removeWorkatoConnection().then(() => {
        // refetch useGlobalContext to update the workatoConnection state
        refetch();
      });
    }
  };

  return (
    <ImportUserContext.Provider
      value={{
        importingUser
      }}
    >
      <div className={classes.container}>
        <LoggedInOnly>
          <ChromeAppBar loading={loading} />
          <ChromeDrawer loading={loading} />
          {isAuthenticated && !loading && <FacebookLinkModal />}
          {isAuthenticated && !loading && <AutomationAdoptionModal />}
          {needsTermsAcceptance && !loading && <TermsOfServiceModal />}
          <WorkatoConnectModal
            workatoConnectModalOpen={workatoConnectModalOpen}
            isWorkatoConnectFirstLoad={isWorkatoConnectFirstLoad}
            closeWorkatoConnectModal={closeWorkatoConnectModal}
            handleWorkatoConnectSuccess={handleWorkatoConnectSuccess}
          />
        </LoggedInOnly>
        <main className={classes.content}>
          <div className={classes.toolbarSpacer} />
          <div className={classes.alert}>
            {deactivationDate && (
              <Alert severity="warning" icon={<WarningIcon />}>
                <AlertTitle>
                  <ClientHtml html={alertHeadingTextDeactivation} />
                </AlertTitle>
                <ClientHtml html={alertBodyTextDeactivation} />
              </Alert>
            )}
            {skinPreviewAlert && import.meta.env.MODE !== 'test' && (
              <Alert
                severity="warning"
                action={
                  <>
                    <Button
                      className={classes.alertButton}
                      color="inherit"
                      size="small"
                      variant="outlined"
                      onClick={() =>
                        history.push(
                          generateLinkPath(paths.admin.settings.skin)
                        )
                      }
                    >
                      <Trans i18nKey="adminSkinSettings:alert.goToSkinSettings" />
                    </Button>
                    <Button
                      color="inherit"
                      size="small"
                      onClick={() => toggleSkinResetModal(true)}
                    >
                      <Trans i18nKey="adminSkinSettings:alert.reset" />
                    </Button>
                  </>
                }
              >
                <Trans i18nKey="adminSkinSettings:alert.previewMode" />
              </Alert>
            )}
            {importingUser && !dismissImportingUser && (
              <LoggedInOnly>
                <Alert
                  severity="info"
                  icon={<Loading size={24} />}
                  action={
                    <>
                      <Button
                        color="inherit"
                        size="small"
                        onClick={() => setDismissImportingUser(true)}
                      >
                        <Trans i18nKey="adminSkinSettings:alert.dismiss" />
                      </Button>
                    </>
                  }
                >
                  <AlertTitle>
                    <Trans i18nKey="adminSkinSettings:alert.importing">
                      Importing
                    </Trans>
                  </AlertTitle>
                  <Trans i18nKey="adminSkinSettings:alert.importingUserData" />
                </Alert>
              </LoggedInOnly>
            )}
            {showTotalExpertAlert && (
              <>
                <Alert severity="warning">
                  {t('chrome:alerts.totalExpertDisconnectStart')}
                  {` `}
                  <Button
                    onClick={() => {
                      setWorkatoConnectModalOpen(true);
                    }}
                    sx={{
                      fontSize: 'inherit',
                      backgroundColor: 'transparent',
                      color: 'primary.main',
                      textTransform: 'none',
                      padding: 0,
                      minWidth: 0,
                      verticalAlign: 'baseline',
                      lineHeight: 'inherit',
                      '&:hover': {
                        textDecoration: 'underline',
                        backgroundColor: 'transparent'
                      }
                    }}
                  >
                    {t('chrome:alerts.totalExpertDisconnectButton')}
                  </Button>

                  {` `}
                  {t('chrome:alerts.totalExpertDisconnectEnd')}
                </Alert>
              </>
            )}
          </div>
          {!loading && !needsTermsAcceptance ? (
            children
          ) : (
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '100%'
              }}
            >
              <Loading />
            </div>
          )}
        </main>
        <AdminSkinResetModal
          open={skinResetModalOpen}
          close={() => toggleSkinResetModal(false)}
        />
        {isAuthenticated && deactivationDate && deactivationNoticeActive && (
          <Modal
            fullWidth
            showClose={false}
            open
            HeaderComponent={() => (
              <span className={classes.warningModalHeader}>
                <WarningIcon className={classes.warningIcon} />

                <ClientHtml html={headingTextDeactivation} />

                <WarningIcon className={classes.warningIcon} />
              </span>
            )}
            FooterComponent={() => (
              <>
                <Button onClick={handleDismissDeactivationNotice}>
                  {text.modalDismissButtonDeactivation}
                </Button>
              </>
            )}
          >
            <div className={classes.container}>
              <ClientHtml html={`${bodyTextDeactivation}`} />
            </div>
          </Modal>
        )}
      </div>
    </ImportUserContext.Provider>
  );
};

export default flow(
  graphql(checkPullProviderExecutionStatuses, {
    name: 'checkPullProviderExecutionStatuses',
    skip: ({ location: { pathname } }) => {
      if (
        allowListPaths.filter(url => pathname === url).length ||
        !Auth.isAuthenticated()
      ) {
        // Note: skip the me query if at an allowed url so we can show unauthed pages
        //       like the activation page
        return true;
      }
    },
    props: ({ checkPullProviderExecutionStatuses }) => {
      const statuses =
        checkPullProviderExecutionStatuses?.me?.pullProviderExecutionStatuses;

      let importingUser = false;
      // check to see if any pullProviderExecutionStatuses are incomplete
      if (
        statuses &&
        statuses.some(status => status.isExecutionComplete === false)
      ) {
        importingUser = true;
        checkPullProviderExecutionStatuses.startPolling(4000);
      } else {
        checkPullProviderExecutionStatuses.stopPolling();
      }

      return { importingUser };
    }
  }),
  withStyles(styles),
  withRouter,
  withAppSettings
)(Chrome);
